import {
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  alpha,
} from "@mui/material";
import {
  arcticMistColor,
  blackColor,
  ceruleanCascadeColor,
  cloudyPearlColor,
  forestShadowColor,
  goldenAmberColor,
  primaryColor,
  sageWhisperColor,
  slateGrayColor,
  softMoonlightColor,
  steelBlueColor,
  whiteColor,
} from "../../assets/colors";
import {
  AccountSettingIcon,
  ArrowLeftIcon,
  BriefcaseIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EuroIcon3,
  LighthouseIcon,
  MoneyReciveIcon,
  ReceiptIcon,
} from "../../assets/icons";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessCategoriesRequestAction,
  getDashboardDataRequestAction,
  setBusinessCategoryRequestAction,
} from "../../redux/actions/dashboardActions";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import CustomAutocomplete from "../../components/CustomAutocomplete/index";
import { appBarHeight, drawerWidth } from "../../utils/constants";
import CustomTextInput from "../../components/CustomTextInput";
import { SET_BUSINESS_CATEGORY_STARTED } from "../../redux/actionTypes";
// --------------------------------------------------------------------------------
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const {
    dashboard: { data, rType },
    auth: { user },
    businessCategories,
  } = useSelector((state) => ({
    dashboard: state.dashboard,
    auth: state.auth,
    businessCategories: state.dashboard.businessCategories,
  }));
  const [activePaylink, setActivePaylink] = useState({});
  const [open, setOpen] = useState(false);
  const [businessCategory, setBusinessCategory] = useState();
  const [businessName, setBusinessName] = useState();
  const [errors, setErrors] = useState({});
  const [index, setIndex] = useState(0);

  const swiperRef = useRef(null);

  useEffect(() => {
    dispatch(getDashboardDataRequestAction());
    businessCategories.length === 0 &&
      dispatch(getBusinessCategoriesRequestAction());
  }, []);

  useEffect(() => {
    const selectedBusinessCategory = businessCategories?.find(
      (item) => item.id === user.business_category_id
    );
    setBusinessCategory(selectedBusinessCategory);
    setBusinessName(user.business_category_name);
    setOpen(!user.business_category_id);
  }, [user.business_category_id]);

  const onSubmit = () => {
    const errorObj = {};

    if (!Boolean(businessCategory)) {
      errorObj.businessCategory = "لطفا دسته بندی کسب و کار خود را مشخص کنید.";
    } else if (businessCategory.id === 15 && businessName === "") {
      errorObj.businessName = "لطفا نام کسب و کار خود را بنویسید.";
    }

    setErrors(errorObj);
    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      setBusinessCategoryRequestAction({
        businessCategoryId: businessCategory.id,
        businessCategoryName: businessName
          ? businessName
          : businessCategory.name_fa,
        setOpen,
      })
    );
  };

  const renderBusinessCategories = (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
          width: 452,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        // top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() => setOpen(false)}
      scroll="body"
    >
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              height: 40,
              width: 40,
              backgroundColor: arcticMistColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
            }}
          >
            <BriefcaseIcon width={25} height={25} color={primaryColor} />
          </Box>
          <Typography sx={{ fontWeight: 700, ml: 1 }}>
            انتخاب حوزه کسب و کار
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ lineHeight: 2 }}>
        برای تجربه بهتر و ارائه راهنمایی دقیق‌تر از سوی همکاران ما، لطفا حوزه
        کسب‌وکار خود را مشخص کنید.
      </DialogContent>
      <DialogContent>
        <CustomAutocomplete
          label={"دسته‌بندی‌های کسب‌وکار"}
          options={businessCategories}
          getOptionLabel={(option) => option.name_fa}
          sx={{ mb: 2 }}
          onChange={(event, value) => {
            setBusinessCategory(value);
            setBusinessName(value.id === 15 ? "" : value.name_fa);
          }}
          ref={containerRef}
          error={errors.businessCategory}
          helperText={errors.businessCategory}
          value={businessCategory}
        />
        <Collapse
          in={businessCategory?.id === 15}
          container={containerRef.current}
        >
          <CustomTextInput
            label="نام کسب و کار"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            error={errors.businessName}
            helperText={errors.businessName}
            persian
          />
        </Collapse>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, pt: 0 }}>
        <Button
          text={"ثبت"}
          onClick={onSubmit}
          sx={{}}
          loading={rType === SET_BUSINESS_CATEGORY_STARTED}
        />
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ backgroundColor: blackColor, p: 2, display: { lg: "none" } }}>
        {user.email ? null : (
          <Button
            onClick={() => navigate("/email-confirmation")}
            startIcon={<AccountSettingIcon width={32} height={32} />}
            text={
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: whiteColor,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  حساب کاربری خود را تایید کنید
                </Typography>
              </Box>
            }
            color="charcoalGray"
            sx={{
              height: 54,
              mb: 1.5,
            }}
            endIcon={<ArrowLeftIcon width={24} color={whiteColor} />}
          />
        )}
        <Button
          startIcon={<MoneyReciveIcon />}
          text={
            <Box sx={{ flex: 1, textAlign: "left" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  color: whiteColor,
                  opacity: 0.8,
                  mb: 1.5,
                }}
              >
                تراکنش های ورودی امروز
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: 25,
                    color: whiteColor,
                    lineHeight: 1,
                  }}
                >
                  {data.today_input}
                </Typography>
                <EuroIcon3 width={15} height={15} color={whiteColor} />
              </Box>
            </Box>
          }
          color="charcoalGray"
          sx={{
            mb: 1.5,
            justifyContent: "space-between",
            boxShadow: "none",
            pl: 0,
            pt: 0,
            pb: 0,
            // fontSize: 16,
            ".MuiButton-startIcon": {
              backgroundColor: alpha(whiteColor, 0.05),
              width: 64,
              height: 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 2,
            },
          }}
          endIcon={<ArrowLeftIcon width={24} color={whiteColor} />}
        />
        <Button
          startIcon={<ReceiptIcon />}
          text={
            <Box sx={{ flex: 1, textAlign: "left" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  color: whiteColor,
                  opacity: 0.8,
                  mb: 1.5,
                }}
              >
                مجموع تراکنش های تسویه نشده
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: 25,
                    color: whiteColor,
                    lineHeight: 1,
                  }}
                >
                  {data.pending_amount}
                </Typography>
                <EuroIcon3 width={15} height={15} color={whiteColor} />
              </Box>
            </Box>
          }
          color="charcoalGray"
          sx={{
            mb: 1.5,
            justifyContent: "space-between",
            boxShadow: "none",
            pl: 0,
            pt: 0,
            pb: 0,
            // fontSize: 16,
            ".MuiButton-startIcon": {
              backgroundColor: alpha(whiteColor, 0.05),
              width: 64,
              height: 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 2,
            },
          }}
          endIcon={<ArrowLeftIcon width={24} color={whiteColor} />}
        />
        <Button
          startIcon={<CheckCircleIcon width={30} hright={30} />}
          text={
            <Box sx={{ flex: 1, textAlign: "left" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  color: whiteColor,
                  opacity: 0.8,
                  // lineHeight: 2,
                  mb: 1.5,
                }}
              >
                تراکنش های تسویه شده امروز
              </Typography>
              <Typography
                sx={{
                  fontSize: 25,
                  color: whiteColor,
                  lineHeight: 1,
                }}
              >
                {data.today_payout}{" "}
                <Typography sx={{ display: "inline-block", color: whiteColor }}>
                  ریالءء
                </Typography>
              </Typography>
            </Box>
          }
          color="charcoalGray"
          sx={{
            justifyContent: "space-between",
            boxShadow: "none",
            pl: 0,
            pt: 0,
            pb: 0,
            // fontSize: 16,
            ".MuiButton-startIcon": {
              backgroundColor: alpha(whiteColor, 0.05),
              width: 64,
              height: 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 2,
            },
          }}
          endIcon={<ArrowLeftIcon width={24} color={whiteColor} />}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "21px",
          flexWrap: "wrap",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            backgroundColor: primaryColor,
            borderRadius: { lg: 2.5 },
            display: "flex",
            alignItems: "center",
            height: { lg: 92 },
            flexDirection: { xs: "column", lg: "row" },
            // m: { lg: 2.5 },
            // mb: "20px",
            flex: "100%",
            mx: { xs: 0, lg: "20px" },
            mt: { lg: "20px" },
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mr: { lg: "auto" } }}
          >
            <Box
              component={LighthouseIcon}
              // sx={{ position: { lg: "relative" }, bottom: -6 }}
              width={87}
              height={87}
              color={whiteColor}
            ></Box>
            <Box sx={{ position: { lg: "relative" }, left: -10 }}>
              <Typography
                sx={{ fontSize: 18, color: whiteColor, fontWeight: 600, mb: 1 }}
              >
                چطور میتونم کمکت کنم؟
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 300, color: whiteColor }}
              >
                دنبال هرچی میگردی از رو به رو پیداش کن ...
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              maxWidth: "100vw",
              py: 2,
              overflow: "auto",
              scrollbarWidth: "none",
            }}
          >
            <Chip
              label="کدوم محصول مناسبه منه؟"
              onClick={() => navigate("/onboarding")}
              color="frostyVeil"
              sx={{
                height: { xs: 36, lg: 40 },
                fontSize: { xs: 12, lg: 16 },
                ml: 1,
              }}
            />
            <Chip
              label="دخل امروزم چقدره؟"
              onClick={() => navigate("/transactions-list")}
              color="frostyVeil"
              sx={{
                height: { xs: 36, lg: 40 },
                fontSize: { xs: 12, lg: 16 },
                ml: 1,
              }}
            />
            <Chip
              label="میخوام پروفایلم رو تکمیل کنم"
              onClick={() => navigate("/account")}
              color="frostyVeil"
              sx={{
                height: { xs: 36, lg: 40 },
                fontSize: { xs: 12, lg: 16 },
                mx: 1,
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: whiteColor,
            borderRadius: 2.5,
            flex: { xs: "100%", lg: "30%" },
            ml: "20px",
            display: { xs: "none", lg: "block" },
          }}
        >
          <Box
            sx={{
              backgroundColor: sageWhisperColor,
              width: 56,
              height: 48,
              ml: 4,
              borderBottomLeftRadius: 50,
              borderBottomRightRadius: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MoneyReciveIcon />
          </Box>
          <Box sx={{ mx: 4, my: 2, display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontSize: 45, fontWeight: 700 }}>
              {data.today_input}
            </Typography>
            <EuroIcon3 width={33} height={33} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 4,
              py: 2,
            }}
          >
            <Typography sx={{ fontSize: 14 }}>
              تراکنش های ورودی امروز
            </Typography>
            <IconButton size="small">
              <ArrowLeftIcon width={24} />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: whiteColor,
            borderRadius: 2.5,
            flex: { xs: "100%", lg: "30%" },
            display: { xs: "none", lg: "block" },
          }}
        >
          <Box
            sx={{
              backgroundColor: sageWhisperColor,
              width: 56,
              height: 48,
              ml: 4,
              borderBottomLeftRadius: 50,
              borderBottomRightRadius: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReceiptIcon />
          </Box>
          <Box sx={{ mx: 4, my: 2, display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: 45,
                fontWeight: 700,
              }}
            >
              {data.pending_amount}
            </Typography>
            <EuroIcon3 width={33} height={33} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 4,
              py: 2,
            }}
          >
            <Typography sx={{ fontSize: 14 }}>
              مجموع تراکنش های تسویه نشده
            </Typography>
            <IconButton size="small">
              <ArrowLeftIcon width={24} />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: whiteColor,
            borderRadius: 2.5,
            flex: { xs: "100%", lg: "30%" },
            mr: "20px",
            display: { xs: "none", lg: "block" },
          }}
        >
          <Box
            sx={{
              backgroundColor: sageWhisperColor,
              width: 56,
              height: 48,
              ml: 4,
              borderBottomLeftRadius: 50,
              borderBottomRightRadius: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon width={30} height={30} />
          </Box>
          <Box sx={{ mx: 4, my: 2 }}>
            <Typography sx={{ fontSize: 45, fontWeight: 700 }}>
              {data.today_payout}
              <Typography
                sx={{
                  display: "inline-block",
                  color: blackColor,
                  fontSize: 25,
                  fontWeight: 700,
                }}
              >
                ریالءء
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 4,
              py: 2,
            }}
          >
            <Typography sx={{ fontSize: 14 }}>
              تراکنش های تسویه شده امروز
            </Typography>
            <IconButton size="small">
              <ArrowLeftIcon width={24} />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: whiteColor,
            // py: 2,
            // px: 2.5,
            borderRadius: 2.5,
            // mb: 2.5,
            flex: { xs: "100%", lg: "57%" },
            ml: "20px",
            mr: { xs: "20px", lg: 0 },
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: 600, py: "15px", ml: 3 }}>
            وب گیت
          </Typography>
          {/* <Typography sx={{ fontSize: 14, pb: "26px", ml: 3 }}>
            توضیح خلاصه در رابطه با این بخش نوشته شود.
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              pb: 2,
              pl: 3,
              mr: { xs: 3, lg: 0 },
            }}
          >
            <Box sx={{ order: { xs: 2, lg: 0 }, minWidth: { lg: 212 } }}>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  mb: 2,
                }}
              >
                <Typography>حجم تراکنش امروز:</Typography>
                <Typography
                  sx={{
                    color: primaryColor,
                    ml: "auto",
                  }}
                >
                  {data.today_webgate_amount}
                </Typography>
                <EuroIcon3 width={12} />
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography>تعداد تراکنش امروز:</Typography>
                <Typography
                  sx={{
                    color: goldenAmberColor,
                    ml: "auto",
                  }}
                >
                  {data.today_webgate_count}
                </Typography>
                <Typography sx={{ fontSize: 8, fontWeight: 500, ml: 0.5 }}>
                  تراکنش
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                  }}
                >
                  هفته گذشته
                </Typography>
                <Box sx={{ display: "flex", textAlign: "center" }}>
                  <Box sx={{ p: 2, flex: 0.5 }}>
                    <Typography
                      sx={{
                        color: primaryColor,
                        fontSize: 25,
                        fontWeight: 700,
                        lineHeight: 1.7,
                      }}
                    >
                      <EuroIcon3
                        style={{
                          width: 10,
                          color: forestShadowColor,
                          position: "absolute",
                        }}
                      />
                      {data.week_webgate_amount}
                    </Typography>
                    <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                      جمع کل فروش
                    </Typography>
                  </Box>
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <Box sx={{ p: 2, flex: 0.5, textAlign: "center" }}>
                    <Typography
                      sx={{
                        color: goldenAmberColor,
                        fontSize: 30,
                        fontWeight: 700,
                      }}
                    >
                      {data.week_webgate_count}
                    </Typography>
                    <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                      تراکنش
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                flex: { lg: 1 },
                height: { xs: 110, lg: "auto" },
              }}
            >
              <ResponsiveContainer>
                <AreaChart
                  data={data.webgate_week}
                  margin={{
                    // top: 10,
                    right: 30,
                    // left: -30,
                    // bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={goldenAmberColor}
                        stopOpacity={0.1}
                      />
                      <stop
                        offset="95%"
                        stopColor={goldenAmberColor}
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={primaryColor}
                        stopOpacity={0.1}
                      />
                      <stop
                        offset="95%"
                        stopColor={primaryColor}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    tick={{
                      fontSize: 14,
                      fill: steelBlueColor,
                      fontWeight: 600,
                    }}
                    axisLine={{ stroke: "#FFFFFF" }}
                    tickLine={{ stroke: "#FFFFFF" }}
                    // tickSize={1}
                  />
                  <YAxis
                    tick={{
                      fontSize: 14,
                      fill: steelBlueColor,
                      fontWeight: 600,
                    }}
                    axisLine={{ stroke: "#FFFFFF" }}
                    tickLine={{ stroke: "#FFFFFF" }}
                    tickMargin={40}
                  />
                  <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="#9EA4B240"
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke={goldenAmberColor}
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                  <Area
                    type="monotone"
                    dataKey="pv"
                    stroke={primaryColor}
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            borderRadius: 2.5,
            flex: { xs: "100%", lg: "25%" },
            mr: "20px",
            ml: { xs: "20px", lg: 0 },
            pb: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: whiteColor,
              py: 2,
              px: 2.5,
              borderRadius: 2.5,
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 1 }}>
              پروفایل کاربری
            </Typography>
            <Box
              sx={{
                mt: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", width: 220, height: 150 }}>
                <svg style={{ position: "absolute", width: 0, height: 0 }}>
                  <defs>
                    <linearGradient
                      id="gradient"
                      gradientTransform="rotate(45)"
                    >
                      <stop offset="25%" stopColor="#43DBB2" />
                      <stop offset="75%" stopColor="#01A87B" />
                    </linearGradient>
                  </defs>
                </svg>
                <CircularProgressbarWithChildren
                  value={data.profile_percent}
                  circleRatio={0.5}
                  styles={buildStyles({
                    rotation: 0.75,
                    strokeLinecap: "butt",
                    trailColor: cloudyPearlColor,
                    pathColor: `url(#gradient)`,
                    pathTransitionDuration: 1,
                  })}
                >
                  <Typography
                    sx={{
                      fontSize: 40,
                      fontWeight: 700,
                      mt: -10,
                    }}
                  >
                    %{data.profile_percent}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: slateGrayColor,
                    }}
                  >
                    درصد تکمیل پروفایل
                  </Typography>
                </CircularProgressbarWithChildren>
              </Box>
            </Box>
            <Button
              text="تکمیل پروفایل کاربری"
              endIcon={<ArrowLeftIcon color={primaryColor} width={24} />}
              sx={{ justifyContent: "space-between", py: 2 }}
              color="arcticMist"
              onClick={() => navigate("/account/profile")}
            />
          </Box>
        </Box>
        {data.paylinks.length > 0 ? (
          <Box
            sx={{
              backgroundColor: whiteColor,
              // py: 2,
              // px: 2.5,
              borderRadius: 2.5,
              width: "100px",
              flex: { xs: "100%", lg: "57%" },
              ml: "20px",
              mb: { lg: "20px" },
              mr: { xs: "20px", lg: 0 },
            }}
          >
            <Typography
              sx={{ fontSize: 20, fontWeight: 600, py: "15px", ml: 3 }}
            >
              پی لینک
            </Typography>
            {/* <Typography sx={{ fontSize: 14, pb: "26px", ml: 3 }}>
              توضیح خلاصه در رابطه با این بخش نوشته شود.
            </Typography> */}
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              // pagination={{
              //   clickable: true,
              // }}
              // navigation={true}
              modules={[Autoplay]}
              // className="mySwiper"
              onAutoplay={(e) => {
                setActivePaylink(data.paylinks[e.activeIndex]);
                setIndex(e.activeIndex);
              }}
              slideac={index}
              ref={swiperRef}
              simulateTouch={false}
            >
              {data.paylinks.map((p, index) => (
                <SwiperSlide key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", lg: "row" },
                      pb: 2,
                      pl: 3,
                      mr: { xs: 3, lg: 0 },
                    }}
                  >
                    <Box
                      sx={{ order: { xs: 2, lg: 0 }, minWidth: { lg: 212 } }}
                    >
                      <Box
                        sx={{
                          backgroundColor: arcticMistColor,
                          borderRadius: 1.5,
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          mb: 2,
                        }}
                      >
                        <Typography>حجم تراکنش امروز:</Typography>
                        <Typography
                          sx={{
                            color: primaryColor,
                            ml: "auto",
                          }}
                        >
                          {p.today_amount}
                        </Typography>
                        <EuroIcon3 width={12} />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: arcticMistColor,
                          borderRadius: 1.5,
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography>تعداد تراکنش امروز:</Typography>
                        <Typography
                          sx={{
                            color: goldenAmberColor,
                            ml: "auto",
                          }}
                        >
                          {p.today_count}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 8, fontWeight: 500, ml: 0.5 }}
                        >
                          تراکنش
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: arcticMistColor,
                          borderRadius: 1.5,
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography>پی لینک فعال:</Typography>
                        <Typography
                          sx={{
                            color: ceruleanCascadeColor,
                            ml: "auto",
                          }}
                        >
                          {data.paylinks.length}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 8, fontWeight: 500, ml: 0.5 }}
                        >
                          عدد
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: arcticMistColor,
                          borderRadius: 1.5,
                          p: 1,
                          // display: "flex",
                          // alignItems: "center",
                          // justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: 700,
                            fontSize: 14,
                            mb: 1,
                          }}
                        >
                          هفته گذشته
                        </Typography>
                        <Box sx={{ display: "flex", textAlign: "center" }}>
                          <Box sx={{ p: 2, flex: 0.5 }}>
                            <Typography
                              sx={{
                                color: primaryColor,
                                fontSize: 25,
                                fontWeight: 700,
                                lineHeight: 1.7,
                              }}
                            >
                              <EuroIcon3
                                style={{
                                  width: 10,
                                  color: forestShadowColor,
                                  position: "absolute",
                                }}
                              />
                              {p.week_amount}
                            </Typography>
                            <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                              جمع کل فروش
                            </Typography>
                          </Box>
                          <Box>
                            <Divider orientation="vertical" />
                          </Box>
                          <Box sx={{ p: 2, flex: 0.5, textAlign: "center" }}>
                            <Typography
                              sx={{
                                color: goldenAmberColor,
                                fontSize: 30,
                                fontWeight: 700,
                              }}
                            >
                              {p.week_count}
                            </Typography>
                            <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                              تراکنش
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: { lg: 1 },
                        height: { xs: 110, lg: "auto" },
                      }}
                    >
                      <ResponsiveContainer>
                        <AreaChart
                          data={p.week}
                          margin={{
                            // top: 10,
                            right: 30,
                            // left: -30,
                            // bottom: 0,
                          }}
                        >
                          <defs>
                            <linearGradient
                              id="colorUv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor={goldenAmberColor}
                                stopOpacity={0.1}
                              />
                              <stop
                                offset="95%"
                                stopColor={goldenAmberColor}
                                stopOpacity={0}
                              />
                            </linearGradient>
                            <linearGradient
                              id="colorPv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor={primaryColor}
                                stopOpacity={0.1}
                              />
                              <stop
                                offset="95%"
                                stopColor={primaryColor}
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis
                            dataKey="name"
                            tick={{
                              fontSize: 14,
                              fill: steelBlueColor,
                              fontWeight: 600,
                            }}
                            axisLine={{ stroke: "#FFFFFF" }}
                            tickLine={{ stroke: "#FFFFFF" }}
                            // tickSize={1}
                          />
                          <YAxis
                            tick={{
                              fontSize: 14,
                              fill: steelBlueColor,
                              fontWeight: 600,
                            }}
                            axisLine={{ stroke: "#FFFFFF" }}
                            tickLine={{ stroke: "#FFFFFF" }}
                            tickMargin={40}
                          />
                          <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={false}
                            stroke="#9EA4B240"
                          />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="uv"
                            stroke={goldenAmberColor}
                            fillOpacity={1}
                            fill="url(#colorUv)"
                          />
                          <Area
                            type="monotone"
                            dataKey="pv"
                            stroke={primaryColor}
                            fillOpacity={1}
                            fill="url(#colorPv)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 1,
              }}
            >
              <IconButton
                onClick={() => {
                  index !== 0 && setIndex(index - 1);
                  swiperRef.current.swiper.slideTo(Number(index) - 1);
                }}
              >
                <ChevronRightIcon
                  width={24}
                  height={24}
                  color={forestShadowColor}
                />
              </IconButton>
              <Box
                sx={{
                  backgroundColor: softMoonlightColor,
                  p: 1,
                  borderRadius: 1.25,
                  minWidth: 130,
                }}
              >
                <Typography
                  sx={{
                    color: ceruleanCascadeColor,
                    fontSize: 12,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {data.paylinks[index]?.name}
                </Typography>
              </Box>
              <IconButton
                onClick={() => {
                  index !== data.paylinks.length - 1 && setIndex(index + 1);
                  setActivePaylink(data.paylinks[index]);
                  swiperRef.current.swiper.slideTo(index + 1);
                }}
              >
                <ChevronLeftIcon
                  width={24}
                  height={24}
                  color={forestShadowColor}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
              {data.paylinks.map((p, i) => (
                <Box
                  key={i}
                  sx={{
                    width: 7,
                    height: 7,
                    backgroundColor:
                      i === index ? ceruleanCascadeColor : "#D9D9D9",
                    mx: 0.5,
                    borderRadius: 100,
                  }}
                ></Box>
              ))}
            </Box>
            {/* 
            <Grid item sx={{ mx: "auto" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={handleNext}
                  disabled={activeStep === steps.length - 1}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Box
                  sx={{
                    backgroundColor: softMoonlightColor,
                    p: 1,
                    borderRadius: 1.25,
                    minWidth: 130,
                  }}
                >
                  <Typography
                    sx={{
                      color: ceruleanCascadeColor,
                      fontSize: 12,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {steps[activeStep]}
                  </Typography>
                </Box>
                <IconButton onClick={handleBack} disabled={activeStep === 0}>
                  <ChevronLeftIcon />
                </IconButton>
              </Box>
              <MobileStepper
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                sx={{
                  justifyContent: "center",
                  "& .MuiMobileStepper-dotActive": {
                    backgroundColor: ceruleanCascadeColor,
                  },
                }}
              />
            </Grid> */}
          </Box>
        ) : (
          <Box
            sx={{
              // py: 2,
              // px: 2.5,
              borderRadius: 2.5,
              width: "100px",
              flex: { xs: "100%", lg: "57%" },
              ml: "20px",
              mb: { lg: "20px" },
              mr: { xs: "20px", lg: 0 },
              display: { xs: "none", lg: "block" },
            }}
          ></Box>
        )}
        <Box
          sx={{
            backgroundColor: whiteColor,
            borderRadius: 2.5,
            flex: { xs: "100%", lg: "25%" },
            mr: "20px",
            ml: { xs: "20px", lg: 0 },
            mb: "20px",
          }}
        >
          <Box
            sx={{
              backgroundColor: whiteColor,
              py: 2,
              px: 2.5,
              borderRadius: 2.5,
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 3 }}>
              حوزه کسب و کار
            </Typography>
            <Button
              text={businessName ? businessName : "انتخاب کنید ..."}
              endIcon={
                <ArrowLeftIcon
                  style={{
                    color: primaryColor,
                    width: 24,
                    marginRight: "auto",
                  }}
                />
              }
              color="arcticMist"
              onClick={() => setOpen(true)}
              // startIcon={
              //   <BriefcaseIcon
              //     style={{ width: 25, height: 25, color: primaryColor }}
              //   />
              // }
              sx={{
                py: 2,
                justifyContent: "space-between",
              }}
            />
          </Box>
        </Box>
      </Box>
      {renderBusinessCategories}
    </Box>
  );
};

export default Dashboard;
