import {
  Avatar,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Slider,
  Typography,
} from "@mui/material";
import CustomTextInput from "../../components/CustomTextInput";
import {
  arcticMistColor,
  forestShadowColor,
  icyBreezeColor,
  pearlyGrayColor,
  primaryColor,
  silverGrayColor,
  silverSageColor,
  slateGrayColor,
  tangerineBlazeColor,
  vanillaCreamColor,
  whiteColor,
} from "../../assets/colors";
import {
  AngryFaceIcon,
  AttachOutlineIcon,
  ChevronRightIcon,
  CloseIcon,
  FormkitSadIcon,
  HappyFaceIcon,
  InfoIcon,
  MMIcon,
  PokerFaceIcon,
  SendIcon,
  SmileFaceIcon,
  XXIcon,
} from "../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import { createElement, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createFeedbackTicketRequestAction,
  createReplyTicketRequestAction,
  getTicketConversationRequestAction,
} from "../../redux/actions/ticketActions";
import moment from "moment-jalaali";
import Button from "../../components/Button";
import { adminapiBaseUrl, apiBaseUrl, fullHeight } from "../../utils/constants";
import { ticketStatus } from "./constants";
// --------------------------------------------------------------------------------
const TicketConversation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { ticketId } = useParams();
  const endOfScrollRef = useRef(null);
  const fileInputRef = useRef(null);

  const [conversations, setConversations] = useState([]);
  const [
    { message, attachment, errors, isTicketClose, feedback, rate },
    setInitState,
  ] = useState({
    subject: "",
    category: "",
    subCategory: "",
    message: "",
    attachment: null,
    errors: {},
    isTicketClose: true,
    rate: 7,
    feedback: "",
  });

  useEffect(() => {
    dispatch(
      getTicketConversationRequestAction({
        ticketId,
        setConversations,
        setState,
      })
    );
  }, [ticketId]);

  useEffect(() => {
    // Scroll to the end of the scrollable element
    if (endOfScrollRef.current) {
      endOfScrollRef.current.scrollTop = endOfScrollRef.current.scrollHeight;
    }
  }, [conversations.length, isTicketClose]);

  const handleReply = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (message === "") errorObj.message = "لطفا متن تیکت را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    const letters = /^[A-Za-z-0-9_.-@-#-%-!-*-=-^-" "|]*$/gi;
    let firstletter = message.slice(0, 1);
    let inputLang;
    let findLang = letters.exec(firstletter);
    if (findLang) {
      inputLang = "en";
    } else {
      inputLang = "fa";
    }

    let formData = new FormData();
    formData.append("lang", inputLang);
    formData.append("ticket_id", ticketId);
    formData.append("text", message);
    formData.append("source", "Website");
    if (attachment) formData.append("attachment", attachment);

    dispatch(
      createReplyTicketRequestAction({
        data: formData,
        setConversations,
        setState,
        ticketId,
      })
    );
  };

  const renderFaceIcon = () => {
    let icon = null;

    switch (rate) {
      case 0:
      case 1:
      case 2:
        icon = AngryFaceIcon;
        break;
      case 3:
      case 4:
        icon = MMIcon;
        break;
      case 5:
      case 6:
        icon = PokerFaceIcon;
        break;
      case 7:
      case 8:
        icon = SmileFaceIcon;
        break;
      case 9:
      case 10:
        icon = XXIcon;
        break;
    }

    return createElement(icon, { width: 44, height: 44 });
  };

  const handleSendFeedback = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (feedback === "") errorObj.feedback = "لطفا نظر خود را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      createFeedbackTicketRequestAction({
        feedback,
        rate,
        setState,
        ticketId,
        setConversations,
      })
    );
  };

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const handleChipClick = () => {
    fileInputRef.current.click();
  };

  return (
    conversations.length > 0 && (
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            height: 70,
            display: "flex",
            alignItems: "center",
            px: "22px",
            backgroundColor: whiteColor,
          }}
        >
          <IconButton
            sx={{ backgroundColor: arcticMistColor, borderRadius: "4px" }}
            size="small"
            onClick={() => navigate("/tickets")}
          >
            <ChevronRightIcon width={24} height={24} />
          </IconButton>
          <Box sx={{ flex: 1, ml: "10px" }}>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 1 }}>
              {conversations[0].subject}
            </Typography>
            <Typography sx={{ fontSize: 10, fontWeight: 300 }}>
              شماره تیکت:{" "}
              <Typography
                sx={{ display: "inline-block", fontSize: 10, fontWeight: 500 }}
              >
                #{ticketId}
              </Typography>
            </Typography>
          </Box>
          <Chip label={ticketStatus[conversations[0].status] || "منتظر پاسخ"} />
        </Box>
        <Box
          ref={endOfScrollRef}
          sx={{
            // flex: 1,
            backgroundColor: arcticMistColor,
            px: "12px",
            height: isTicketClose
              ? {
                  xs: `calc(${fullHeight} - 260px)`,
                  lg: `calc(${fullHeight} - 205px)`,
                }
              : `calc(${fullHeight} - 333px)`,
            overflow: "auto",
          }}
        >
          {conversations.map(
            (item, index) =>
              item.text && (
                <Box
                  key={index}
                  sx={{
                    ml: item.admin_id ? "auto" : 0,
                    width: { lg: 392 },
                    mb: 2,
                    maxWidth: { xs: 347, lg: 376 },
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: item.admin_id
                        ? whiteColor
                        : primaryColor,
                      my: 1,
                      py: "9px",
                      pl: "11px",
                      pr: "10px",
                      borderRadius: item.admin_id
                        ? "12px 0 12px 12px"
                        : "0 12px 12px 12px",
                      boxShadow: "0px 0px 40px 0px #00000008",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: 12, lg: 14 },
                        color: item.admin_id ? forestShadowColor : whiteColor,
                        lineHeight: 2,
                        margin: "0px!important",
                        "> p": {
                          m: 0,
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    ></Typography>
                    {item.attachment && (
                      <Button
                        fullWidth={false}
                        text="دانلود فایل ضمیمه"
                        sx={{ display: "flex" }}
                        startIcon={<AttachOutlineIcon width={25} height={25} />}
                        // color="silverSage"
                        onClick={() =>
                          window.open(
                            item.admin_id
                              ? `${adminapiBaseUrl}/${item.attachment}`
                              : `${apiBaseUrl}/${item.attachment}`,
                            "_blank"
                          )
                        }
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: item.admin_id ? "row-reverse" : "row",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 300,
                        color: slateGrayColor,
                      }}
                    >
                      {moment(item.created_at).format("HH:mm - jYYYY/jMM/jDD")}
                    </Typography>
                  </Box>
                </Box>
              )
          )}
          {isTicketClose && conversations[0].feedback === null && (
            <Box
              sx={{
                borderTop: `2px dashed ${silverGrayColor}`,
                py: "74px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: whiteColor,
                  width: { lg: 488 },
                  mx: "auto",
                  borderRadius: 5,
                  pt: "30px",
                  pl: "30px",
                  pr: "20px",
                  pb: "20px",
                  boxShadow: "0px 0px 77px 20px #5F5F5F08",
                  position: "relative",
                }}
              >
                <IconButton sx={{ position: "absolute", right: 10, top: 8 }}>
                  <CloseIcon />
                </IconButton>
                <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                  <Box
                    sx={{
                      backgroundColor: silverSageColor,
                      width: 85,
                      height: 85,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    {renderFaceIcon()}
                  </Box>
                  <Box sx={{ ml: 1.5, flex: 1 }}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: forestShadowColor,
                        mb: 1,
                      }}
                    >
                      پاسخدهی چطور بود؟
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: slateGrayColor,
                        lineHeight: 1.8,
                      }}
                    >
                      به نحوه پاسخدهی این کارشناس از 1 تا 10 چه نمره ای میدهید؟
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <HappyFaceIcon />
                  <>
                    <Slider
                      // defaultValue={5}
                      // aria-label="Default"
                      // valueLabelDisplay="auto"
                      sx={{
                        width: { lg: 360 },
                        "& .MuiSlider-thumb": {
                          marginRight: -2, // Adjust the value as needed
                        },
                      }}
                      value={rate}
                      min={0}
                      max={10}
                      onChange={(e) => setState({ rate: e.target.value })}
                    />
                  </>
                  <FormkitSadIcon
                    width={20}
                    height={20}
                    color={forestShadowColor}
                    style={{ opacity: 0.2 }}
                  />
                </Box>
                <CustomTextInput
                  placeholder="... نظرت رو بنویس"
                  multiline
                  row={3}
                  sx={{
                    backgroundColor: icyBreezeColor,
                    "&.MuiInputBase-root": {
                      borderRadius: "8px!important",
                      mt: 2,
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          backgroundColor: primaryColor,
                          borderRadius: 1,
                          height: 80,
                        }}
                        onClick={handleSendFeedback}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={feedback}
                  onChange={(e) => setState({ feedback: e.target.value })}
                  error={errors.feedback}
                  helperText={errors.feedback}
                />
              </Box>
            </Box>
          )}
        </Box>
        {isTicketClose ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              px: 2,
              justifyContent: "space-between",
              flexWrap: "wrap",
              backgroundColor: whiteColor,
              // height: 68,
            }}
          >
            <Box
              sx={{
                width: { lg: 461 },
                backgroundColor: vanillaCreamColor,
                p: 1.375,
                borderRadius: "9px",
                display: "flex",
                alignItems: "center",
                flex: { xs: "100%", lg: "none" },
              }}
            >
              <InfoIcon
                style={{ marginLeft: 5 }}
                color={tangerineBlazeColor}
                width={20}
                height={20}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                تیکت شما بسته شده آیا میخواهید ادامه دهید؟
              </Typography>
            </Box>
            <Button
              text="ادامه گفتگو"
              // fullWidth={false}
              onClick={() =>
                setState({
                  isTicketClose: false,
                })
              }
              sx={{
                width: { lg: 141 },
                border: `1px solid ${silverSageColor}`,
                // display: { xs: "none", lg: "flex" },
              }}
              color="arcticMist2"
            />
          </Box>
        ) : (
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex" }}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => setState({ attachment: e.target.files[0] })}
                accept=".png, .jpg, .jpeg"
              />
              {attachment && (
                <Chip
                  label={attachment.name}
                  icon={
                    <Avatar
                      src={URL.createObjectURL(attachment)}
                      sx={{ width: 35, height: 23, borderRadius: "3px" }}
                    />
                  }
                  sx={{
                    mr: 1,
                    width: { lg: 138 },
                    fontSize: 12,
                    borderRadius: 2,
                    color: slateGrayColor,
                  }}
                  onDelete={() => setState({ attachment: null })}
                />
              )}
            </Box>
            <CustomTextInput
              placeholder="... پیامت رو بنویس"
              multiline
              row={3}
              sx={{
                backgroundColor: icyBreezeColor,
                "&.MuiInputBase-root": { borderRadius: "8px!important", mt: 2 },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      backgroundColor: pearlyGrayColor,
                      borderRadius: 1,
                      height: 80,
                      mr: 0.5,
                    }}
                    onClick={handleChipClick}
                    disabled={attachment}
                  >
                    <AttachOutlineIcon width={20} height={20} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: primaryColor,
                      borderRadius: 1,
                      height: 80,
                    }}
                    onClick={handleReply}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
              value={message}
              onChange={(e) => setState({ message: e.target.value })}
            />
          </Box>
        )}
      </Box>
    )
  );
};

export default TicketConversation;
