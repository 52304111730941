// import CheckIcon from "@mui/icons-material/Check";
// import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { alpha, styled } from "@mui/material/styles";
// import AddIcon from "@mui/icons-material/Add";
import {
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  useMediaQuery,
} from "@mui/material";
import {
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import { createElement } from "react";
// --------------------------------------------------------------------------------
const MuiStepper = ({ activeStep, steps, size }) => {
  let isSmall = size === "sm";
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      ownerState.active || ownerState.completed
        ? primaryColor
        : silverSageColor,
    zIndex: 1,
    color: "#ffffff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {createElement(props.icon, { width: 24, height: 24 })}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Stepper
      orientation="vertical"
      activeStep={steps.findIndex((step) => step.value === activeStep)}
      connector={<ColorlibConnector />}
      // sx={{ position: "relative", left: 10 }}
    >
      {steps.map((step, index) => (
        <Step key={step.value}>
          <StepLabel
            StepIconComponent={(props) =>
              ColorlibStepIcon({ ...props, icon: step.icon })
            }
          >
            <Typography sx={{ fontSize: 10 }}>مرحله {index + 1}</Typography>
            <Typography sx={{ fontSize: 14 }}>{step.label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default MuiStepper;

const ColorlibConnector = styled(StepConnector)(({ theme }) => {
  return {
    // [`&.${stepConnectorClasses.alternativeLabel}`]: {
    //   top: 18,
    //   right: "50%",
    //   left: "-50%",
    // },
    [`&.${stepConnectorClasses.root}`]: {
      marginLeft: 18,
    },
    [`& .${stepConnectorClasses.line}`]: {
      // border: 0,
      // borderRadius: 1,
      minHeight: 37,
      borderWidth: 3,
      borderColor: silverSageColor,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: primaryColor,
      },
    },
    [` &.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: primaryColor,
        opacity: 0.4,
      },
    },
  };
});
