import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getPersonalPaylinksAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "personal-paylink/get-all",
  });
};
export const createPersonalPaylinkAPI = ({ data }) => {
  let postData = data;
  return axiosMiddleware({
    method: "post",
    url: "personal-paylink/create",
    data: postData,
  });
};
export const getProductPaylinksAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "product-paylink/get-all",
  });
};
export const createProductPaylinkAPI = ({ data }) => {
  let postData = data;
  return axiosMiddleware({
    method: "post",
    url: "product-paylink/create",
    data: postData,
  });
};
export const cancelPersonalPaylinkAPI = ({ id }) => {
  let postData = { id };
  return axiosMiddleware({
    method: "post",
    url: "personal-paylink/cancel",
    data: postData,
  });
};
export const cancelProductPaylinkAPI = ({ id }) => {
  let postData = { id };
  return axiosMiddleware({
    method: "post",
    url: "product-paylink/cancel",
    data: postData,
  });
};
