import {
  CREATE_PERSONAL_PAYLINK_FAILURE,
  CREATE_PERSONAL_PAYLINK_STARTED,
  CREATE_PERSONAL_PAYLINK_SUCCESS,
  CREATE_PRODUCT_PAYLINK_FAILURE,
  CREATE_PRODUCT_PAYLINK_STARTED,
  CREATE_PRODUCT_PAYLINK_SUCCESS,
  GET_PERSONAL_PAYLINKS_FAILURE,
  GET_PERSONAL_PAYLINKS_STARTED,
  GET_PERSONAL_PAYLINKS_SUCCESS,
  GET_PRODUCT_PAYLINKS_FAILURE,
  GET_PRODUCT_PAYLINKS_STARTED,
  GET_PRODUCT_PAYLINKS_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: "",
  personalPaylinks: [],
  productPaylinks: [],
  error: "",
};
const paylinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERSONAL_PAYLINKS_STARTED:
    case CREATE_PERSONAL_PAYLINK_STARTED:
    case GET_PRODUCT_PAYLINKS_STARTED:
    case CREATE_PRODUCT_PAYLINK_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_PERSONAL_PAYLINKS_SUCCESS:
      return {
        ...state,
        rType: GET_PERSONAL_PAYLINKS_SUCCESS,
        personalPaylinks: action.payload.personalPaylinks,
        error: "",
      };
    case CREATE_PERSONAL_PAYLINK_SUCCESS:
      return {
        ...state,
        rType: CREATE_PERSONAL_PAYLINK_SUCCESS,
        error: "",
      };
    case GET_PRODUCT_PAYLINKS_SUCCESS:
      return {
        ...state,
        rType: GET_PRODUCT_PAYLINKS_SUCCESS,
        productPaylinks: action.payload.productPaylinks,
        error: "",
      };
    case CREATE_PRODUCT_PAYLINK_SUCCESS:
      return {
        ...state,
        rType: CREATE_PRODUCT_PAYLINK_SUCCESS,
        error: "",
      };
    case GET_PERSONAL_PAYLINKS_FAILURE:
    case CREATE_PERSONAL_PAYLINK_FAILURE:
    case GET_PRODUCT_PAYLINKS_FAILURE:
    case CREATE_PRODUCT_PAYLINK_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default paylinkReducer;
