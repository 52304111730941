import {
  GET_EVENTS_LIST_FAILURE,
  GET_EVENTS_LIST_STARTED,
  GET_EVENTS_LIST_SUCCESS,
  GET_TRANSACTIONS_COORDINATES_FAILURE,
  GET_TRANSACTIONS_COORDINATES_STARTED,
  GET_TRANSACTIONS_COORDINATES_SUCCESS,
  GET_TRANSACTIONS_LIST_FAILURE,
  GET_TRANSACTIONS_LIST_STARTED,
  GET_TRANSACTIONS_LIST_SUCCESS,
  GET_TRANSACTIONS_REPORT_FAILURE,
  GET_TRANSACTIONS_REPORT_STARTED,
  GET_TRANSACTIONS_REPORT_SUCCESS,
  REFUND_TRANSACTION_FAILURE,
  REFUND_TRANSACTION_STARTED,
  REFUND_TRANSACTION_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: "",
  transactionsList: [],
  eventsList: [],
  transactionsReport: {},
  transactionsCoordinates: [],
  error: "",
};
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_LIST_STARTED:
    case GET_EVENTS_LIST_STARTED:
    case GET_TRANSACTIONS_REPORT_STARTED:
    case GET_TRANSACTIONS_COORDINATES_STARTED:
    case REFUND_TRANSACTION_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_TRANSACTIONS_LIST_SUCCESS,
        transactionsList: action.payload.transactionsList,
        error: "",
      };
    case GET_TRANSACTIONS_REPORT_SUCCESS:
      return {
        ...state,
        rType: GET_TRANSACTIONS_REPORT_SUCCESS,
        transactionsReport: action.payload.transactionsReport,
        error: "",
      };
    case GET_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_EVENTS_LIST_SUCCESS,
        eventsList: action.payload.eventsList,
        error: "",
      };
    case GET_TRANSACTIONS_COORDINATES_SUCCESS:
      return {
        ...state,
        rType: GET_TRANSACTIONS_COORDINATES_SUCCESS,
        transactionsCoordinates: action.payload.transactionsCoordinates,
        error: "",
      };
    case REFUND_TRANSACTION_SUCCESS:
      return {
        ...state,
        rType: REFUND_TRANSACTION_SUCCESS,
        error: "",
      };
    case GET_TRANSACTIONS_LIST_FAILURE:
    case GET_TRANSACTIONS_REPORT_FAILURE:
    case GET_EVENTS_LIST_FAILURE:
    case GET_TRANSACTIONS_COORDINATES_FAILURE:
    case REFUND_TRANSACTION_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default transactionReducer;
