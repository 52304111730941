import {
  ChevronDownIcon,
  CloseIcon,
  CopyIcon,
  DownloadIcon,
  EyeIcon,
  ScanBarcodeIcon,
  ShareIcon,
  ShoppingBagIcon,
  TrashIcon2,
} from "../../../assets/icons";
import {
  arcticMistColor,
  coralSunset,
  forestShadowColor,
  goldenAmberColor,
  primaryColor,
  silverSageColor,
  whiteColor,
} from "../../../assets/colors";
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { statusObj } from "../../Account/constants";
import { useDispatch } from "react-redux";
import { cancelPersonalPaylinkRequestAction } from "../../../redux/actions/paylinkAction";
import {
  apiBaseUrl,
  appBarHeight,
  drawerWidth,
} from "../../../utils/constants";
import { QRCodeSVG } from "qrcode.react";

const Item = ({ paylink }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const svgRef = useRef();

  const downloadQRCode = () => {
    const svgElement = svgRef.current;
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const svgSize = svgElement.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;

    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: paylink.title,
          text: "Here is something interesting for you!",
          url: paylink.link,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Sharing not supported on this browser");
    }
  };

  const renderDialog = (
    <Dialog
      open={showDialog}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
          width: 452,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() => setShowDialog(false)}
      scroll="body"
    >
      <DialogContent sx={{ pb: 0 }}>
        <Typography>دلایل رد شدن درخواست شما:</Typography>
      </DialogContent>
      <DialogContent>{paylink.admin_description}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: "24px",
          pb: "38px",
          pt: "17px",
        }}
      ></DialogActions>
    </Dialog>
  );

  const renderMerchantCode = (
    <Dialog
      open={showQrCode}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08;",
          width: 488,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(218, 218, 218, 0.21)",
          backdropFilter: " blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
          borderLeft: "1px solid #E7EFEC",
        },
      }}
      sx={{ left: { lg: drawerWidth }, top: appBarHeight }}
      onClose={() => setShowQrCode(false)}
      scroll="body"
    >
      <IconButton
        aria-label="close"
        onClick={() => setShowQrCode(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon width={18} />
      </IconButton>
      <DialogTitle sx={{ px: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              height: 40,
              width: 40,
              backgroundColor: arcticMistColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
            }}
          >
            <ScanBarcodeIcon style={{ width: 25, color: primaryColor }} />
          </Box>
          <Typography sx={{ fontWeight: 700, ml: 1 }}>
            QR Code پی‌لینک {paylink.title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <QRCodeSVG value={paylink.link} level={"H"} ref={svgRef} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <IconButton
          sx={{ backgroundColor: arcticMistColor }}
          onClick={handleShare}
        >
          <ShareIcon style={{ width: 20 }} />
        </IconButton>
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={downloadQRCode}
        >
          <DownloadIcon style={{ width: 20 }} />
        </IconButton>
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={() => navigator.clipboard.writeText(paylink.link)}
        >
          <CopyIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box
      sx={{
        border: `1px solid ${silverSageColor}`,
        p: { xs: 1, lg: 2 },
        my: { lg: 3 },
        mb: { xs: 3, lg: 0 },
        borderRadius: 3,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <Avatar
          sx={{
            height: { xs: 32, lg: 42 },
            width: { xs: 32, lg: 42 },
            mr: 1.5,
            backgroundColor: forestShadowColor,
          }}
          src={`${apiBaseUrl}/${paylink.image}`}
        >
          <Box component={ShoppingBagIcon} sx={{ width: { xs: 16, lg: 24 } }} />
        </Avatar>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 12, lg: 14 },
              fontWeight: 600,
              mb: 0.5,
            }}
          >
            {paylink.title}
          </Typography>
          <Typography
            sx={{
              color: statusObj[paylink.status]?.color,
              fontSize: { xs: 10, lg: 12 },
              fontWeight: 500,
            }}
          >
            <Box
              sx={{
                width: { xs: 5, lg: 7 },
                height: { xs: 5, lg: 7 },
                borderRadius: 100,
                backgroundColor: statusObj[paylink.status]?.color,
                display: "inline-block",
                mr: 0.4,
              }}
            ></Box>{" "}
            {statusObj[paylink.status]?.label}
          </Typography>
        </Box>
        {paylink.admin_description && (
          <IconButton
            onClick={() => setShowDialog(true)}
            sx={{
              backgroundColor: coralSunset,
              ml: "auto",
              display: { lg: "none" },
            }}
          >
            <EyeIcon width={16} color={whiteColor} />
          </IconButton>
        )}
        <IconButton
          sx={{
            backgroundColor: arcticMistColor,
            ml: { xs: paylink.admin_description ? 1 : "auto", lg: 1 },
            display: { lg: "none" },
          }}
          onClick={() => setOpen(!open)}
        >
          <ChevronDownIcon width={16} style={{ transform: "rotate(180deg)" }} />
        </IconButton>
        {paylink.admin_description && (
          <Chip
            label="مشاهده دلایل"
            onClick={() => setShowDialog(true)}
            color="coralSunset"
            sx={{
              ml: "auto",
              mr: 1,
              display: { xs: "none", lg: "inline-flex" },
            }}
          />
        )}
        <Chip
          label="مشاهده آمار"
          onClick={() => setOpen(!open)}
          deleteIcon={
            <ChevronDownIcon
              width={16}
              style={{ transform: "rotate(180deg)" }}
            />
          }
          color="arcticMist4"
          onDelete={() => {}}
          sx={{
            ml: paylink.admin_description ? 0 : "auto",
            display: { xs: "none", lg: "flex" },
          }}
        />
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={() => navigator.clipboard.writeText(paylink.link)}
        >
          <CopyIcon />
        </IconButton>
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={() => setShowQrCode(true)}
        >
          <ScanBarcodeIcon style={{ width: 18 }} />
        </IconButton>
        {/* <IconButton sx={{ backgroundColor: arcticMistColor, ml: 1 }}>
          <EditIcon color={forestShadowColor} width={18} height={18} />
        </IconButton> */}
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={() =>
            dispatch(
              cancelPersonalPaylinkRequestAction({
                id: paylink.personal_paylink_id,
              })
            )
          }
        >
          <TrashIcon2 style={{ width: 18 }} />
        </IconButton>
      </Box>
      {open && (
        <>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box sx={{ width: { lg: 300 }, order: { xs: 2, lg: 1 } }}>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Typography>حجم تراکنش امروز:</Typography>
                <Typography sx={{ color: primaryColor }}>
                  {paylink.today_paylink_amount}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Typography>تعداد تراکنش امروز:</Typography>
                <Typography sx={{ color: goldenAmberColor }}>
                  {paylink.today_paylink_count}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                  }}
                >
                  هفته گذشته
                </Typography>
                <Box sx={{ display: "flex", textAlign: "center" }}>
                  <Box sx={{ p: 2, flex: 0.5 }}>
                    <Typography
                      sx={{
                        color: primaryColor,
                        fontSize: 25,
                        fontWeight: 700,
                        lineHeight: 1.7,
                      }}
                    >
                      {paylink.week_paylink_amount}
                    </Typography>
                    <Typography sx={{ fontSize: 10 }}>جمع کل فروش</Typography>
                  </Box>
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <Box sx={{ p: 2, flex: 0.5, textAlign: "center" }}>
                    <Typography
                      sx={{
                        color: goldenAmberColor,
                        fontSize: 30,
                        fontWeight: 700,
                      }}
                    >
                      {paylink.week_paylink_count}
                    </Typography>
                    <Typography sx={{ fontSize: 10 }}>تراکنش</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                pl: { lg: 3 },
                height: { xs: 120, lg: "unset" },
                width: "100%",
                order: 1,
                mb: { xs: 5, lg: 0 },
              }}
            >
              <ResponsiveContainer>
                <AreaChart
                  // width={492}
                  // height={250}
                  data={paylink.paylink_week}
                  margin={{ top: 10, right: 30, left: -30, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={goldenAmberColor}
                        stopOpacity={0.1}
                      />
                      <stop
                        offset="95%"
                        stopColor={goldenAmberColor}
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={primaryColor}
                        stopOpacity={0.1}
                      />
                      <stop
                        offset="95%"
                        stopColor={primaryColor}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke={goldenAmberColor}
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                  <Area
                    type="monotone"
                    dataKey="pv"
                    stroke={primaryColor}
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </>
      )}
      {renderDialog}
      {renderMerchantCode}
    </Box>
  );
};

export default Item;
