import { Avatar, Box, Chip, Tab, Tabs, Typography } from "@mui/material";
import styled from "@emotion/styled";
import {
  arcticMistColor,
  primaryColor,
  slateGrayColor,
  whisperingSnowColor,
  whiteColor,
} from "../../../assets/colors";
import { ImageIcon, ListIcon } from "../../../assets/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductRequestAction,
  deleteProductRequestAction,
  getProductsListRequestAction,
} from "../../../redux/actions/productActions";
import Table2 from "../../../components/Table2";
import AddProduct from "./AddProduct";
import Item from "./item";
import { apiBaseUrl, dmSans } from "../../../utils/constants";

const ProductsList = () => {
  const dispatch = useDispatch();

  const { productList, rType } = useSelector((state) => state.product);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [count, setCount] = useState(1);

  useEffect(() => {
    dispatch(
      getProductsListRequestAction({
        limit: rowPerPage,
        offset: page,
        setCount,
      })
    );
  }, []);

  const columns = [
    {
      field: "title", //access nested data with dot notation
      label: "نام محصول",
      renderCell: (row) => (
        <Box
          sx={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ backgroundColor: arcticMistColor }}
            src={`${apiBaseUrl}/${row.image}`}
            variant="rounded"
          >
            <ImageIcon />
          </Avatar>
          <Box sx={{ flex: 1, ml: 1 }}>
            <Typography sx={{ mb: 0.5 }}>{row.title}</Typography>
            <Typography sx={{ fontSize: 12, color: slateGrayColor }}>
              {row.detail}
            </Typography>
          </Box>
        </Box>
      ),
    },
    // {
    //   field: "detail",
    //   label: "جزئیات",
    // },
    {
      field: "description",
      label: "توضیحات",
    },
    {
      field: "amount",
      label: "قیمت",
      renderCell: (row) => `€${row.amount}`,
      sx: { fontFamily: dmSans, fontWeight: 600 },
    },
    {
      field: "price",
      label: "عملیات",
      renderCell: (row) => (
        <Chip
          label="حذف محصول"
          sx={{
            fontSize: 12,
            height: "24px!important",
            fontWeight: 500,
          }}
          onClick={() =>
            dispatch(
              deleteProductRequestAction({ productId: row.id, setCount })
            )
          }
          color="peachPink"
        />
      ),
    },
  ];

  return (
    <Box sx={{ flex: 1, p: { xs: "15px", lg: 3 } }}>
      <Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            p: { lg: 3, xs: 2 },
            border: `1px solid ${whisperingSnowColor}`,
            borderRadius: 2.5,
          }}
        >
          <Box
            sx={{
              // display: { xs: "none", lg: "flex" },
              display: "flex",
              alignItems: "center",
              mb: 3,
            }}
          >
            <ListIcon color={primaryColor} />{" "}
            <Typography
              sx={{ ml: 1, fontWeight: 600, fontSize: { xs: 16, lg: 18 } }}
            >
              محصولات
            </Typography>{" "}
            <Chip
              label="+ افزودن محصول جدید"
              color="brightTurquoise"
              sx={{ ml: "auto" }}
              onClick={() => setOpen(true)}
            />
          </Box>
          <Table2
            data={productList}
            columns={columns}
            count={count}
            onChangePage={(page) => setPage(page)}
          />
          {productList.map((p, index) => (
            <Item
              key={index}
              product={p}
              onDelete={() =>
                dispatch(
                  deleteProductRequestAction({ productId: p.id, setCount })
                )
              }
            />
          ))}
        </Box>
      </Box>
      <AddProduct
        open={open}
        setOpen={setOpen}
        setCount={setCount}
        onAdd={({ formData, setState }) => {
          dispatch(
            createProductRequestAction({
              formData,
              setOpen,
              setState,
              setCount,
            })
          );
        }}
      />
    </Box>
  );
};

export default ProductsList;

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  //   "& .MuiTabs-indicator": {
  //     backgroundColor: "#1890ff",
  //   },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "10px 8px",
    fontSize: 12,
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    // "&:hover": {
    //   color: "#40a9ff",
    //   opacity: 1,
    // },
    "&.Mui-selected": {
      //   color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
