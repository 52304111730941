import { useEffect, useState } from "react";
import Item from "./Item";
import { useDispatch, useSelector } from "react-redux";
import {
  getPersonalPaylinksRequestAction,
  getProductPaylinksRequestAction,
} from "../../../redux/actions/paylinkAction";

const ProductPaylinks = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);

  const { productPaylinks } = useSelector((state) => state.paylink);

  useEffect(() => {
    dispatch(getProductPaylinksRequestAction());
  }, []);

  return productPaylinks.map((paylink) => <Item paylink={paylink} />);
};

export default ProductPaylinks;
