import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import Button from "../../../components/Button";
import {
  arcticMistColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
} from "../../../assets/colors";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import Pic from "./pic.png";

const Step0 = ({ setActiveStep }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [tab, setTab] = useState(0);

  switch (tab) {
    case 0:
      return (
        <>
          <Box
            sx={{
              width: { lg: 455 },
              pr: { sx: 0, lg: 3 },
              flex: 1,
              position: "relative",
            }}
          >
            <img src={Pic} alt="" width="100%" style={{ borderRadius: 12 }} />
            <Typography sx={{ fontWeight: 700, my: 1 }}>
              به وب گیت خوش آمدید!
            </Typography>
            <Typography
              sx={{ fontSize: 14, color: slateGrayColor, lineHeight: 2 }}
            >
              وب‌گیت؛ درگاه بانکی بین المللی وب‌سایت با نصب این درگاه بانکی
              بین‌المللی روی وبسایت فروش خدمات و محصولاتتان، به جمع هزاران
              کسب‌وکاری بپیوندید که درگاه پرداخت بین‌المللی دارند. این درگاه امن
              به مشتریان شما این امکان را می‌دهد که با خیال آسوده به شما پرداخت
              کنند. معادل ریالی این مبالغ در حساب بانکی شما قابل برداشت خواهد
              بود.
            </Typography>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: { xs: 0, lg: 24 },
              }}
            >
              <Button text="ساخت اولین وب گیت" onClick={() => setTab(1)} />
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{ mb: 10, display: { xs: "none", lg: "block" } }}
          />
        </>
      );
    case 1:
      return (
        <>
          <Box
            sx={{
              width: { lg: 455 },
              pr: { sx: 0, lg: 3 },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: { xs: 18, lg: 32 },
                    backgroundColor: primaryColor,
                    position: "absolute",
                    left: { xs: -35, lg: -25 },
                    borderTopRightRadius: 32,
                    borderBottomRightRadius: 32,
                  }}
                ></Box>
                <Typography
                  sx={{
                    ml: { xs: 0.5, lg: 2 },
                    fontSize: { xs: 14, lg: 20 },
                    fontWeight: 600,
                  }}
                >
                  قبل از شروع خوانده شود!
                </Typography>
              </Box>
              <Box
                sx={{ backgroundColor: arcticMistColor, p: 2, borderRadius: 3 }}
              >
                <Typography
                  sx={{ fontSize: { xs: 12, lg: 14 }, lineHeight: 2 }}
                >
                  برای فعال‌سازی وب گیت، وب سایت شما باید شرایط زیر را داشته
                  باشد:
                  <br />
                  <br />
                  - تمامی صفحات وب سایت به طور کامل پیاده‌سازی شده باشند.
                  <br />
                  - محصولات یا خدمات به طور کامل در وب سایت تعریف شده باشند.
                  <br />
                  - قیمت‌گذاری محصولات یا خدمات در وب سایت به یورو باشد.
                  <br />
                  - فرم دریافت اطلاعات مشتری قبل از مرحله پرداخت طراحی و قابل
                  مشاهده باشد. این فرم باید شامل - - - اطلاعات زیر باشد: نام و
                  نام خانوادگی، آدرس ایمیل، شماره تماس، کشور، آدرس و کد پستی.
                  <br />
                  - دامنه وب سایت شما ir. نباشد.
                  <br />
                  در صورتی که وب سایت شما این شرایط را دارد، می‌توانید درخواست
                  فعال‌سازی وب گیت خود را ثبت نمایید.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                text={isMobile ? "قبلی" : "مرحله قبل"}
                onClick={() => setTab(0)}
                fullWidth={false}
                color="arcticMist"
                sx={{
                  width: { xs: 93, lg: 156 },
                  border: `1px solid ${silverSageColor}`,
                }}
              />
              <Button
                text="مرحله بعد"
                onClick={() => setActiveStep(1)}
                fullWidth={false}
                sx={{ width: { xs: 196, lg: 156 } }}
              />
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{ mb: 10, display: { xs: "none", lg: "block" } }}
          />
        </>
      );
  }
};

export default Step0;
