import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getTransactionsListAPI = ({
  from,
  to,
  offset,
  limit,
  eventId = 0,
}) => {
  return axiosMiddleware({
    method: "get",
    url: `transaction/all?from=${from}&to=${to}&limit=${limit}&offset=${offset}&event_id=${eventId}`,
  });
};
export const getTransactionsReportAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "transaction/get-data",
  });
};
export const refundTransactionAPI = ({
  transactionId,
  amount,
  description,
}) => {
  let postData = {
    transaction_id: transactionId,
    amount,
    description,
  };
  return axiosMiddleware({
    method: "post",
    url: "transaction/refund",
    data: postData,
  });
};
export const getEventsListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "get-events",
  });
};
export const getTransactionsCoordinatesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "get-transactions-map",
  });
};
export const getLastTransactionsAPI = ({ countryCode }) => {
  return axiosMiddleware({
    method: "get",
    url: `get-latest-transactions?country_code=${countryCode}`,
  });
};
