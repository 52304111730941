import { showSnackbar } from "../../components/Snackbar";
import {
  addBankCardAPI,
  changeProfilePictureAPI,
  createUserCompanyAPI,
  createUserPasswordAPI,
  editUserAddressAPI,
  getBankCardsAPI,
  getPlansHistoryAPI,
  getUserCompanyAPI,
  sendEmailTokenAPI,
  sendUserEmailAPI,
  upgradePlanAPI,
  verifyEmailTokenAPI,
  verifyUserEmailAPI,
} from "../../services/api/user";
import {
  ADD_BANK_CARD_FAILURE,
  ADD_BANK_CARD_STARTED,
  ADD_BANK_CARD_SUCCESS,
  CHANGE_PROFILE_PICTURE_FAILURE,
  CHANGE_PROFILE_PICTURE_STARTED,
  CHANGE_PROFILE_PICTURE_SUCCESS,
  CREATE_USER_COMPANY_FAILURE,
  CREATE_USER_COMPANY_STARTED,
  CREATE_USER_COMPANY_SUCCESS,
  CREATE_USER_PASSWORD_FAILURE,
  CREATE_USER_PASSWORD_STARTED,
  CREATE_USER_PASSWORD_SUCCESS,
  EDIT_USER_ADDRESS_FAILURE,
  EDIT_USER_ADDRESS_STARTED,
  EDIT_USER_ADDRESS_SUCCESS,
  GET_BANK_CARDS_FAILURE,
  GET_BANK_CARDS_STARTED,
  GET_BANK_CARDS_SUCCESS,
  GET_PLANS_HISTORY_FAILURE,
  GET_PLANS_HISTORY_STARTED,
  GET_PLANS_HISTORY_SUCCESS,
  GET_USER_COMPANY_FAILURE,
  GET_USER_COMPANY_STARTED,
  GET_USER_COMPANY_SUCCESS,
  SEND_EMAIL_TOKEN_FAILURE,
  SEND_EMAIL_TOKEN_STARTED,
  SEND_EMAIL_TOKEN_SUCCESS,
  SEND_USER_EMAIL_FAILURE,
  SEND_USER_EMAIL_STARTED,
  SEND_USER_EMAIL_SUCCESS,
  UPGRADE_PLAN_FAILURE,
  UPGRADE_PLAN_STARTED,
  UPGRADE_PLAN_SUCCESS,
  VERIFY_EMAIL_TOKEN_FAILURE,
  VERIFY_EMAIL_TOKEN_STARTED,
  VERIFY_EMAIL_TOKEN_SUCCESS,
  VERIFY_USER_EMAIL_FAILURE,
  VERIFY_USER_EMAIL_STARTED,
  VERIFY_USER_EMAIL_SUCCESS,
} from "../actionTypes";
import { getUserProfileRequestAction } from "./authActions";
// --------------------------------------------------------------------------------
export const addBankCardRequestAction = ({
  iban,
  currencyId,
  setOpen,
  setErrors,
}) => {
  return async (dispatch) => {
    dispatch(addBankCardStartedAction());
    try {
      let response = await addBankCardAPI({ iban, currencyId });
      if (response.status === "success") {
        dispatch(addBankCardSuccessAction(response.data));
        dispatch(getBankCardsRequestAction());
        setOpen(false);
      } else {
        dispatch(addBankCardFailureAction(response.data));
      }
    } catch (error) {
      dispatch(addBankCardFailureAction(error.message));
      setErrors({ iban: error.message });
    }
  };
};

export const addBankCardStartedAction = () => ({
  type: ADD_BANK_CARD_STARTED,
});

export const addBankCardSuccessAction = (payload) => ({
  type: ADD_BANK_CARD_SUCCESS,
  payload,
});

export const addBankCardFailureAction = (message) => ({
  type: ADD_BANK_CARD_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getBankCardsRequestAction = () => {
  return async (dispatch) => {
    dispatch(getBankCardsStartedAction());
    try {
      let response = await getBankCardsAPI();
      if (response.status === "success") {
        dispatch(getBankCardsSuccessAction({ bankCards: response.data }));
      } else {
        dispatch(getBankCardsFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getBankCardsFailureAction(error.message));
    }
  };
};

export const getBankCardsStartedAction = () => ({
  type: GET_BANK_CARDS_STARTED,
});

export const getBankCardsSuccessAction = (payload) => ({
  type: GET_BANK_CARDS_SUCCESS,
  payload,
});

export const getBankCardsFailureAction = (message) => ({
  type: GET_BANK_CARDS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getPlansHistoryRequestAction = () => {
  return async (dispatch) => {
    dispatch(getPlansHistoryStartedAction());
    try {
      let response = await getPlansHistoryAPI();
      if (response.status === "success") {
        dispatch(
          getPlansHistorySuccessAction({
            plansHistory: response.data,
          })
        );
      } else {
        dispatch(getPlansHistoryFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getPlansHistoryFailureAction(error.message));
    }
  };
};

export const getPlansHistoryStartedAction = () => ({
  type: GET_PLANS_HISTORY_STARTED,
});

export const getPlansHistorySuccessAction = (payload) => ({
  type: GET_PLANS_HISTORY_SUCCESS,
  payload,
});

export const getPlansHistoryFailureAction = (message) => ({
  type: GET_PLANS_HISTORY_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const changeProfilePictureRequestAction = ({
  formData,
  setShowProfileModal,
}) => {
  return async (dispatch) => {
    dispatch(changeProfilePictureStartedAction());
    try {
      let response = await changeProfilePictureAPI(formData);
      if (response.status === "success") {
        dispatch(changeProfilePictureSuccessAction());
        dispatch(getUserProfileRequestAction());
        setShowProfileModal(false);
      } else {
        dispatch(changeProfilePictureFailureAction(response.data));
      }
    } catch (error) {
      dispatch(changeProfilePictureFailureAction(error.message));
    }
  };
};

export const changeProfilePictureStartedAction = () => ({
  type: CHANGE_PROFILE_PICTURE_STARTED,
});

export const changeProfilePictureSuccessAction = (payload) => ({
  type: CHANGE_PROFILE_PICTURE_SUCCESS,
  payload,
});

export const changeProfilePictureFailureAction = (message) => ({
  type: CHANGE_PROFILE_PICTURE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const sendEmailTokenRequestAction = ({ email, setState }) => {
  return async (dispatch) => {
    dispatch(sendEmailTokenStartedAction());
    try {
      let response = await sendEmailTokenAPI({ email });
      if (response.status === "success") {
        dispatch(sendEmailTokenSuccessAction());
        setState({ showTokenInput: true, token: "", counter: 120 });
      } else {
        dispatch(sendEmailTokenFailureAction(response.data));
      }
    } catch (error) {
      dispatch(sendEmailTokenFailureAction(error.message));
    }
  };
};

export const sendEmailTokenStartedAction = () => ({
  type: SEND_EMAIL_TOKEN_STARTED,
});

export const sendEmailTokenSuccessAction = (payload) => ({
  type: SEND_EMAIL_TOKEN_SUCCESS,
  payload,
});

export const sendEmailTokenFailureAction = (message) => ({
  type: SEND_EMAIL_TOKEN_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const verifyEmailTokenRequestAction = ({ token, setState }) => {
  return async (dispatch) => {
    dispatch(verifyEmailTokenStartedAction());
    try {
      let response = await verifyEmailTokenAPI({ token });
      if (response.status === "success") {
        dispatch(verifyEmailTokenSuccessAction());
        setState({ activeStep: 1 });
      } else {
        dispatch(verifyEmailTokenFailureAction(response.data));
      }
    } catch (error) {
      dispatch(verifyEmailTokenFailureAction(error.message));
    }
  };
};

export const verifyEmailTokenStartedAction = () => ({
  type: VERIFY_EMAIL_TOKEN_STARTED,
});

export const verifyEmailTokenSuccessAction = (payload) => ({
  type: VERIFY_EMAIL_TOKEN_SUCCESS,
  payload,
});

export const verifyEmailTokenFailureAction = (message) => ({
  type: VERIFY_EMAIL_TOKEN_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createUserCompanyRequestAction = ({ formData, setState }) => {
  return async (dispatch) => {
    dispatch(createUserCompanyStartedAction());
    try {
      let response = await createUserCompanyAPI(formData);
      if (response.status === "success") {
        dispatch(createUserCompanySuccessAction());

        setState({ companyOwners: [{}], open: false });
        dispatch(getUserCompanyRequestAction());
      } else {
        dispatch(createUserCompanyFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createUserCompanyFailureAction(error.message));
    }
  };
};

export const createUserCompanyStartedAction = () => ({
  type: CREATE_USER_COMPANY_STARTED,
});

export const createUserCompanySuccessAction = (payload) => ({
  type: CREATE_USER_COMPANY_SUCCESS,
  payload,
});

export const createUserCompanyFailureAction = (message) => ({
  type: CREATE_USER_COMPANY_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getUserCompanyRequestAction = () => {
  return async (dispatch) => {
    dispatch(getUserCompanyStartedAction());
    try {
      let response = await getUserCompanyAPI();
      if (response.status === "success") {
        dispatch(getUserCompanySuccessAction({ userCompany: response.data }));
      } else {
        dispatch(getUserCompanyFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getUserCompanyFailureAction(error.message));
    }
  };
};

export const getUserCompanyStartedAction = () => ({
  type: GET_USER_COMPANY_STARTED,
});

export const getUserCompanySuccessAction = (payload) => ({
  type: GET_USER_COMPANY_SUCCESS,
  payload,
});

export const getUserCompanyFailureAction = (message) => ({
  type: GET_USER_COMPANY_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const upgradePlanRequestAction = ({
  planId,
  duration,
  setState,
  setRecieptData,
}) => {
  return async (dispatch) => {
    dispatch(upgradePlanStartedAction());
    try {
      let response = await upgradePlanAPI({ planId, duration });
      if (response.status === "success") {
        dispatch(upgradePlanSuccessAction());
        dispatch(getUserProfileRequestAction());
        setRecieptData(response.data);
        setState({
          payStep: 2,
          openPersonalPlanConfirm: false,
          selectedPlan: {},
        });
      } else {
        dispatch(upgradePlanFailureAction(response.data));
      }
    } catch (error) {
      dispatch(upgradePlanFailureAction(error.message));
    }
  };
};

export const upgradePlanStartedAction = () => ({
  type: UPGRADE_PLAN_STARTED,
});

export const upgradePlanSuccessAction = (payload) => ({
  type: UPGRADE_PLAN_SUCCESS,
  payload,
});

export const upgradePlanFailureAction = (message) => ({
  type: UPGRADE_PLAN_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const sendUserEmailRequestAction = ({ email, setState, setCounter }) => {
  return async (dispatch) => {
    dispatch(sendUserEmailStartedAction());
    try {
      let response = await sendUserEmailAPI({ email });
      if (response.status === "success") {
        dispatch(sendUserEmailSuccessAction());
        setState({ showTokenInput: true });
        setCounter(120);
      } else {
        dispatch(sendUserEmailFailureAction(response.data));
      }
    } catch (error) {
      dispatch(sendUserEmailFailureAction(error.message));
      setState({ errors: { email: error.data } });
    }
  };
};

export const sendUserEmailStartedAction = () => ({
  type: SEND_USER_EMAIL_STARTED,
});

export const sendUserEmailSuccessAction = (payload) => ({
  type: SEND_USER_EMAIL_SUCCESS,
  payload,
});

export const sendUserEmailFailureAction = (message) => ({
  type: SEND_USER_EMAIL_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const verifyUserEmailRequestAction = ({ email, token, setState }) => {
  return async (dispatch) => {
    dispatch(verifyUserEmailStartedAction());
    try {
      let response = await verifyUserEmailAPI({ email, token });
      if (response.status === "success") {
        dispatch(verifyUserEmailSuccessAction());
        setState({ activeStep: 1 });
      } else {
        dispatch(verifyUserEmailFailureAction(response.data));
      }
    } catch (error) {
      dispatch(verifyUserEmailFailureAction(error.message));
      setState({ errors: { token: error.data } });
    }
  };
};

export const verifyUserEmailStartedAction = () => ({
  type: VERIFY_USER_EMAIL_STARTED,
});

export const verifyUserEmailSuccessAction = (payload) => ({
  type: VERIFY_USER_EMAIL_SUCCESS,
  payload,
});

export const verifyUserEmailFailureAction = (message) => ({
  type: VERIFY_USER_EMAIL_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createUserPasswordRequestAction = ({
  password,
  confirmPassword,
  email,
  navigate,
}) => {
  return async (dispatch) => {
    dispatch(createUserPasswordStartedAction());
    try {
      let response = await createUserPasswordAPI({
        password,
        confirmPassword,
        email,
      });
      if (response.status === "success") {
        dispatch(createUserPasswordSuccessAction());
        dispatch(getUserProfileRequestAction());
        navigate("/dashboard");
      } else {
        dispatch(createUserPasswordFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createUserPasswordFailureAction(error.message));
      // setState({ errors: { email: error.data } });
    }
  };
};

export const createUserPasswordStartedAction = () => ({
  type: CREATE_USER_PASSWORD_STARTED,
});

export const createUserPasswordSuccessAction = (payload) => ({
  type: CREATE_USER_PASSWORD_SUCCESS,
  payload,
});

export const createUserPasswordFailureAction = (message) => ({
  type: CREATE_USER_PASSWORD_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const editUserAddressRequestAction = ({
  state,
  city,
  postalCode,
  address,
  setOpen,
  setPage,
}) => {
  return async (dispatch) => {
    dispatch(editUserAddressStartedAction());
    try {
      let response = await editUserAddressAPI({
        state,
        city,
        postalCode,
        address,
      });
      if (response.status === "success") {
        dispatch(editUserAddressSuccessAction());
        dispatch(getUserProfileRequestAction());
        setOpen && setOpen(false);
        setPage && setPage(null);
        showSnackbar({
          type: "success",
          message: "اطلاعات آدرس با موفقیت تغییر یافت!",
        });
      } else {
        dispatch(editUserAddressFailureAction(response.data));
      }
    } catch (error) {
      dispatch(editUserAddressFailureAction(error.message));
      // setState({ errors: { email: error.data } });
    }
  };
};

export const editUserAddressStartedAction = () => ({
  type: EDIT_USER_ADDRESS_STARTED,
});

export const editUserAddressSuccessAction = (payload) => ({
  type: EDIT_USER_ADDRESS_SUCCESS,
  payload,
});

export const editUserAddressFailureAction = (message) => ({
  type: EDIT_USER_ADDRESS_FAILURE,
  payload: message,
});
