import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const createWebgateAPI = ({
  websiteAddress,
  websiteName,
  websiteIp,
  cmsNameId,
}) => {
  let postData = {
    currency_id: 2,
    ip_address: websiteIp,
    domain: websiteAddress,
    name: websiteName,
    cms_name_id: cmsNameId,
  };
  return axiosMiddleware({
    method: "post",
    url: "gateway/create",
    data: postData,
  });
};
export const getWebgatesAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "gateway/all",
  });
};
export const getPluginsAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "plugin/all",
  });
};
export const changeStatusWebgateAPI = ({ id }) => {
  let postData = {
    gateway_id: id,
    status: "Canceled",
  };
  return axiosMiddleware({
    method: "post",
    url: "gateway/change-status",
    data: postData,
  });
};
