import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  SwipeableDrawer,
  Typography,
  alpha,
} from "@mui/material";
import {
  arcticMistColor,
  darkForestGreenColor,
  forestShadowColor,
  midnightBlueColor,
  primaryColor,
  sageGreenColor,
  softGrayColor,
  whiteColor,
} from "../../../assets/colors";
import Wave from "../wave.svg";
import Background from "../background.png";
import {
  ArrowRightIcon,
  EditIcon,
  EmailIcon,
  LogoText,
  PhoneHangupIcon,
  UnlockIcon,
} from "../../../assets/icons";
import Button from "../../../components/Button";
import CustomTextInput from "../../../components/CustomTextInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotEmailRequestAction,
  forgotOtpRequestAction,
  forgotPasswordRequestAction,
} from "../../../redux/actions/authActions";
import {
  FORGOT_EMAIL_STARTED,
  FORGOT_OTP_STARTED,
  FORGOT_PASSWORD_STARTED,
} from "../../../redux/actionTypes";
import CustomOtpInput from "../../../components/CustomOtpInput/index";
import { formatTime } from "../../../utils/helpers";
import { EMAIL_REGEX } from "../../../utils/regex";
import { fullHeight } from "../../../utils/constants";
import { showSecondSplashRequestAction } from "../../../redux/actions/configActions";
import CustomInputBase from "../../../components/CustomInputBase";
import { LOGIN } from "../../routes";
// --------------------------------------------------------------------------------
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rType, showFirstSplash, showSecondSplash } = useSelector((state) => ({
    rType: state.auth.rType,
    showFirstSplash: state.config.showFirstSplash,
    showSecondSplash: state.config.showSecondSplash,
  }));

  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(0);
  const [poorPassword, setPoorPassword] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [strongPassword, setStrongPassword] = useState(false);
  const [passwordError, setPasswordErr] = useState("");

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", primaryColor);
  }, []);

  useEffect(() => {
    if (otp.length === 5) {
      onForgotOtp();
    }
  }, [otp]);

  const onForgotEmail = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (email === "") errorObj.email = "لطفا آدرس ایمیل را وارد کنید.";
    if (!EMAIL_REGEX.test(email)) errorObj.email = "آدرس ایمیل صحیح نمی باشد.";

    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      forgotEmailRequestAction({ email, setStep, setCounter, setErrors })
    );
  };

  const onForgotOtp = (e) => {
    e?.preventDefault();
    const errorObj = {};

    if (otp === "") errorObj.otp = "لطفا کد ارسال شده را وارد کنید.";

    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0) return;

    dispatch(forgotOtpRequestAction({ email, otp, setStep, setErrors }));
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = newPassword.length >= 8;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(newPassword);
    strengthChecks.hasLowerCase = /[a-z]+/.test(newPassword);
    strengthChecks.hasDigit = /[0-9]+/.test(newPassword);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(newPassword);

    if (confirmNewPassword === "") {
      errorObj.confirmNewPassword = "لطفا تکرار رمز عبور جدید را وارد کنید.";
      error = true;
    }
    if (!strengthChecks.hasDigit) {
      setPasswordErr("یادتان باشد که یک عدد در رمز عبور خود وارد کنید.");
      error = true;
    }
    if (!strengthChecks.hasSpecialChar) {
      setPasswordErr(
        "رمز عبور خود را با افزودن یک کاراکتر خاص (مثل @، #، $) قوی‌تر کنید!"
      );
      error = true;
    }
    if (!strengthChecks.hasLowerCase) {
      setPasswordErr("یک حرف کوچک در رمز عبور خود وارد کنید!");
      error = true;
    }
    if (!strengthChecks.hasUpperCase) {
      setPasswordErr("رمز عبور خود را با اضافه کردن یک حرف بزرگ قوی‌تر کنید!");
      error = true;
    }
    if (!strengthChecks.length) {
      setPasswordErr("لطفا رمز عبور خود را به حداقل ۸ کاراکتر افزایش دهید!");
      error = true;
    }
    if (newPassword === "") {
      setPasswordErr("لطفا رمز عبور جدید را وارد کنید.");
      error = true;
    }

    setErrors(errorObj);

    if (error) return;
    dispatch(
      forgotPasswordRequestAction({
        email,
        newPassword,
        confirmNewPassword,
        otp,
        navigate,
      })
    );
  };

  useEffect(() => {
    let timer = null;
    timer =
      step === 1 &&
      counter > 0 &&
      setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, step]);

  const handleChangePassword = (value) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = value.length >= 8;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(value);
    strengthChecks.hasLowerCase = /[a-z]+/.test(value);
    strengthChecks.hasDigit = /[0-9]+/.test(value);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(value);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    if (verifiedList.length === 0) {
      setPoorPassword(0);
      setStrongPassword(0);
      setWeakPassword(0);
    }
    if (verifiedList.length > 0) {
      setPoorPassword(100);
      setWeakPassword(0);
    }
    if (verifiedList.length > 2) {
      setWeakPassword(100);
      setStrongPassword(0);
    }
    if (verifiedList.length > 4) {
      setStrongPassword(100);
    }

    setPasswordErr("");
    if (!strengthChecks.hasDigit) {
      setPasswordErr("یادتان باشد که یک عدد در رمز عبور خود وارد کنید.");
    }
    if (!strengthChecks.hasSpecialChar) {
      setPasswordErr(
        "رمز عبور خود را با افزودن یک کاراکتر خاص (مثل @، #، $) قوی‌تر کنید!"
      );
    }
    if (!strengthChecks.hasLowerCase) {
      setPasswordErr("یک حرف کوچک در رمز عبور خود وارد کنید!");
    }
    if (!strengthChecks.hasUpperCase) {
      setPasswordErr("رمز عبور خود را با اضافه کردن یک حرف بزرگ قوی‌تر کنید!");
    }
    if (!strengthChecks.length) {
      setPasswordErr("لطفا رمز عبور خود را به حداقل ۸ کاراکتر افزایش دهید!");
    }

    setNewPassword(value);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              فراموشی رمز عبور
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 3 }}
            >
              لطفا آدرس ایمیل خود را وارد کنید
            </Typography>
            <CustomTextInput
              label="آدرس ایمیل"
              value={email}
              placeholder="... آدرس ایمیل خود را وارد کنید"
              sx={{ height: 56 }}
              onChange={(e) => setEmail(e.target.value)}
              startAdornment={<EmailIcon color={sageGreenColor} />}
              helperText={errors.email}
              error={errors.email}
            />
            <Button
              text="ارسال کد"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                mt: { xs: "auto", lg: 20 },
              }}
              onClick={onForgotEmail}
              loading={rType === FORGOT_EMAIL_STARTED}
              type="submit"
            />
            <Button
              variant="text"
              text="بازگشت به مرحله قبل"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                fontSize: 16,
              }}
              onClick={() => navigate(LOGIN)}
              startIcon={<ArrowRightIcon />}
              color="black"
            />
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: blackColor }}>
                حساب کاربری ندارید؟
              </Typography>
              <Button
                variant="text"
                text="ثبت نام کنید"
                fullWidth={false}
                onClick={() => navigate("/register")}
              />
            </Box> */}
          </>
        );
      case 1:
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              فراموشی رمز عبور
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 2.75 }}
            >
              کد تایید ارسال شده به ایمیل خود را وارد کنید
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "center",
                mb: 2.75,
              }}
            >
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 2.5,
                  p: 0.5,
                }}
              >
                <Typography sx={{ color: darkForestGreenColor }}>
                  {email}
                </Typography>
              </Box>
              <IconButton onClick={() => setStep(0)}>
                <EditIcon color={primaryColor} />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1.5 }}>
              <CustomOtpInput
                length={5}
                value={otp}
                onComplete={(value) => setOtp(value)}
                error={errors.otp}
                helperText={errors.otp}
              />
            </Box>
            <Typography
              sx={{
                fontSize: 14,
                color: sageGreenColor,
                textAlign: "center",
                mb: 20,
              }}
            >
              <Link
                component="button"
                underline="none"
                disabled={counter > 0}
                onClick={onForgotEmail}
                sx={{
                  color: forestShadowColor,
                  ":disabled": {
                    color: "inherit",
                    cursor: "default",
                  },
                }}
              >
                ارسال مجدد کد
              </Link>
              {counter > 0 ? `: ${formatTime(counter)}` : null}
            </Typography>
            <Button
              text="تایید"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                mt: { xs: "auto", lg: 0 },
              }}
              onClick={onForgotOtp}
              loading={rType === FORGOT_OTP_STARTED}
              type="submit"
            />
            <Button
              variant="text"
              text="بازگشت به مرحله قبل"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                fontSize: 16,
              }}
              onClick={() => setStep(0)}
              startIcon={<ArrowRightIcon />}
              color="black"
            />
          </>
        );
      case 2:
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              رمز عبور جدید
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 2.75 }}
            >
              رمز عبور جدید خود را وارد کنید.
            </Typography>
            <FormControl fullWidth variant="standard">
              <CustomInputBase
                label="رمز عبور جدید"
                type="password"
                value={newPassword}
                placeholder="... رمز عبور مورد نظر خود را وارد کنید"
                sx={{ height: 56, mb: 0.5 }}
                onChange={(e) =>
                  handleChangePassword(e.target.value.replace(/\s/g, ""))
                }
                fullWidth
                InputLabelProps={{ shrink: "true" }}
                startAdornment={<UnlockIcon color={sageGreenColor} />}
                error={passwordError}
              />
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <LinearProgress
                    variant="determinate"
                    value={strongPassword}
                    color="jungleGreen"
                    sx={{ backgroundColor: softGrayColor }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LinearProgress
                    variant="determinate"
                    value={weakPassword}
                    color="apricotOrange"
                    sx={{ backgroundColor: softGrayColor }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LinearProgress
                    variant="determinate"
                    value={poorPassword}
                    color="firebrickRed"
                    sx={{ backgroundColor: softGrayColor }}
                  />
                </Grid>
              </Grid>
              <FormHelperText error>{passwordError || "\u200C"}</FormHelperText>
            </FormControl>
            <CustomTextInput
              label="تکرار رمز عبور جدید"
              type="password"
              value={confirmNewPassword}
              placeholder="... رمز عبور مورد نظر خود را وارد کنید"
              sx={{ height: 56 }}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              startAdornment={<UnlockIcon color={sageGreenColor} />}
              helperText={errors.confirmNewPassword}
              error={errors.confirmNewPassword}
            />
            <Button
              text="تایید"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                mt: { xs: "auto", lg: 10 },
              }}
              onClick={onForgotPassword}
              loading={rType === FORGOT_PASSWORD_STARTED}
              type="submit"
            />
            <Button
              variant="text"
              text="بازگشت به مرحله قبل"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                fontSize: 16,
              }}
              onClick={() => setStep(1)}
              startIcon={<ArrowRightIcon />}
              color="black"
            />
          </>
        );
    }
  };

  const renderDrawer = (
    <SwipeableDrawer
      variant="persistent"
      anchor="bottom"
      open={!showSecondSplash}
      onOpen={() => dispatch(showSecondSplashRequestAction(false))}
      onClose={() => dispatch(showSecondSplashRequestAction(true))}
      PaperProps={{
        sx: {
          borderTop: 0,
          backgroundColor: primaryColor,
        },
      }}
      sx={{ display: { lg: "none" } }}
    >
      <Box
        sx={{
          minHeight: 200,
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Background})`,
            backgroundPositionY: -15,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            transform: "scale(1.6)",
            zIndex: -1,
          },
        }}
      >
        <LogoText color={whiteColor} height={51} />
      </Box>
      <Box
        sx={{
          px: "25px",
          pt: "29px",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          backgroundColor: whiteColor,
          height: fullHeight,
        }}
        role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
      >
        {renderStep()}
      </Box>
    </SwipeableDrawer>
  );

  return (
    <Box
      sx={{
        backgroundColor: primaryColor,
        minHeight: fullHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        // overflow: "hidden",
        backgroundImage: `url(${Background})`,
        backgroundPositionY: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        component={LogoText}
        color={whiteColor}
        height={51}
        sx={{ mt: "60px", display: { lg: "none" } }}
      />
      <Box
        sx={{
          width: 495,
          // height: 730,
          backgroundColor: whiteColor,
          borderRadius: 5,
          boxShadow: "0px 0px 40px 0px #0000000F",
          zIndex: 1,
          //   p: 2,
          mt: 1,
          mb: 10,
          display: { xs: "none", lg: "block" },
        }}
      >
        <Box sx={{ mx: "auto", width: { lg: 361 } }} component="form">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LogoText
              color={primaryColor}
              width="73.79"
              style={{
                marginTop: "40px",
                marginBottom: "35px",
              }}
            />
          </Box>
          {renderStep()}
        </Box>
      </Box>
      <Wave style={{ position: "absolute", right: 0, left: 0, bottom: 0 }} />
      <Box
        sx={{
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row-reverse",
            alignItems: "center",
            pb: 1,
            color: alpha(midnightBlueColor, 0.7),
            // opacity: 0.7,
          }}
        >
          <Typography sx={{ ml: 1, color: "inherit" }}>021-91001397</Typography>
          <PhoneHangupIcon color="inherit" />
          <Typography sx={{ mx: 1, color: "inherit" }}>
            support@yekpay.com
          </Typography>
          <EmailIcon color="inherit" />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
          <Typography
            sx={{ fontSize: 14, color: sageGreenColor, fontWeight: 300 }}
          >
            کلیه حقوق استفاده از این وب سایت متعلق به یک پی میباشد.
          </Typography>
        </Box>
      </Box>
      {renderDrawer}
    </Box>
  );
};

export default ForgotPassword;
