import Button from "../../components/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  arcticMistColor,
  primaryColor,
  silverMistColor,
  silverSageColor,
  whiteColor,
} from "../../assets/colors";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import MuiPaginationItem from "@mui/material/PaginationItem";
import { styled } from "@mui/material/styles";

const Table2 = ({ columns, data, count, page, onChangePage }) => {
  const [rowPerPage, setRowPerPage] = useState(`20 items`);
  return (
    <TableContainer sx={{ display: { xs: "none", lg: "block" } }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: 600,
                  backgroundColor: arcticMistColor,
                  borderBottom: 0,
                  borderTopLeftRadius: index === 0 ? 8 : 0,
                  borderBottomLeftRadius: index === 0 ? 8 : 0,
                  borderBottomRightRadius: index === columns.length - 1 ? 8 : 0,
                  borderTopRightRadius: index === columns.length - 1 ? 8 : 0,
                  ...column.columnSx,
                }}
              >
                {column.label}
              </TableCell>
            ))}
            {/* <TableCell
              sx={{
                fontWeight: 600,
                backgroundColor: arcticMistColor,
                borderBottom: 0,
              }}
              align="left"
            >
              دستگاه
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  // component="th"
                  // scope="row"
                  sx={{ borderColor: silverSageColor, ...column.sx }}
                >
                  {"renderCell" in column
                    ? column.renderCell(row)
                    : row[column.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {count && (
        <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            variant="outlined"
            count={Math.ceil(count / rowPerPage.replace("items", ""))}
            page={page}
            // siblingCount={0}
            onChange={(event, value) => onChangePage(value)}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                // slots={{
                //   previous: () => (
                //     <ArrowDownIcon style={{ transform: "rotate(90deg)" }} />
                //   ),
                //   next: () => (
                //     <ArrowDownIcon style={{ transform: "rotate(-90deg)" }} />
                //   ),
                // }}
                rowPerPage={rowPerPage.replace("items", "")}
                count={count}
              />
            )}
            sx={{
              "& .MuiPagination-ul": {
                flexDirection: "row-reverse",
              },
            }}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export default Table2;

const PaginationItem = styled(MuiPaginationItem)((props) => {
  return {
    "&.MuiPaginationItem-root": {
      height: 27,
      minWidth: 27,
      borderColor: silverMistColor,
      fontWeight: 600,
      // backgroundColor: silverCloudColor,
      // margin: 0,
      // borderRadius: 0,
      // borderTopLeftRadius: props.page === 1 ? 38 : 0,
      // borderBottomLeftRadius: props.page === 1 ? 38 : 0,
      // borderTopRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      // borderBottomRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      fontSize: 11,
      "&.Mui-selected": {
        backgroundColor: primaryColor,
        color: whiteColor,
        // boxShadow: "0px 0px 4.6px 0px rgba(65, 173, 206, 0.76);",
        // borderRadius: 5,
        // height: 27,
        // width: 27,
      },
    },
    "&.MuiPaginationItem-ellipsis": {
      // height: 25,
    },
    "&.MuiPaginationItem-previousNext": {
      borderRadius: "100%",
      marginLeft: 4,
      marginRight: 4,
      minWidth: "auto",
      width: 25,
    },
  };
});
