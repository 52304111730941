import MuiTableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styled from "@emotion/styled";
import { silverSageColor, slateGrayColor } from "../../assets/colors";

const TableRow = ({ columns, data, rowsCount }) => {
  return (
    <MuiTableRow>
      {columns?.map((column, index) => (
        <TableCell
          key={column.field}
          component="th"
          scope="row"
          sx={{
            fontWeight: 500,
            color: slateGrayColor,
            position: "relative",
            borderBottom: `1px solid #00000000`,
            // borderBottom:0,
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: index === 0 && data.rowIndex !== rowsCount - 1 ? 30 : 0, // Adjust the value as needed
              right:
                index === columns.length - 1 && data.rowIndex !== rowsCount - 1
                  ? 30
                  : 0, // Adjust the value as needed
              borderBottom: `1px solid ${silverSageColor}`,
            },
            ...column.sx,
          }}
          align="center"
        >
          {"renderCell" in column
            ? column.renderCell(data)
            : data[column.field]}
        </TableCell>
      ))}
    </MuiTableRow>
  );
};

export default TableRow;
