import { SHOW_FIRST_SPLASH, SHOW_SECOND_SPLASH } from "../actionTypes";

export const showFirstSplashRequestAction = (data) => {
  return async (dispatch) => {
    dispatch(showFirstSplashAction(data));
  };
};

export const showFirstSplashAction = (payload) => ({
  type: SHOW_FIRST_SPLASH,
  payload,
});

export const showSecondSplashRequestAction = (data) => {
  return async (dispatch) => {
    dispatch(showSecondSplashAction(data));
  };
};

export const showSecondSplashAction = (payload) => ({
  type: SHOW_SECOND_SPLASH,
  payload,
});
