import { Box, Typography } from "@mui/material";
import { primaryColor } from "../../assets/colors";

const Title = ({
  text,
  fontWeight = 700,
  fontSize = 16,
  size = 8,
  containerProps,
  color = primaryColor,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...containerProps?.sx }}>
      <Box
        sx={{
          width: size,
          height: size,
          backgroundColor: color,
          borderRadius: 100,
          mr: 1,
        }}
      ></Box>
      <Typography sx={{ fontWeight, fontSize }}>{text}</Typography>
    </Box>
  );
};

export default Title;
