import {
  checkExchangeAPI,
  checkIrrRemittanceAPI,
  confirmIrrRemittanceAPI,
  createExchangeAPI,
  getExchangeRatesAPI,
  getVirtualAccountsAPI,
} from "../../services/api/virtualAccounts";
import {
  CHECK_EXCHANGE_FAILURE,
  CHECK_EXCHANGE_STARTED,
  CHECK_EXCHANGE_SUCCESS,
  CHECK_IRR_REMITTANCE_FAILURE,
  CHECK_IRR_REMITTANCE_STARTED,
  CHECK_IRR_REMITTANCE_SUCCESS,
  CONFIRM_IRR_REMITTANCE_FAILURE,
  CONFIRM_IRR_REMITTANCE_STARTED,
  CONFIRM_IRR_REMITTANCE_SUCCESS,
  CREATE_EXCHANGE_FAILURE,
  CREATE_EXCHANGE_STARTED,
  CREATE_EXCHANGE_SUCCESS,
  GET_EXCHANGE_RATES_FAILURE,
  GET_EXCHANGE_RATES_STARTED,
  GET_EXCHANGE_RATES_SUCCESS,
  GET_VIRTUAL_ACCOUNTS_FAILURE,
  GET_VIRTUAL_ACCOUNTS_STARTED,
  GET_VIRTUAL_ACCOUNTS_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
export const getVirtualAccountsRequestAction = () => {
  return async (dispatch) => {
    dispatch(getVirtualAccountsStartedAction());
    try {
      let response = await getVirtualAccountsAPI();
      if (response.status === "success") {
        dispatch(
          getVirtualAccountsSuccessAction({ virtualAccounts: response.data })
        );
      } else {
        dispatch(getVirtualAccountsFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getVirtualAccountsFailureAction(error.message));
    }
  };
};

export const getVirtualAccountsStartedAction = () => ({
  type: GET_VIRTUAL_ACCOUNTS_STARTED,
});

export const getVirtualAccountsSuccessAction = (payload) => ({
  type: GET_VIRTUAL_ACCOUNTS_SUCCESS,
  payload,
});

export const getVirtualAccountsFailureAction = (message) => ({
  type: GET_VIRTUAL_ACCOUNTS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getExchangeRatesRequestAction = () => {
  return async (dispatch) => {
    dispatch(getExchangeRatesStartedAction());
    try {
      let response = await getExchangeRatesAPI();
      if (response.status === "success") {
        dispatch(
          getExchangeRatesSuccessAction({ exchangeRates: response.data })
        );
      } else {
        dispatch(getExchangeRatesFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getExchangeRatesFailureAction(error.message));
    }
  };
};

export const getExchangeRatesStartedAction = () => ({
  type: GET_EXCHANGE_RATES_STARTED,
});

export const getExchangeRatesSuccessAction = (payload) => ({
  type: GET_EXCHANGE_RATES_SUCCESS,
  payload,
});

export const getExchangeRatesFailureAction = (message) => ({
  type: GET_EXCHANGE_RATES_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const checkIrrRemittanceRequestAction = ({
  accountId,
  amount,
  source,
  setActiveStep,
  setData,
  setErrors,
}) => {
  return async (dispatch) => {
    dispatch(checkIrrRemittanceStartedAction());
    try {
      let response = await checkIrrRemittanceAPI({
        accountId,
        amount,
        source,
      });
      if (response.status === "success") {
        dispatch(checkIrrRemittanceSuccessAction());
        setData(response.data);
        setActiveStep(1);
      } else {
        dispatch(checkIrrRemittanceFailureAction(response.data));
      }
    } catch (error) {
      setErrors(error.message);
      dispatch(checkIrrRemittanceFailureAction(error.message));
    }
  };
};

export const checkIrrRemittanceStartedAction = () => ({
  type: CHECK_IRR_REMITTANCE_STARTED,
});

export const checkIrrRemittanceSuccessAction = (payload) => ({
  type: CHECK_IRR_REMITTANCE_SUCCESS,
  payload,
});

export const checkIrrRemittanceFailureAction = (message) => ({
  type: CHECK_IRR_REMITTANCE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const confirmIrrRemittanceRequestAction = ({
  accountId,
  amount,
  source,
  setActiveStep,
  setData,
}) => {
  return async (dispatch) => {
    dispatch(confirmIrrRemittanceStartedAction());
    try {
      let response = await confirmIrrRemittanceAPI({
        accountId,
        amount,
        source,
      });
      if (response.status === "success") {
        dispatch(confirmIrrRemittanceSuccessAction());
        setData(response.data);
        setActiveStep(2);
      } else {
        dispatch(confirmIrrRemittanceFailureAction(response.data));
      }
    } catch (error) {
      dispatch(confirmIrrRemittanceFailureAction(error.message));
    }
  };
};

export const confirmIrrRemittanceStartedAction = () => ({
  type: CONFIRM_IRR_REMITTANCE_STARTED,
});

export const confirmIrrRemittanceSuccessAction = (payload) => ({
  type: CONFIRM_IRR_REMITTANCE_SUCCESS,
  payload,
});

export const confirmIrrRemittanceFailureAction = (message) => ({
  type: CONFIRM_IRR_REMITTANCE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const checkExchangeRequestAction = ({
  amount,
  fromCurrencyId,
  toCurrencyId,
  setErrors,
  setActiveStep,
  setData,
}) => {
  return async (dispatch) => {
    dispatch(checkExchangeStartedAction());
    try {
      let response = await checkExchangeAPI({
        amount,
        fromCurrencyId,
        toCurrencyId,
      });
      if (response.status === "success") {
        dispatch(checkExchangeSuccessAction());
        setData(response.data);
        setActiveStep(1);
      } else {
        dispatch(checkExchangeFailureAction());
      }
    } catch (error) {
      dispatch(checkExchangeFailureAction(error.message));
      setErrors({ amount: error.message });
    }
  };
};

export const checkExchangeStartedAction = () => ({
  type: CHECK_EXCHANGE_STARTED,
});

export const checkExchangeSuccessAction = (payload) => ({
  type: CHECK_EXCHANGE_SUCCESS,
  payload,
});

export const checkExchangeFailureAction = (message) => ({
  type: CHECK_EXCHANGE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createExchangeRequestAction = ({
  amount,
  fromCurrencyId,
  toCurrencyId,
  setActiveStep,
  setRecieptData,
}) => {
  return async (dispatch) => {
    dispatch(createExchangeStartedAction());
    try {
      let response = await createExchangeAPI({
        amount,
        fromCurrencyId,
        toCurrencyId,
      });
      if (response.status === "success") {
        dispatch(createExchangeSuccessAction());
        setRecieptData(response.data);
        setActiveStep(2);
      } else {
        dispatch(createExchangeFailureAction());
      }
    } catch (error) {
      dispatch(createExchangeFailureAction(error.message));
    }
  };
};

export const createExchangeStartedAction = () => ({
  type: CREATE_EXCHANGE_STARTED,
});

export const createExchangeSuccessAction = (payload) => ({
  type: CREATE_EXCHANGE_SUCCESS,
  payload,
});

export const createExchangeFailureAction = (message) => ({
  type: CREATE_EXCHANGE_FAILURE,
  payload: message,
});
