import {
  createFeedbackTicketAPI,
  createReplyTicketAPI,
  createTicketAPI,
  getTicketCategoriesAPI,
  getTicketConversationAPI,
  getTicketsListAPI,
} from "../../services/api/ticket";
import {
  CREATE_FEEDBACK_TICKET_FAILURE,
  CREATE_FEEDBACK_TICKET_STARTED,
  CREATE_FEEDBACK_TICKET_SUCCESS,
  CREATE_REPLY_TICKET_FAILURE,
  CREATE_REPLY_TICKET_STARTED,
  CREATE_REPLY_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  CREATE_TICKET_STARTED,
  CREATE_TICKET_SUCCESS,
  GET_TICKETS_LIST_FAILURE,
  GET_TICKETS_LIST_STARTED,
  GET_TICKETS_LIST_SUCCESS,
  GET_TICKET_CATEGORIES_FAILURE,
  GET_TICKET_CATEGORIES_STARTED,
  GET_TICKET_CATEGORIES_SUCCESS,
  GET_TICKET_CONVERSATION_FAILURE,
  GET_TICKET_CONVERSATION_STARTED,
  GET_TICKET_CONVERSATION_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
export const getTicketsListRequestAction = (param) => {
  return async (dispatch) => {
    dispatch(getTicketsListStartedAction());
    try {
      let response = await getTicketsListAPI();
      if (response.status === "success") {
        dispatch(getTicketsListSuccessAction({ ticketsList: response.data }));
        "onSuccess" in param && param.onSuccess(response.data);
      } else {
        dispatch(getTicketsListFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getTicketsListFailureAction(error.message));
    }
  };
};

export const getTicketsListStartedAction = () => ({
  type: GET_TICKETS_LIST_STARTED,
});

export const getTicketsListSuccessAction = (payload) => ({
  type: GET_TICKETS_LIST_SUCCESS,
  payload,
});

export const getTicketsListFailureAction = (message) => ({
  type: GET_TICKETS_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getTicketCategoriesRequestAction = () => {
  return async (dispatch) => {
    dispatch(getTicketCategoriesStartedAction());
    try {
      let response = await getTicketCategoriesAPI();
      if (response.status === "success") {
        dispatch(
          getTicketCategoriesSuccessAction({ ticketCategories: response.data })
        );
      } else {
        dispatch(getTicketCategoriesFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getTicketCategoriesFailureAction(error.message));
    }
  };
};

export const getTicketCategoriesStartedAction = () => ({
  type: GET_TICKET_CATEGORIES_STARTED,
});

export const getTicketCategoriesSuccessAction = (payload) => ({
  type: GET_TICKET_CATEGORIES_SUCCESS,
  payload,
});

export const getTicketCategoriesFailureAction = (message) => ({
  type: GET_TICKET_CATEGORIES_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createTicketRequestAction = ({ data, navigate }) => {
  return async (dispatch) => {
    dispatch(createTicketStartedAction());
    try {
      let response = await createTicketAPI({ data });
      if (response.status === "success") {
        dispatch(createTicketSuccessAction());
        dispatch(
          getTicketsListRequestAction({
            onSuccess: (data) => navigate(`/tickets/ticket/${data[0].id}`),
          })
        );
      } else {
        dispatch(createTicketFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createTicketFailureAction(error.message));
    }
  };
};

export const createTicketStartedAction = () => ({
  type: CREATE_TICKET_STARTED,
});

export const createTicketSuccessAction = (payload) => ({
  type: CREATE_TICKET_SUCCESS,
  payload,
});

export const createTicketFailureAction = (message) => ({
  type: CREATE_TICKET_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getTicketConversationRequestAction = ({
  ticketId,
  setConversations,
  setState,
}) => {
  return async (dispatch) => {
    dispatch(getTicketConversationStartedAction());
    try {
      let response = await getTicketConversationAPI({ ticketId });
      if (response.status === "success") {
        dispatch(getTicketConversationSuccessAction());
        setConversations(response.data);
        setState({
          isTicketClose:
            response.data[response.data.length - 1].status == "Close",
        });
      } else {
        dispatch(getTicketConversationFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getTicketConversationFailureAction(error.message));
    }
  };
};

export const getTicketConversationStartedAction = () => ({
  type: GET_TICKET_CONVERSATION_STARTED,
});

export const getTicketConversationSuccessAction = (payload) => ({
  type: GET_TICKET_CONVERSATION_SUCCESS,
  payload,
});

export const getTicketConversationFailureAction = (message) => ({
  type: GET_TICKET_CONVERSATION_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createReplyTicketRequestAction = ({
  data,
  setConversations,
  ticketId,
  setState,
}) => {
  return async (dispatch) => {
    dispatch(createReplyTicketStartedAction());
    try {
      let response = await createReplyTicketAPI({ data });
      if (response.status === "success") {
        dispatch(createReplyTicketSuccessAction());
        dispatch(
          getTicketConversationRequestAction({ setConversations, ticketId })
        );
        setState({ message: "", attachment: null });
      } else {
        dispatch(createReplyTicketFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createReplyTicketFailureAction(error.message));
    }
  };
};

export const createReplyTicketStartedAction = () => ({
  type: CREATE_REPLY_TICKET_STARTED,
});

export const createReplyTicketSuccessAction = (payload) => ({
  type: CREATE_REPLY_TICKET_SUCCESS,
  payload,
});

export const createReplyTicketFailureAction = (message) => ({
  type: CREATE_REPLY_TICKET_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createFeedbackTicketRequestAction = ({
  feedback,
  rate,
  ticketId,
  setConversations,
  setState,
}) => {
  return async (dispatch) => {
    dispatch(createFeedbackTicketStartedAction());
    try {
      let response = await createFeedbackTicketAPI({
        feedback,
        rate,
        ticketId,
      });
      if (response.status === "success") {
        dispatch(createFeedbackTicketSuccessAction());
        dispatch(
          getTicketConversationRequestAction({ setConversations, ticketId })
        );
        setState({ message: "", attachment: null });
      } else {
        dispatch(createFeedbackTicketFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createFeedbackTicketFailureAction(error.message));
    }
  };
};

export const createFeedbackTicketStartedAction = () => ({
  type: CREATE_FEEDBACK_TICKET_STARTED,
});

export const createFeedbackTicketSuccessAction = (payload) => ({
  type: CREATE_FEEDBACK_TICKET_SUCCESS,
  payload,
});

export const createFeedbackTicketFailureAction = (message) => ({
  type: CREATE_FEEDBACK_TICKET_FAILURE,
  payload: message,
});
