import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  arcticMistColor,
  forestShadowColor,
  silverSageColor,
  slateGrayColor,
  sunsetCoralColor,
  transparent,
  whiteColor,
} from "../../assets/colors";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { TicketIcon } from "../../assets/icons";
import CreateTicket from "./CreateTicket";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTicketsListRequestAction } from "../../redux/actions/ticketActions";
import { appBarHeight, fullHeight } from "../../utils/constants";
import TicketConversation from "./TicketConversation";

const Tickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tab, ticketId } = useParams();

  const { ticketsList } = useSelector((state) => state.ticket);

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    setShowSidebar(true);
    ticketsList.length === 0 && dispatch(getTicketsListRequestAction());
  }, []);

  const renderTab = () => {
    switch (tab) {
      case "create-ticket":
        return <CreateTicket />;
      case "ticket":
        return <TicketConversation />;
      default:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <TicketIcon width={127} style={{ opacity: 0.1 }} />
            <Typography sx={{ opacity: 0.2, fontWeight: 500 }}>
              یک تیکت را انتخاب یا ایجاد کنید
            </Typography>
          </Box>
        );
    }
  };

  const renderTicketStatus = (status) => {
    switch (status) {
      case "Admin Reply":
        return "پاسخ داده شد.";
      case "Close":
        return "بسته شده";
      default:
        return "منتظر پاسخ";
    }
  };

  let h = tab === "create-ticket" ? 2 : 75;
  return (
    <Box
      sx={{
        display: { lg: "flex" },
        flex: 1,
        backgroundColor: arcticMistColor,
      }}
    >
      <Box
        sx={{
          display: { xs: ticketId ? "none" : "block", lg: "block" },
          width: { xs: "100vw", lg: 380 },
        }}
      >
        <Box
          sx={{
            backgroundColor: whiteColor,
            height: {
              lg: `calc(${fullHeight} - ${appBarHeight + h}px)`,
            },
            left: { lg: showSidebar ? 0 : -380 },
            //   bottom: -100,
            // position: "relative",

            transition: "all 1s",

            // flexDirection: "column",
            borderRight: { lg: `1px solid ${silverSageColor}` },
            overflow: { lg: "auto" },
          }}
        >
          {/* <Box
          sx={{
            position: "sticky",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: whiteColor,
            px: 2,
            zIndex: 1,
          }}
        >
          <CustomTextInput
            placeholder="جستجو کنید"
            endAdornment={<SearchIcon />}
          />
        </Box> */}
          <List sx={{ flex: 1, px: 2 }} dense disablePadding>
            {ticketsList.map((ticket, index) => (
              <ListItemButton
                disableGutters
                onClick={() => navigate(`/tickets/ticket/${ticket.id}`)}
                selected={ticket.id === Number(ticketId)}
                key={index}
                sx={{
                  borderRadius: 2,
                  "&.Mui-selected": {
                    backgroundColor: arcticMistColor,
                    ":hover": {
                      backgroundColor: arcticMistColor,
                    },
                  },
                  ":hover": {
                    backgroundColor: transparent,
                  },
                }}
                disableRipple
              >
                <ListItem disablePadding>
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            width: 4,
                            height: 4,
                            backgroundColor: sunsetCoralColor,
                            mx: 0.75,
                            borderRadius: 100,
                          }}
                        ></Box>
                        {ticket.subject}
                      </Box>
                    }
                    primaryTypographyProps={{
                      color: forestShadowColor,
                      fontSize: 14,
                      fontWeight: 500,
                      mb: 1,
                    }}
                    secondary={
                      <>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 300,
                            color: slateGrayColor,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            "> p": {
                              m: 0,
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: ticket.text }}
                        ></Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", my: 1 }}
                        >
                          <Chip
                            label={renderTicketStatus(ticket.status)}
                            sx={{ borderRadius: 1.5, height: 26 }}
                            color={
                              ticket.id === Number(ticketId)
                                ? "paleBlue"
                                : "arcticMist"
                            }
                            size="small"
                          />
                          <Chip
                            label={`دپارتمان: ${ticket.department_name}`}
                            sx={{
                              borderRadius: 1.5,
                              height: 26,
                              ml: 1,
                            }}
                            color={
                              ticket.id === Number(ticketId)
                                ? "paleBlue"
                                : "arcticMist"
                            }
                            size="small"
                          />
                          <Chip
                            label={`#${ticket.id}`}
                            sx={{
                              borderRadius: 1.5,
                              height: 26,
                              mr: "auto",
                              ml: 1,
                            }}
                            color={
                              ticket.id === Number(ticketId)
                                ? "paleBlue"
                                : "arcticMist"
                            }
                            size="small"
                          />
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: slateGrayColor,
                              opacity: 0.5,
                            }}
                          >
                            {/* {dayjs(ticket.updated_at).format("HH:mm")} */}
                          </Typography>
                        </Box>
                      </>
                    }
                    secondaryTypographyProps={{
                      sx: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: slateGrayColor,
                        fontSize: 12,
                        px: 2,
                      },
                    }}
                  />
                </ListItem>
              </ListItemButton>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: whiteColor,
            p: 2,
          }}
        >
          <Button
            text="+ تیکت جدید"
            sx={{ mt: "auto" }}
            onClick={() => navigate("/tickets/create-ticket")}
          />
        </Box>
      </Box>
      <Box
        sx={{
          //   p: 3,
          flex: 1,
          display: { xs: ticketId ? "flex" : "none", lg: "flex" },
        }}
      >
        {renderTab()}
      </Box>
    </Box>
  );
};

export default Tickets;
