import React, { createContext, useState } from "react";

const initialState = {
  country: "",
  state: "",
  city: "",
  postalCode: "",
  address: "",

  name: "",
  registrationNumber: "",
  email: "",
  token: "",
  showTokenInput: false,
  activeStep: 0,
  logo: null,
  lastChanges: null,
  officialNewspaper: null,
  companyOwners: [
    {
      firstname: "",
      lastname: "",
      nationalId: "",
      mobile: "",
      signatureImage: null,
    },
  ],
  counter: 0,
  open: false,
  errors: {},
};

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [state, setInitState] = useState(initialState);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  return (
    <AccountContext.Provider value={{ state, setState }}>
      {children}
    </AccountContext.Provider>
  );
};
