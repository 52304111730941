export const statusObj = {
  Pending: {
    label: "در انتظار تایید",
    color: "goldenCream",
  },
  Approved: {
    label: "تایید شده",
    color: "emeraldOasis",
  },
  Declined: {
    label: "رد شده",
    color: "rosyGlow",
  },
  // Canceled: {
  //   label: "تعلیق شده",
  //   color: "lavenderGray",
  // },
  Canceled: {
    label: "غیر فعال",
    color: "arcticMist",
  },
};
