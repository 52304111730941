import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MuiMobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import {
  arcticMistColor,
  charcoalGrayColor,
  whiteColor,
} from "../../assets/colors";

const MobileStepper = ({ activeStep, steps }) => {
  const theme = useTheme();
  //   const [activeStep, setActiveStep] = React.useState(0);

  //   const handleNext = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   };

  //   const handleBack = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
  //   };

  return (
    <MuiMobileStepper
      variant="progress"
      steps={steps.length}
      position="static"
      activeStep={activeStep}
      //   nextButton={
      //     <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
      //       Next
      //       {theme.direction === "rtl" ? (
      //         <KeyboardArrowLeft />
      //       ) : (
      //         <KeyboardArrowRight />
      //       )}
      //     </Button>
      //   }
      //   backButton={
      //     <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
      //       {theme.direction === "rtl" ? (
      //         <KeyboardArrowRight />
      //       ) : (
      //         <KeyboardArrowLeft />
      //       )}
      //       Back
      //     </Button>
      //   }
      sx={{
        backgroundColor: whiteColor,
        width: "100%",
        p: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
      }}
      LinearProgressProps={{
        sx: { width: "100%", backgroundColor: arcticMistColor, height: 3 },
      }}
    />
  );
};

export default MobileStepper;
