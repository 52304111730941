import styled from "@emotion/styled";
import {
  FormControl,
  InputBase,
  InputLabel,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import {
  inputColor,
  graphiteGrayColor,
  errorColor,
  primaryColor,
  silverSageColor,
} from "../../assets/colors";
import { useState } from "react";
import {
  NON_ALPHABETIC_REGEX,
  NON_DIGIT_REGEX,
  NON_ENGLISH_ALPHANUMERIC,
  NON_PERSIAN_REGEX,
} from "../../utils/regex";
import { useTranslation } from "react-i18next";
import { getDirection } from "../../utils/helpers";
import {
  dmSans,
  iranSans,
  iranSansFaNum,
  notoSans,
} from "../../utils/constants";
// --------------------------------------------------------------------------------
const CustomTextInput = ({
  name,
  label,
  type,
  value,
  onChange = () => {}, // Default to a no-op function,
  color,
  startAdornment,
  endAdornment,
  multiline,
  row,
  sx,
  error,
  helperText,
  pattern,
  maxLength,
  inputMode,
  fullWidth = true,
  alphabetic,
  number,
  persian,
  englishAlphanumeric,
  removeSpecialChar,
  showMaxLengthMessage = true,
  hideHelperText,
  typographyVariant,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [helperError, setHelperError] = useState("");
  const [isError, setIsError] = useState(error);

  const defaultHelperText = helperError || helperText || "\u200C";
  const defaulError = isError || error;

  const handleChange = (e) => {
    let value = e.target.value;

    if (englishAlphanumeric && NON_ENGLISH_ALPHANUMERIC.test(value)) {
      setHelperError("لطفا از حروف انگلیسی برای ورودی‌های خود استفاده کنید.");
      setIsError(true);
    } else if (persian && NON_PERSIAN_REGEX.test(value)) {
      setHelperError("لطفا از حروف فارسی برای ورودی‌های خود استفاده کنید.");
      setIsError(true);
    }
    // else if (value.length > maxLength && showMaxLengthMessage) {
    //   setHelperError(
    //     "متن ورودی از حد مجاز طولانی‌تر است. لطفا آن را کوتاه‌تر کنید."
    //   );
    //   setIsError(true);
    // }
    else {
      setHelperError(false);
      setIsError(false);
    }

    let trimmedValue = value.slice(0, maxLength);

    let event = {
      ...e,
      target: {
        ...e.target,
        name: e.target.name,
        value: alphabetic
          ? trimmedValue.replace(NON_ALPHABETIC_REGEX, "")
          : englishAlphanumeric
          ? trimmedValue.replace(NON_ENGLISH_ALPHANUMERIC, "")
          : persian
          ? trimmedValue.replace(NON_PERSIAN_REGEX, "")
          : number
          ? trimmedValue.replace(NON_DIGIT_REGEX, "")
          : removeSpecialChar
          ? trimmedValue.replace(/[^\w\s]|_/gi, "")
          : trimmedValue,
      },
    };

    onChange(event);
  };

  const direction = getDirection(value); // Determine text direction

  return (
    <FormControl variant="standard" fullWidth disabled={otherProps.disabled}>
      <InputLabel
        shrink
        sx={{
          fontSize: 14,
          color: graphiteGrayColor,
          "&.Mui-focused": {
            color: "unset",
          },
          "&.Mui-disabled": {
            opacity: 0.3,
          },
          transform: "none",
          fontWeight: 600,
          width: "100%",
        }}
      >
        {label}
      </InputLabel>
      <BootstrapInput
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          )
        }
        endAdornment={endAdornment}
        multiline={multiline}
        rows={row}
        sx={sx}
        error={defaulError}
        inputProps={{ pattern, inputMode }}
        direction={direction} // Apply text direction
        typographyVariant={typographyVariant}
        {...otherProps}
      />
      {!hideHelperText && (
        <FormHelperText error={defaulError}>{defaultHelperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomTextInput;

export const BootstrapInput = styled(InputBase)(
  ({ theme, startAdornment, endAdornment, direction, typographyVariant }) => ({
    "label + &": {
      marginTop: theme.spacing(3.8),
    },
    "&.MuiInputBase-root": {
      border: `1.5px solid ${silverSageColor}`,
      borderRadius: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      padding: 0,
      paddingLeft: startAdornment && 8,
      paddingRight: endAdornment && 4,
      "&.Mui-disabled": {
        opacity: 0.3,
      },
      "&.Mui-focused": {
        borderColor: primaryColor,
        boxShadow: "0px 0px 0px 2px rgba(65, 173, 206, 0.11)",
      },
      "&.Mui-error": {
        borderColor: errorColor,
        boxShadow: "none",
      },
    },
    "& .MuiInputBase-input": {
      direction,
      borderRadius: 8,
      position: "relative",
      fontSize: 14,
      padding: `10px ${endAdornment ? "0px" : "12px"} 10px ${
        startAdornment ? "0px" : "12px"
      }`,
      "&::placeholder": {
        fontSize: 12,
        textAlign: "left",
      },
      fontFamily:
        typographyVariant === 2
          ? [dmSans, iranSans].join(",")
          : typographyVariant === 3
          ? [notoSans, iranSans].join(",")
          : [iranSansFaNum, dmSans].join(","),
    },
    "& + p": {
      transition: theme.transitions.create(["opacity"]),
      opacity: 0,
      "&.Mui-error": {
        opacity: 1,
      },
    },
  })
);
