import {
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_STARTED,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_BUSINESS_CATEGORIES_FAILURE,
  GET_BUSINESS_CATEGORIES_STARTED,
  GET_BUSINESS_CATEGORIES_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_STARTED,
  GET_DASHBOARD_DATA_SUCCESS,
  SET_BUSINESS_CATEGORY_FAILURE,
  SET_BUSINESS_CATEGORY_STARTED,
  SET_BUSINESS_CATEGORY_SUCCESS,
  UPDATE_USER_STEP_FAILURE,
  UPDATE_USER_STEP_STARTED,
  UPDATE_USER_STEP_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: "",
  data: {
    paylinks: [],
    profile_percent: 0,
  },
  businessCategories: [],
  announcements: {
    announcements: [],
  },
  error: "",
};
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_STARTED:
    case UPDATE_USER_STEP_STARTED:
    case GET_ANNOUNCEMENTS_STARTED:
    case GET_BUSINESS_CATEGORIES_STARTED:
    case SET_BUSINESS_CATEGORY_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        rType: GET_DASHBOARD_DATA_SUCCESS,
        data: action.payload.data,
        error: "",
      };
    case UPDATE_USER_STEP_SUCCESS:
      return {
        ...state,
        rType: UPDATE_USER_STEP_SUCCESS,
        error: "",
      };
    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        rType: GET_ANNOUNCEMENTS_SUCCESS,
        announcements: action.payload.announcements,
        error: "",
      };
    case GET_BUSINESS_CATEGORIES_SUCCESS:
      return {
        ...state,
        rType: GET_BUSINESS_CATEGORIES_SUCCESS,
        businessCategories: action.payload.businessCategories,
        error: "",
      };
    case SET_BUSINESS_CATEGORY_SUCCESS:
      return {
        ...state,
        rType: SET_BUSINESS_CATEGORY_SUCCESS,
        error: "",
      };
    case GET_DASHBOARD_DATA_FAILURE:
    case UPDATE_USER_STEP_FAILURE:
    case GET_ANNOUNCEMENTS_FAILURE:
    case GET_BUSINESS_CATEGORIES_FAILURE:
    case SET_BUSINESS_CATEGORY_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
