import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  crimsonBlazeColor,
  errorColor,
  graphiteGrayColor,
  icyBreezeColor,
  mistyGreenColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
} from "../../../assets/colors";
import CustomTextInput from "../../../components/CustomTextInput";
import Button from "../../../components/Button";
import { useContext, useRef } from "react";
import styled from "@emotion/styled";
import Title from "../../../components/Title";
import MaterialUISwitch from "../../../components/Switch";
import {
  BoxIcon,
  ChevronDownIcon,
  CloseIcon,
  Edit2Icon,
  EuroIcon2,
  InfoIcon,
  LinkIcon1,
} from "../../../assets/icons";
import CurrencyInput from "../../../components/CurrencyInput";
import { PaylinkContext } from "../../../context/PyalinkContext";
import { dmSans, iranSans } from "../../../utils/constants";

const PaylinkConfig = ({ selectedTab }) => {
  const fileInputRef = useRef(null);

  const {
    state: {
      showContactInformation,
      linkImage,
      linkTitle,
      linkDescription,
      desiredAmount,
      paymentLimits,
      defaultAmount,
      successfulMessage,
      unsuccessfulMessage,
      confirmationPage,
      customConfirmationPage,
      focusAmount,
      determination,
      day,
      count,
      errors,
    },
    setState,
  } = useContext(PaylinkContext);

  const handleSelectImage = (e) => {
    let error = false;
    const errorObj = { ...error };

    let file = e.target.files[0];
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    if (file?.size > 2000000) {
      errorObj.size = true;
    }
    if (!".png, .jpg, .jpeg".includes(fileExtension)) {
      errorObj.type = true;
    }

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    setState({ linkImage: file });
  };

  const renderTab = () => {
    switch (selectedTab) {
      case "PaymentPage":
        return (
          <>
            <Box sx={{ mb: 2 }}>
              <CustomTextInput
                label="عنوان لینک"
                placeholder="مثلا : ایران وب هاست"
                endAdornment={
                  <InputAdornment position="end">
                    <BoxIcon />
                  </InputAdornment>
                }
                onChange={(e) =>
                  setState({
                    linkTitle: e.target.value,
                    errors: { ...errors, linkTitle: "" },
                  })
                }
                error={errors.linkTitle}
                helperText={errors.linkTitle}
                value={linkTitle}
                typographyVariant={2}
              />
            </Box>
            <Box>
              <CustomTextInput
                label="توضیحات (اختیاری)"
                placeholder="... درباره لینک خود بنویسید"
                endAdornment={
                  <InputAdornment position="end">
                    <Edit2Icon />
                  </InputAdornment>
                }
                onChange={(e) => setState({ linkDescription: e.target.value })}
                value={linkDescription}
                typographyVariant={2}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  display: "inline-block",
                  mb: 1,
                }}
              >
                تصویر لینک
              </Typography>{" "}
              <Typography sx={{ display: "inline-block", fontSize: 12 }}>
                (اختیاری)
              </Typography>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleSelectImage}
                // accept=".png, .jpg, .jpeg"
              />
              {linkImage ? (
                <Box
                  sx={{
                    backgroundColor: icyBreezeColor,
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 2,
                    direction: "rtl",
                  }}
                >
                  <IconButton
                    onClick={() => setState({ linkImage: null })}
                    sx={{ ml: "auto" }}
                  >
                    <CloseIcon width={18} height={18} />
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: 12,
                      // mr: "auto",
                      ml: 1,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: 225,
                    }}
                  >
                    {linkImage.name}
                  </Typography>
                  <Avatar src={URL.createObjectURL(linkImage)} />
                </Box>
              ) : (
                <>
                  <Button
                    text="+ افزودن"
                    color="arcticMist"
                    sx={{ color: primaryColor, fontSize: 12, py: 1.5, mb: 2 }}
                    onClick={() => fileInputRef.current.click()}
                  />
                  <Box sx={{ flex: 1, mr: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <InfoIcon
                          style={{ marginTop: 4 }}
                          color={
                            errors.size ? crimsonBlazeColor : mistyGreenColor
                          }
                          width={20}
                          height={20}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 11,
                          color: errors.size
                            ? crimsonBlazeColor
                            : graphiteGrayColor,
                          lineHeight: 2,
                          ml: 1,
                        }}
                      >
                        لطفا عکس را با حجم کمتر از ۲ مگابایت انتخاب کنید.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <InfoIcon
                          style={{ marginTop: 4 }}
                          color={
                            errors.type ? crimsonBlazeColor : mistyGreenColor
                          }
                          width={20}
                          height={20}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 11,
                          color: errors.type
                            ? crimsonBlazeColor
                            : graphiteGrayColor,
                          lineHeight: 2,
                          ml: 1,
                        }}
                      >
                        لطفا فقط عکس‌هایی با فرمت PNG، JPG یا JPEG بارگذاری
                        کنید.
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Title text="تنظیمات پیشرفته" />
            <Box
              sx={{
                pt: 4,
                pb: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                تنظیم مبلغ دلخواه
              </Typography>
              <MaterialUISwitch
                checked={desiredAmount}
                onChange={() =>
                  setState({
                    desiredAmount: !desiredAmount,
                    paymentLimits: false,
                    errors: {},
                  })
                }
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: desiredAmount ? 0 : -70,
                visibility: desiredAmount ? "visible" : "hidden",
                opacity: desiredAmount ? 1 : 0,
                height: desiredAmount ? 65.5 : 0,
                transition: "0.6s all",
              }}
            >
              <CurrencyInput
                startAdornment={
                  <Box
                    sx={{
                      height: 40.13,
                      borderRight: `1px solid ${silverSageColor}`,
                      pr: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                      مبلغ دلخواه
                    </Typography>
                  </Box>
                }
                endAdornment={
                  <EuroIcon2
                    color={focusAmount ? primaryColor : slateGrayColor}
                    width={16}
                    height={16}
                  />
                }
                sx={{ mt: "0!important" }}
                value={defaultAmount}
                // onChange={(e) => setState({ defaultAmount: e.target.value })}
                onValueChange={(values, sourceInfo) => {
                  console.log(values, sourceInfo);

                  setState({
                    defaultAmount: values.formattedValue,
                    errors: {},
                  });
                }}
                onFocus={(e) => setState({ focusAmount: true })}
                onBlur={(e) => setState({ focusAmount: false })}
                decimalScale={2}
                thousandSeparator
                helperText={errors.defaultAmount}
                error={errors.defaultAmount}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                محدودیت حداکثر و حداقل پرداخت
              </Typography>
              <MaterialUISwitch
                checked={paymentLimits}
                onChange={() =>
                  setState({
                    paymentLimits: !paymentLimits,
                    desiredAmount: false,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: paymentLimits ? 0 : -70,
                visibility: paymentLimits ? "visible" : "hidden",
                opacity: paymentLimits ? 1 : 0,
                height: paymentLimits ? 68.13 : 0,
                transition: "0.6s all",
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                        Min
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  placeholder="مثلا: 5 یورو"
                  onChange={(e) =>
                    setState({
                      minAmount: e.target.value,
                      errors: { ...errors, amount: "" },
                    })
                  }
                  error={errors.amount}
                  hideHelperText
                  endAdornment={<EuroIcon2 width={16} height={16} />}
                />
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                        Max
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  placeholder="مثلا: 2,500 یورو"
                  onChange={(e) =>
                    setState({
                      maxAmount: e.target.value,
                      errors: { ...errors, amount: "" },
                    })
                  }
                  error={errors.amount}
                  hideHelperText
                  endAdornment={<EuroIcon2 width={16} height={16} />}
                />
              </Box>
              <Typography sx={{ color: errorColor, fontSize: 12, mt: 1 }}>
                {errors.amount}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                تعیین میزان پرداخت
              </Typography>
              <MaterialUISwitch
                checked={determination}
                onChange={(e) => setState({ determination: e.target.checked })}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: determination ? 0 : -70,
                visibility: determination ? "visible" : "hidden",
                opacity: determination ? 1 : 0,
                height: determination ? 68.13 : 0,
                transition: "0.6s all",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                        بر اساس روز
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  onChange={(e) => {
                    let value = e.target.value;
                    setState({
                      day: value.startsWith("0")
                        ? value.replace(/^0+/, "")
                        : value,
                    });
                  }}
                  value={day}
                  endAdornment={
                    <InputAdornment
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                      }}
                      position="end"
                    >
                      <IconButton
                        size="small"
                        sx={{ p: 0.25, transform: "rotate(180deg)" }}
                        onClick={(e) => setState({ day: Number(day) + 1 })}
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                      <IconButton
                        size="small"
                        sx={{ p: 0.25 }}
                        onClick={(e) =>
                          setState({ day: day <= 1 ? 1 : Number(day) - 1 })
                        }
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                    </InputAdornment>
                  }
                  number
                  error={errors.determination}
                  hideHelperText
                />
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                        تعداد دفعات
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  value={count}
                  onChange={(e) => {
                    let value = e.target.value;
                    setState({
                      count: value.startsWith("0")
                        ? value.replace(/^0+/, "")
                        : value,
                    });
                  }}
                  endAdornment={
                    <InputAdornment
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                      }}
                      position="end"
                    >
                      <IconButton
                        size="small"
                        sx={{ p: 0.25, transform: "rotate(180deg)" }}
                        onClick={(e) => setState({ count: Number(count) + 1 })}
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                      <IconButton
                        size="small"
                        sx={{ p: 0.25 }}
                        onClick={(e) =>
                          setState({
                            count: count === 1 ? 1 : Number(count) - 1,
                          })
                        }
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                    </InputAdornment>
                  }
                  number
                  error={errors.determination}
                  hideHelperText
                />
              </Box>
              <Typography sx={{ color: errorColor, fontSize: 12, mt: 1 }}>
                {errors.determination}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                نمایش اطلاعات تماس
              </Typography>
              <MaterialUISwitch
                onChange={(e) =>
                  setState({ showContactInformation: e.target.checked })
                }
                checked={showContactInformation}
                //   onChange={() => setLimitPay((prevState) => !prevState)}
              />
            </Box>
          </>
        );
      case "ReceiptPage":
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ fontSize: 14 }}>
                نمایش صفحه تاییدیه پرداخت
              </Typography>
              <MaterialUISwitch
                checked={confirmationPage}
                onChange={(e) =>
                  setState({
                    confirmationPage: e.target.checked,
                    customConfirmationPage: false,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: confirmationPage ? 0 : -70,
                visibility: confirmationPage ? "visible" : "hidden",
                opacity: confirmationPage ? 1 : 0,
                height: confirmationPage ? 95.42 : 0,
                transition: "0.6s all",
              }}
            >
              <CustomTextInput
                label="پیام موفقیت دلخواه ( اختیاری )"
                placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({ successfulMessage: e.target.value })
                }
                value={successfulMessage}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: confirmationPage ? 0 : -70,
                visibility: confirmationPage ? "visible" : "hidden",
                opacity: confirmationPage ? 1 : 0,
                height: confirmationPage ? 95.42 : 0,
                transition: "0.6s all",
              }}
            >
              <CustomTextInput
                label="پیام تراکنش ناموفق دلخواه ( اختیاری )"
                placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({ unsuccessfulMessage: e.target.value })
                }
                value={unsuccessfulMessage}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
                mb: 1,
              }}
            >
              <Typography sx={{ fontSize: 14 }}>
                نمایش صفحه دلخواه بعد از پرداخت
              </Typography>
              <MaterialUISwitch
                checked={customConfirmationPage}
                onChange={(e) =>
                  setState({
                    customConfirmationPage: e.target.checked,
                    confirmationPage: false,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: customConfirmationPage ? 0 : -70,
                visibility: customConfirmationPage ? "visible" : "hidden",
                opacity: customConfirmationPage ? 1 : 0,
                height: customConfirmationPage ? 95.42 : 0,
                transition: "0.6s all",
                mb: 1,
              }}
            >
              <CustomTextInput
                label="لینک تراکنش موفق"
                // placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({
                    successfulLink: e.target.value,
                    errors: { ...errors, successfulLink: "" },
                  })
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LinkIcon1 color={slateGrayColor} />
                  </InputAdornment>
                }
                error={errors.successfulLink}
                helperText={errors.successfulLink}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: customConfirmationPage ? 0 : -70,
                visibility: customConfirmationPage ? "visible" : "hidden",
                opacity: customConfirmationPage ? 1 : 0,
                height: customConfirmationPage ? 95.42 : 0,
                transition: "0.6s all",
              }}
            >
              <CustomTextInput
                label="لینک تراکنش ناموفق"
                // placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({
                    unsuccessfulLink: e.target.value,
                    errors: { ...errors, unsuccessfulLink: "" },
                  })
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LinkIcon1 color={slateGrayColor} />
                  </InputAdornment>
                }
                error={errors.unsuccessfulLink}
                helperText={errors.unsuccessfulLink}
              />
            </Box>
          </>
        );
    }
  };

  return renderTab();
};

export default PaylinkConfig;

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  //   "& .MuiTabs-indicator": {
  //     backgroundColor: "#1890ff",
  //   },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "10px 8px",
    fontSize: 12,
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    // "&:hover": {
    //   color: "#40a9ff",
    //   opacity: 1,
    // },
    "&.Mui-selected": {
      //   color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
