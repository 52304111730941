import React, { useState, useRef, useEffect } from "react";
import { Box, FormHelperText, InputBase, InputLabel } from "@mui/material";
import styled from "@emotion/styled";
import { errorColor, primaryColor, silverSageColor } from "../../assets/colors";

const OtpInput = ({
  length,
  label,
  helperText = "\u200C",
  error,
  value,
  onChange,
  containerProps,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (value) {
      setOtp(value.split(""));
    }
  }, [value]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[A-Za-z0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value.toUpperCase();
      setOtp(newOtp);
      onChange(newOtp.join(""));

      // Focus the next input if the current one is filled
      if (value && index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    const value = e.target.value;
    if (e.key === "Backspace" && !value) {
      // Focus the previous input if the current one is empty
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (/^[A-Za-z0-9]*$/.test(paste)) {
      const newOtp = [...otp];
      for (let i = 0; i < length; i++) {
        if (i < paste.length) {
          newOtp[i] = paste[i];
        }
      }
      setOtp(newOtp);
      onChange(newOtp.join(""));
      // Focus the last input field after pasting
      inputRefs.current[length - 1].focus();
    }
  };

  return (
    <Box onPaste={handlePaste} {...containerProps}>
      <InputLabel
        shrink
        sx={{
          color: "#33383D",
          "&.Mui-focused, &.Mui-error": { color: "unset" },
          transform: "none",
          fontWeight: 600,
          fontSize: { xs: 14, lg: 16 },
          mb: 1.25,
        }}
      >
        {label}
      </InputLabel>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
        }}
      >
        {otp.map((data, index) => (
          <BootstrapInput
            key={index}
            value={data}
            inputProps={{ maxLength: "1" }}
            inputRef={(el) => {
              inputRefs.current[index] = el;
            }}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onFocus={(e) => e.target.select()}
            error={error}
          />
        ))}
      </Box>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
};

export default OtpInput;

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "&.MuiInputBase-root": {
    border: `1.5px solid ${silverSageColor}`,
    borderRadius: 12,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    padding: 0,
    width: 56,
    height: 56,
    "&.Mui-disabled": {
      opacity: 0.3,
    },
    "&.Mui-focused": {
      borderColor: primaryColor,
      boxShadow: "0px 0px 0px 2px rgba(65, 173, 206, 0.11)",
    },
    "&.Mui-error": {
      borderColor: errorColor,
      boxShadow: "none",
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    fontSize: 25,
    textAlign: "center",
    fontWeight: 500,
  },
  "& + p": {
    transition: theme.transitions.create(["opacity"]),
    opacity: 0,
    "&.Mui-error": {
      opacity: 1,
    },
  },
}));
