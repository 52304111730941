import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/Authentication/Login";
import Register from "./pages/Authentication/Registration";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import Loading from "./components/Loading";
import Lottie from "react-lottie";
import logoMotion from "./assets/lotties/logoMotion.json";
import logoLoading from "./assets/lotties/logoLoading.json";
import { Box } from "@mui/material";
import { whiteColor } from "./assets/colors";
import {
  showFirstSplashRequestAction,
  showSecondSplashRequestAction,
} from "./redux/actions/configActions";
import { fullHeight } from "./utils/constants";

const Index = lazy(() => import("./pages"));

const options = {
  loop: false,
  autoplay: true,
  animationData: logoMotion,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  renderer: "svg",
};

const options2 = {
  loop: true,
  autoplay: true,
  animationData: logoLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  renderer: "svg",
};

function App() {
  const dispatch = useDispatch();

  const { user, token, showSecondSplash, showFirstSplash } = useSelector(
    (state) => ({
      user: state.auth.user,
      token: state.auth.token,
      showFirstSplash: state.config.showFirstSplash,
      showSecondSplash: state.config.showSecondSplash,
    })
  );
  const loading = showFirstSplash || showSecondSplash;

  useEffect(() => {
    const firstTimer = setTimeout(() => {
      dispatch(showFirstSplashRequestAction(false));
      dispatch(showSecondSplashRequestAction(true));
    }, 1500);

    // Show the second splash screen for another 2 seconds
    const secondTimer = setTimeout(() => {
      dispatch(showSecondSplashRequestAction(false));
    }, 3500);

    return () => {
      clearTimeout(firstTimer);
      clearTimeout(secondTimer);
    };
  }, [showFirstSplash]);

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );

  const lottieLoading = (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 10000,
        backgroundColor: whiteColor,
        visibility: loading ? "visible" : "hidden",
        opacity: loading ? 1 : 0,
        transition: "all .5s ease-out",
      }}
    >
      {showFirstSplash && <Lottie options={options} />}
      {showSecondSplash && <Lottie options={options2} />}
    </Box>
  );

  return (
    <Box sx={{ position: "relative" }}>
      {token ? (
        <>
          <Suspense
            fallback={
              <Box
                sx={{ height: fullHeight, backgroundColor: whiteColor }}
              ></Box>
            }
          >
            <Box
              sx={{
                height: loading ? fullHeight : "auto",
                overflow: loading ? "hidden" : "visible",
              }}
            >
              <Index />
            </Box>
          </Suspense>
          {lottieLoading}
        </>
      ) : (
        <Box>
          {routeblog}
          {lottieLoading}
        </Box>
      )}
    </Box>
  );
}

export default App;
