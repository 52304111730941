import { Box, Typography } from "@mui/material";
import {
  arcticMistColor,
  primaryColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import { createElement, useContext, useState } from "react";
import {
  CheckCircleIcon,
  Simplification,
  UserIcon,
} from "../../../assets/icons";
import MobileStepper from "../../../components/MobileStepper";
import { AccountContext } from "../../../context/AccountContext";
import StepSecond from "./StepSecond";
import StepFirst from "./StepFirst";

const AddCompanyMobile = () => {
  const {
    state: { activeStep },
    setState,
  } = useContext(AccountContext);
  const steps = [
    { label: "ورود اطلاعات اولیه", value: 0, icon: Simplification },
    { label: "بارگذاری مدارک شرکت", value: 1, icon: UserIcon },
  ];

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return <StepFirst />;
      case 1:
        return <StepSecond />;
    }
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Box
        sx={{
          position: "relative",
          backgroundColor: arcticMistColor,
          px: 3,
          py: 2,
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: 48,
            height: 48,
            backgroundColor: primaryColor,
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: 1,
          }}
        >
          {createElement(steps[activeStep].icon, {
            color: whiteColor,
            width: 24,
            height: 24,
          })}
        </Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 1 }}>
            {steps[activeStep].label}
          </Typography>
          <Typography sx={{ fontSize: 10, color: slateGrayColor }}>
            مرحله {activeStep + 1} از 2
          </Typography>
        </Box>
        <MobileStepper activeStep={activeStep} steps={steps} />
      </Box>
      <Box sx={{ px: 2, py: 3 }}>{renderSteps()}</Box>
    </Box>
  );
};

export default AddCompanyMobile;
