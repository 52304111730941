import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import {
  NON_ALPHABETIC_REGEX,
  NON_DIGIT_REGEX,
  NON_ENGLISH_REGEX,
} from "../../utils/regex";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
// --------------------------------------------------------------------------------
const TextInput = ({
  name,
  label,
  type,
  value,
  onChange = () => {}, // Default to a no-op function,
  color,
  endAdornment,
  multiline,
  row,
  sx,
  error,
  helperText,
  pattern,
  maxLength,
  fullWidth = true,
  alphabetic,
  number,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [helperError, setHelperError] = useState("");
  const defaultHelperText = helperError || helperText || "\u200C";

  const handleChange = (e) => {
    let value = e.target.value;
    let latinValue = value.replace(NON_ENGLISH_REGEX, "");

    if (NON_ENGLISH_REGEX.test(value)) {
      setHelperError(t("pleaseUseEnglish"));
    } else if (value.length > maxLength) {
      setHelperError(t("characterLimit"));
    } else {
      setHelperError("");
    }

    let trimmedValue = latinValue.slice(0, maxLength);

    let event = {
      ...e,
      target: {
        ...e.target,
        name: e.target.name,
        value: alphabetic
          ? trimmedValue.replace(NON_ALPHABETIC_REGEX, "")
          : number
          ? trimmedValue.replace(NON_DIGIT_REGEX, "")
          : trimmedValue,
      },
    };

    onChange(event);
  };

  return (
    <CustomTextField
      name={name}
      type={type}
      label={label}
      value={value}
      onChange={handleChange}
      color={color}
      endAdornment={
        endAdornment && (
          <InputAdornment sx={{ p: 1 }}>{endAdornment}</InputAdornment>
        )
      }
      multiline={multiline}
      rows={row}
      sx={sx}
      inputProps={{ pattern }}
      error={helperError || error}
      helperText={defaultHelperText}
      autoComplete="off"
      fullWidth={fullWidth}
      InputLabelProps={{ shrink: "true" }}
      {...otherProps}
    />
  );
};

export default TextInput;

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .Mui-error.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.error.main,
  },
}));
