import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getProductsListAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `get-products?limit=${limit}&offset=${offset}`,
  });
};
export const createProductAPI = ({ formData }) => {
  let postData = formData;
  return axiosMiddleware({
    method: "post",
    url: "craete-products",
    data: postData,
  });
};
export const deleteProductAPI = ({ productId }) => {
  let postData = {
    id: productId,
  };
  return axiosMiddleware({
    method: "delete",
    url: "delete-products",
    data: postData,
  });
};
