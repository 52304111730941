import { Box } from "@mui/material";
import { CheckFillIcon } from "../../assets/icons";
import {
  primaryColor,
  sereneSkyBlueColor,
  whiteColor,
} from "../../assets/colors";

const CustomRadio = ({ checked, ...otherProps }) => {
  return checked ? (
    <Box
      sx={{
        bgcolor: primaryColor,
        borderRadius: "100%",
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        ...otherProps.sx,
      }}
    >
      <CheckFillIcon width={12} color={whiteColor} />
    </Box>
  ) : (
    <Box
      sx={{
        borderRadius: "100%",
        width: 20,
        height: 20,
        border: `1px solid ${sereneSkyBlueColor}`,
        ...otherProps.sx,
      }}
    ></Box>
  );
};

export default CustomRadio;
