import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import CustomInputBase from "../CustomInputBase";

const CurrencyInput = ({
  name,
  label,
  value,
  helperText,
  onChange,
  error,
  allowNegative = false,
  ...otherProps
}) => {
  const defaultHelperText = helperText || "\u200C";
  const materialUITextFieldProps = {
    InputLabelProps: { shrink: "true" },
  };

  return (
    <NumericFormat
      label={label}
      type="tel"
      value={value}
      onChange={onChange}
      customInput={CustomInputBase}
      autoComplete="off"
      error={error}
      helperText={defaultHelperText}
      allowNegative={allowNegative}
      {...materialUITextFieldProps}
      {...otherProps}
    />
  );
};

export default CurrencyInput;
