import {
  amberBlazeColor,
  crimsonBlazeColor,
  periwinkleBlue,
  primaryColor,
  slateGrayColor,
} from "../../assets/colors";

export const statusObj = {
  Pending: {
    label: "در انتظار تایید",
    color: amberBlazeColor,
  },
  Approved: {
    label: "فعال",
    color: primaryColor,
  },
  Declined: {
    label: "رد شده",
    color: crimsonBlazeColor,
  },
  Canceled: {
    label: "غیر فعال",
    color: slateGrayColor,
  },
  Suspended: {
    label: "تعلیق شده",
    color: periwinkleBlue,
  },
};

export const persianOrdinal = [
  "اول",
  "دوم",
  "سوم",
  "چهارم",
  "پنجم",
  "ششم",
  "هفتم",
  "هشتم",
  "نهم",
  "دهم",
  "یازدهم",
  "دوازدهم",
  "سیزدهم",
  "چهاردهم",
  "پانزدهم",
  "شانزدهم",
  "هفدهم",
  "هجدهم",
  "نوزدهم",
  "بیستم",
];
