import { Box, alpha } from "@mui/material";
import { Avatar, Grid } from "@mui/material";
import {
  arcticMistColor,
  coralRedColor,
  emeraldGreenColor,
  goldenrodColor,
  lightAlabasterColor,
  mintCreamColor,
  primaryColor,
  silverMistColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { PaylinkContext } from "../../../context/PyalinkContext";
import { apiBaseUrl } from "../../../utils/constants";
import Typography from "../../../components/Typography";

const ProductPreview = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    state: { showContactInformation, products },
  } = useContext(PaylinkContext);

  let allProductAmount = products
    .map((p) => Number(p.amount))
    .reduce((sum, current) => sum + current, 0);

  return (
    <Box
      sx={{
        width: 718,
        backgroundColor: whiteColor,
        borderRadius: 2.5,
        overflow: "hidden",
        // mt: 10,
        // mb: 3,
        my: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          p: 2,
          boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            background: coralRedColor,
            borderRadius: 100,
            ml: 0.5,
          }}
        ></Box>
        <Box
          sx={{
            width: 10,
            height: 10,
            background: goldenrodColor,
            borderRadius: 100,
            ml: 0.5,
          }}
        ></Box>
        <Box
          sx={{
            width: 10,
            height: 10,
            background: emeraldGreenColor,
            borderRadius: 100,
          }}
        ></Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 278,
            backgroundColor: arcticMistColor,
            p: 2,
            textAlign: "right",
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
            Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              borderBottom: `1px solid ${silverSageColor}`,
              pt: 3,
              pb: 1,
              mb: 1.5,
            }}
          >
            <Avatar
              sx={{ width: 51, height: 51, ml: 1 }}
              src={`${apiBaseUrl}/${user.profile_picture}`}
            />
            <Box>
              <Typography sx={{ fontSize: 12, fontWeight: 700, mb: 0.5 }}>
                {user.first_name} {user.last_name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 10,
                  mb: 0.25,
                  display: showContactInformation ? "block" : "none",
                }}
                variant={2}
              >
                {user.email}
              </Typography>
              <Typography
                sx={{
                  fontSize: 8,
                  display: showContactInformation ? "block" : "none",
                  direction: "rtl",
                }}
                variant={2}
              >
                {user.mobile}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: whiteColor,
              height: 28,
              border: `0.4px solid ${lightAlabasterColor}`,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1.75,
            }}
          >
            <Box
              sx={{
                width: 39,
                height: 24,
                backgroundColor: primaryColor,
                borderRadius: "2px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ml: 0.25,
              }}
            >
              <Typography sx={{ fontSize: 8, color: whiteColor }}>
                Apply
              </Typography>
            </Box>
            <Typography
              sx={{ fontSize: 8, color: alpha(slateGrayColor, 0.4), mr: 1 }}
            >
              Discount code
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 11 }} variant={2}>
              {products[0]?.symbol}
              {allProductAmount}
            </Typography>
            <Typography sx={{ fontSize: 11, color: slateGrayColor }}>
              :Payment amount{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            p: 2,
            textAlign: "right",
          }}
        >
          <Box
            sx={{
              width: 294,
              border: `1px solid ${mintCreamColor}`,
              p: 1,
              borderRadius: 2.5,
              mx: "auto",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 700, mb: 1.5 }}>
              Products
            </Typography>
            {products.map((p) => (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
                <Typography sx={{ fontSize: 10, fontWeight: 600 }} variant={2}>
                  {p.amount} {p.symbol}
                </Typography>
                <Box sx={{ ml: "auto", mr: 1 }}>
                  <Typography
                    sx={{
                      fontSize: 10,
                      fontWeight: 600,
                    }}
                    variant={2}
                  >
                    {p.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 8,
                      color: slateGrayColor,
                    }}
                    variant={2}
                  >
                    {p.detail}
                  </Typography>
                </Box>
                <Avatar
                  src={`${apiBaseUrl}/${p.image}`}
                  variant="rounded"
                  sx={{ width: 40, h4ight: 40 }}
                />
              </Box>
            ))}
            <Box
              sx={{
                borderTop: `1px solid ${silverMistColor}`,
                my: 2,
                pt: 0.15,
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 700,
                  display: "inline-block",
                }}
                variant={2}
              >
                {products[0]?.symbol}
                {allProductAmount}
              </Typography>{" "}
              <Typography sx={{ display: "inline-block", fontSize: 10 }}>
                :Total
              </Typography>{" "}
            </Box>
            <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
              Customer info
            </Typography>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                {/* <Typography sx={{ fontSize: 8 }}>
                  This is a place for showing a hint here!
                </Typography> */}
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Last Name
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your last name
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  First Name
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your first name
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Mobile
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your mobile
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Email
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>Enter your email</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Country
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your country
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Address
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your address
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                backgroundColor: primaryColor,
                borderRadius: 1,
                p: 0.8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Typography sx={{ color: whiteColor, fontSize: 10 }}>
                Pay
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductPreview;
