import {
  changeStatusWebgateAPI,
  createWebgateAPI,
  getPluginsAPI,
  getWebgatesAPI,
} from "../../services/api/webgate";
import {
  CHANGE_STATUS_WEBGATE_FAILURE,
  CHANGE_STATUS_WEBGATE_STARTED,
  CHANGE_STATUS_WEBGATE_SUCCESS,
  CREATE_WEBGATE_FAILURE,
  CREATE_WEBGATE_STARTED,
  CREATE_WEBGATE_SUCCESS,
  GET_PLUGINS_FAILURE,
  GET_PLUGINS_STARTED,
  GET_PLUGINS_SUCCESS,
  GET_WEBGATES_FAILURE,
  GET_WEBGATES_STARTED,
  GET_WEBGATES_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
export const createWebgateRequestAction = ({
  websiteAddress,
  websiteName,
  websiteIp,
  cmsNameId,
  setActiveStep,
}) => {
  return async (dispatch) => {
    dispatch(createWebgateStartedAction());
    try {
      let response = await createWebgateAPI({
        websiteAddress,
        websiteName,
        websiteIp,
        cmsNameId,
      });
      if (response.status === "success") {
        dispatch(createWebgateSuccessAction(response.data));
        setActiveStep(3);
      } else {
        dispatch(createWebgateFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createWebgateFailureAction(error.message));
    }
  };
};

export const createWebgateStartedAction = () => ({
  type: CREATE_WEBGATE_STARTED,
});

export const createWebgateSuccessAction = (payload) => ({
  type: CREATE_WEBGATE_SUCCESS,
  payload,
});

export const createWebgateFailureAction = (message) => ({
  type: CREATE_WEBGATE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getWebgatesRequestAction = () => {
  return async (dispatch) => {
    dispatch(getWebgatesStartedAction());
    try {
      let response = await getWebgatesAPI();
      if (response.status === "success") {
        dispatch(getWebgatesSuccessAction({ webgates: response.data }));
      } else {
        dispatch(getWebgatesFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getWebgatesFailureAction(error.message));
    }
  };
};

export const getWebgatesStartedAction = () => ({
  type: GET_WEBGATES_STARTED,
});

export const getWebgatesSuccessAction = (payload) => ({
  type: GET_WEBGATES_SUCCESS,
  payload,
});

export const getWebgatesFailureAction = (message) => ({
  type: GET_WEBGATES_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getPluginsRequestAction = () => {
  return async (dispatch) => {
    dispatch(getPluginsStartedAction());
    try {
      let response = await getPluginsAPI();
      if (response.status === "success") {
        dispatch(getPluginsSuccessAction({ plugins: response.data }));
      } else {
        dispatch(getPluginsFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getPluginsFailureAction(error.message));
    }
  };
};

export const getPluginsStartedAction = () => ({
  type: GET_PLUGINS_STARTED,
});

export const getPluginsSuccessAction = (payload) => ({
  type: GET_PLUGINS_SUCCESS,
  payload,
});

export const getPluginsFailureAction = (message) => ({
  type: GET_PLUGINS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const changeStatusWebgateRequestAction = ({ id }) => {
  return async (dispatch) => {
    dispatch(changeStatusWebgateStartedAction());
    try {
      let response = await changeStatusWebgateAPI({ id });
      if (response.status === "success") {
        dispatch(changeStatusWebgateSuccessAction());
        dispatch(getWebgatesRequestAction());
      } else {
        dispatch(changeStatusWebgateFailureAction(response.data));
      }
    } catch (error) {
      dispatch(changeStatusWebgateFailureAction(error.message));
    }
  };
};

export const changeStatusWebgateStartedAction = () => ({
  type: CHANGE_STATUS_WEBGATE_STARTED,
});

export const changeStatusWebgateSuccessAction = (payload) => ({
  type: CHANGE_STATUS_WEBGATE_SUCCESS,
  payload,
});

export const changeStatusWebgateFailureAction = (message) => ({
  type: CHANGE_STATUS_WEBGATE_FAILURE,
  payload: message,
});
