import { Box, Chip, Divider, Typography } from "@mui/material";
import Button from "../../../components/Button";
import UploadFile from "../../../components/UploadFile";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";
import CustomTextInput from "../../../components/CustomTextInput";
import { arcticMistColor, primaryColor } from "../../../assets/colors";
import { UserIcon } from "../../../assets/icons";
import { useDispatch } from "react-redux";
import { createUserCompanyRequestAction } from "../../../redux/actions/userActions";

const StepSecond = () => {
  const dispatch = useDispatch();
  const { state, setState } = useContext(AccountContext);

  const handleChangeOwners = (e, index) => {
    let owners = [...state.companyOwners];
    owners[index][e.target.name] = e.target.value;
    setState({ companyOwners: owners });
  };

  const handleAddCompany = () => {
    let formData = new FormData();

    formData.append("name", state.name);
    formData.append("national_id", state.registrationNumber);
    formData.append("email", state.email);
    state.logo instanceof File && formData.append("logo", state.logo); // assuming state.logo is a File or Blob object
    state.lastChanges instanceof File &&
      formData.append("last_changes", state.lastChanges);
    state.officialNewspaper instanceof File &&
      formData.append("official_newspaper", state.officialNewspaper);

    // Append each owner and their attachment separately
    state.companyOwners.forEach((item, index) => {
      formData.append(`company_owners[${index}][first_name]`, item.firstname);
      formData.append(`company_owners[${index}][last_name]`, item.lastname);
      formData.append(`company_owners[${index}][mobile]`, item.mobile); // fixed typo from module to mobile
      formData.append(`company_owners[${index}][national_id]`, item.nationalId);
      item.signatureImage instanceof File &&
        formData.append(
          `company_owners[${index}][attachment]`,
          item.signatureImage
        ); // assuming item.signatureImage is a File or Blob object
    });

    dispatch(createUserCompanyRequestAction({ formData, setState }));
  };

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 2 }}>
        <Box sx={{ flex: "45%" }}>
          <UploadFile
            label="لوگو شرکت"
            onChange={(value) => setState({ logo: value })}
            value={state.logo}
          />
        </Box>
        <Box sx={{ flex: "45%" }}>
          <UploadFile
            label="آگهی آخرین تغییرات شرکت"
            onChange={(value) => setState({ lastChanges: value })}
            value={state.lastChanges}
          />
        </Box>
        <Box sx={{ flex: "45%" }}>
          <UploadFile
            label="تصویر روزنامه رسمی"
            onChange={(value) => setState({ officialNewspaper: value })}
            value={state.officialNewspaper}
          />
        </Box>
        <Box sx={{ flex: "45%" }}></Box>
      </Box>
      <Divider sx={{ flex: 1, borderStyle: "dashed", mb: 2 }} />
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <Box
          sx={{
            backgroundColor: arcticMistColor,
            height: 30,
            width: 30,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UserIcon width={18} height={18} color={primaryColor} />{" "}
        </Box>
        <Typography sx={{ fontWeight: 700, fontSize: 14, ml: 1 }}>
          اطلاعات صاحب امضا
        </Typography>
      </Box>
      {state.companyOwners?.map((item, index) => (
        <Box sx={{ display: "flex", columnGap: 2, flexWrap: "wrap" }}>
          <Box sx={{ flex: "45%" }}>
            <CustomTextInput
              label="نام"
              name="firstname"
              onChange={(e) => handleChangeOwners(e, index)}
              value={item.firstname}
            />
          </Box>
          <Box sx={{ flex: "45%" }}>
            <CustomTextInput
              label="نام خانوادگی"
              name="lastname"
              onChange={(e) => handleChangeOwners(e, index)}
              value={item.lastname}
            />
          </Box>
          <Box sx={{ flex: "45%" }}>
            <CustomTextInput
              label="کد ملی"
              name="nationalId"
              onChange={(e) => handleChangeOwners(e, index)}
              value={item.nationalId}
            />
          </Box>
          <Box sx={{ flex: "45%" }}>
            <CustomTextInput
              label="شماره تماس"
              name="mobile"
              onChange={(e) => handleChangeOwners(e, index)}
              value={item.mobile}
            />
          </Box>
          <UploadFile
            label="تصویر گواهی امضا"
            onChange={(value) =>
              handleChangeOwners(
                { target: { value, name: "signatureImage" } },
                index
              )
            }
            value={item.signatureImage}
          />
          <Divider sx={{ flex: 1, borderStyle: "dashed", my: 2 }} />
        </Box>
      ))}
      {state.companyOwners.length === 3 ? null : (
        <Chip
          label="+ افزودن صاحب امضا"
          color="emeraldOasis"
          onClick={() => {
            let owners = [...state.companyOwners];
            owners.push({
              fisrtname: "",
              lastname: "",
              nationalId: "",
              mobile: "",
              signatureImage: null,
            });
            setState({ companyOwners: owners });
          }}
          sx={{ mb: 2 }}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          text="مرحله قبل"
          fullWidth={false}
          sx={{ borderRadius: 100, width: 107, mr: 2 }}
          onClick={() => setState({ activeStep: 0 })}
          color="slateGray"
          variant="outlined"
        />
        <Button
          text="مرحله بعد"
          fullWidth={false}
          sx={{ borderRadius: 100, width: 181 }}
          onClick={handleAddCompany}
        />
      </Box>
    </>
  );
};

export default StepSecond;
