import {
  SHOW_FIRST_SPLASH,
  SHOW_SECOND_SPLASH,
  SIGNIN_DEFAULT,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: SIGNIN_DEFAULT,
  showFirstSplash: true,
  showSecondSplash: false,
  error: "",
};
const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FIRST_SPLASH:
      return {
        ...state,
        showFirstSplash: action.payload,
        error: "",
      };
    case SHOW_SECOND_SPLASH:
      return {
        ...state,
        showSecondSplash: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export default configReducer;
