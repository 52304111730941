import { Box, Typography } from "@mui/material";
import {
  arcticMistColor,
  primaryColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import {
  CheckCircleIcon,
  CheckIcon,
  CpuIcon,
  MoreHorizontalIcon,
  UserIcon,
} from "../../../assets/icons";
import MuiStepper from "../../../components/Stepper";
import { createElement, useState } from "react";
import MobileStepper from "../../../components/MobileStepper";
import Step1 from "./Step1";
import Step0 from "./Step0";
import Step2 from "./Step2";
import Step3 from "./Step3";

const CreateWebgate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [part, setPart] = useState(1);
  const steps = [
    { label: "مطالعه الزامات وب سایت", value: 0, icon: CheckCircleIcon },
    { label: "ورود اطلاعات وب سایت", value: 1, icon: UserIcon },
    {
      label: "انتخاب روش پیاده سازی وب سایت",
      value: 2,
      icon: MoreHorizontalIcon,
    },
    { label: "ثبت درخواست وب گیت", value: 3, icon: CheckIcon },
  ];

  const initialState = {
    websiteName: "",
    websiteAddress: "",
    websiteIp: ["", "", "", ""],
    cmsId: "",
    errors: {},
  };
  const [state, setInitState] = useState(initialState);
  const [values, setValues] = useState(["", "", "", ""]);
  const [selectedPlugin, setSelectedPlugin] = useState(null);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <Step0 setActiveStep={setActiveStep} activeStep={activeStep} />;
      case 1:
        return (
          <Step1
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            state={state}
            setState={setState}
            values={values}
            setValues={setValues}
          />
        );
      case 2:
        return (
          <Step2
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            part={part}
            setPart={setPart}
            state={state}
            values={values}
            selectedPlugin={selectedPlugin}
            setSelectedPlugin={setSelectedPlugin}
          />
        );
      case 3:
        return <Step3 selectedPlugin={selectedPlugin} />;
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: arcticMistColor,
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: { lg: "center" },
        alignItems: { lg: "center" },
      }}
    >
      {activeStep >= 0 ? (
        <Box
          sx={{
            position: "relative",
            backgroundColor: arcticMistColor,
            px: 3,
            py: 2,
            display: { xs: "flex", lg: "none" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 48,
              height: 48,
              backgroundColor: primaryColor,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 1,
            }}
          >
            {createElement(steps[activeStep].icon, {
              color: whiteColor,
              width: 24,
              height: 24,
            })}
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 1 }}>
              ارزیابی وب سایت
            </Typography>
            <Typography sx={{ fontSize: 10, color: slateGrayColor }}>
              مرحله {activeStep + 1} از 4
            </Typography>
          </Box>
          <MobileStepper activeStep={activeStep + 1} steps={steps} />
        </Box>
      ) : null}
      <Box
        sx={{
          // width: 772,
          minHeight: { lg: 638 },
          backgroundColor: whiteColor,
          p: 3,
          borderRadius: { lg: "15px" },
          display: "flex",
          flex: { xs: 1, lg: 0 },
        }}
      >
        <Box sx={{ display: "flex", flex: 1 }}>
          {renderStep()}
          <Box sx={{ width: 317, pl: 3, display: { xs: "none", lg: "block" } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 800, mb: 3 }}>
              مراحل ساخت وب گیت
            </Typography>
            <MuiStepper activeStep={activeStep} steps={steps} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateWebgate;
