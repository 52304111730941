import { Avatar, Box, Divider, Typography } from "@mui/material";
import {
  aquaMist,
  arcticMistColor,
  crimsonBlazeColor,
  forestShadowColor,
  peachBlush,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import {
  CardSendIcon,
  CheckIcon,
  CoinStackIcon,
  ErrIcon,
  TimeLineIcon,
} from "../../../assets/icons";
import Button from "../../../components/Button";
import CurrencyInput from "../../../components/CurrencyInput";
import { useEffect, useState } from "react";
import {
  addBalanceSeparator,
  numberToPersianWords,
} from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBankCardsRequestAction } from "../../../redux/actions/userActions";
import {
  checkIrrRemittanceRequestAction,
  confirmIrrRemittanceRequestAction,
} from "../../../redux/actions/virtualAccountsActions";
import { CONFIRM_IRR_REMITTANCE_STARTED } from "../../../redux/actionTypes";
import moment from "moment-jalaali";

const Payout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    virtualAccounts: { virtualAccounts, rType },
    auth: { user },
    bankCards,
  } = useSelector((state) => ({
    virtualAccounts: state.virtualAccounts,
    auth: state.auth,
    bankCards: state.user.bankCards,
  }));

  const [amount, setAmount] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState("");

  useEffect(() => {
    dispatch(getBankCardsRequestAction());
  }, []);

  switch (activeStep) {
    case 0:
      return (
        <Box
          sx={{
            flex: 1,
            display: { lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            px: "15px",
            py: "24px",
          }}
        >
          <Box
            sx={{
              width: { lg: 691 },
              borderRadius: { xs: "24px", lg: "20px" },
              px: { xs: "12px ", lg: "26px" },
              py: "23px",
              backgroundColor: whiteColor,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: arcticMistColor,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <CardSendIcon />
              </Box>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                برداشت از حساب
              </Typography>
            </Box>
            <Box sx={{ minHeight: 448.73 }}>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: "12px",
                  mb: 2,
                  px: { xs: "12px", lg: "19px" },
                  py: { xs: "12px", lg: "15px" },
                }}
              >
                <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                  توجه:
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.8 }}>
                  برداشت از حساب در روز های کاری بانک های ایران از شنبه تا
                  چهارشنبه و از ساعت ۱۰ الی ۱۵ میسر میباشد. لازم به ذکر است
                  مجموع مبلغ درخواستی و کارمزد نباید از موجودی حساب ریالی شما
                  بیشتر باشد.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { lg: "flex" },
                  // alignItems: "center",
                  // mb: { xs: "9px", lg: 0 },
                  mb: "24px",
                }}
              >
                <CurrencyInput
                  label="مبلغ"
                  startAdornment={
                    <Typography sx={{ color: primaryColor }}>ریالءء</Typography>
                  }
                  value={amount}
                  onValueChange={(values, sourceInfo) => {
                    console.log(values, sourceInfo);

                    setAmount(values.value);
                  }}
                  decimalScale={0}
                  thousandSeparator
                  helperText={`${numberToPersianWords(
                    Math.floor(amount / 10)
                  )} تومان`}
                  isAllowed={(values) => values.floatValue <= 1000000000}
                />
                <Box sx={{ minWidth: 225, ml: { lg: "14px" } }}>
                  <Box
                    sx={{
                      height: { xs: 55, lg: 45 },
                      backgroundColor: arcticMistColor,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: 1,
                      borderRadius: "12px",
                      mt: 3.5,
                      mb: "9px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src="https://flagcdn.com/ir.svg"
                        sx={{ width: 30, height: 30 }}
                      />
                      <Typography
                        sx={{ fontSize: 12, color: slateGrayColor, ml: 1 }}
                      >
                        موجودی
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                      {virtualAccounts[1]?.balance}{" "}
                      <Typography
                        sx={{
                          color: primaryColor,
                          display: "inline-block",
                          fontWeight: 400,
                          fontSize: 12,
                        }}
                      >
                        ریالءء
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      minWidth: 152,
                      height: 42,
                      border: `1px solid ${silverSageColor}`,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ml: "auto",
                    }}
                  >
                    <Typography sx={{ fontSize: 12, color: slateGrayColor }}>
                      کارمزد:
                    </Typography>
                    <Typography sx={{ mx: 0.5 }}>
                      {addBalanceSeparator(
                        (amount * user.payout_commission) / 100
                      )}
                    </Typography>
                    <Typography sx={{ fontSize: 12, color: primaryColor }}>
                      ریالءء
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: 14,
                  color: crimsonBlazeColor,
                  lineHeight: 1.8,
                  opacity: errors ? 1 : 0,
                  transition: "all 0.25s",
                  mb: 4,
                }}
              >
                <ErrIcon style={{ verticalAlign: "middle", marginLeft: 5 }} />
                {errors}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                text="بازگشت"
                fullWidth={false}
                color="arcticMist"
                sx={{ width: { xs: 121, lg: 141 } }}
                onClick={() => navigate("/virtual-accounts")}
              />
              <Button
                text="مرحله بعد"
                fullWidth={false}
                sx={{ width: 193 }}
                onClick={() =>
                  dispatch(
                    checkIrrRemittanceRequestAction({
                      accountId: bankCards[0].id,
                      amount,
                      source: "Website",
                      setActiveStep,
                      setData,
                      setErrors,
                    })
                  )
                }
                disabled={
                  amount.length === 0 ||
                  amount > Number(virtualAccounts[1]?.balance)
                }
                // loading={}
              />
            </Box>
          </Box>
        </Box>
      );
    case 1:
      return (
        <Box
          sx={{
            flex: 1,
            display: { lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            px: "15px",
            py: "24px",
          }}
        >
          <Box
            sx={{
              width: { lg: 691 },
              borderRadius: { xs: "24px", lg: "20px" },
              px: { xs: "12px ", lg: "26px" },
              py: "23px",
              backgroundColor: whiteColor,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: arcticMistColor,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <CardSendIcon />
              </Box>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                برداشت از حساب
              </Typography>
            </Box>
            <Box sx={{ height: 448.73 }}>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: "12px",
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    height: 66,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: "19px",
                    // py: "15px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src="https://flagcdn.com/ir.svg"
                      sx={{ width: 30, height: 30 }}
                    />
                    <Typography
                      sx={{
                        fontSize: 12,
                        ml: 1,
                        fontWeight: 500,
                      }}
                    >
                      ملبغ درخواستی:
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                    {addBalanceSeparator(amount)}{" "}
                    <Typography
                      sx={{
                        color: primaryColor,
                        display: "inline-block",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      ریالءء
                    </Typography>
                  </Typography>
                </Box>
                <Divider sx={{ borderColor: silverSageColor }} />
                <Box
                  sx={{
                    height: 67,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: "19px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                      ml: 1,
                    }}
                  >
                    واریز به:
                  </Typography>
                  <Box>
                    <Typography sx={{ fontSize: 12, fontWeight: 500, mb: 1 }}>
                      {bankCards[0]?.bank_response_first_name}{" "}
                      {bankCards[0]?.bank_response_last_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {bankCards[0]?.iban}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography sx={{ fontSize: 12, color: slateGrayColor }}>
                واریز مبلغ{" "}
                {`${numberToPersianWords(Math.floor(amount / 10))} تومان`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "auto",
              }}
            >
              <Button
                text="بازگشت"
                fullWidth={false}
                color="arcticMist"
                sx={{ width: { xs: 121, lg: 141 } }}
                onClick={() => setActiveStep(0)}
              />
              <Button
                text="تایید و برداشت"
                fullWidth={false}
                sx={{ width: 193 }}
                onClick={() =>
                  dispatch(
                    confirmIrrRemittanceRequestAction({
                      accountId: bankCards[0].id,
                      amount,
                      source: "Website",
                      setActiveStep,
                      setData,
                    })
                  )
                }
                loading={rType === CONFIRM_IRR_REMITTANCE_STARTED}
              />
            </Box>
          </Box>
        </Box>
      );
    case 2:
      return (
        <Box
          sx={{
            flex: 1,
            display: { lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            px: "15px",
            py: "16px",
          }}
        >
          <Box
            sx={{
              width: { lg: 691 },
              height: { lg: 596 },
              backgroundColor: whiteColor,
              pt: "36px",
              pb: { xs: "16px", lg: "23px" },
              px: { xs: "12px", lg: "36px" },
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <Button
              text="بازگشت به حساب ها"
              startIcon={<CoinStackIcon />}
              sx={{
                position: "absolute",
                top: 26,
                left: 36,
                color: forestShadowColor,
                display: { xs: "none", lg: "flex" },
              }}
              variant="outlined"
              fullWidth={false}
              color="silverSage"
              onClick={() => navigate("/virtual-accounts")}
            />
            <Box sx={{ textAlign: "center", width: { lg: 380 }, m: "auto" }}>
              <Box
                sx={{
                  width: 68,
                  height: 68,
                  backgroundColor: aquaMist,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mb: "27px",
                }}
              >
                <CheckIcon width={40.8} height={40.8} color={primaryColor} />
              </Box>
              <Typography sx={{ fontWeight: 700, mb: 2 }}>
                درخواست برداشت از حساب شما ثبت شد!
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: slateGrayColor,
                  mb: 2,
                  lineHeight: 1.8,
                }}
              >
                این مبلغ در سیکل های پایا به حساب بانکی شما واریز و از طریق
                ایمیل به شما اطلاع رسانی خواهد شد
              </Typography>
              <Box
                sx={{
                  backgroundColor: peachBlush,
                  width: 200,
                  mx: "auto",
                  height: 33,
                  mb: "27px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  borderRadius: "4px",
                }}
              >
                <TimeLineIcon />
                <Typography sx={{ fontSize: 14, fontWeight: 500, ml: 1 }}>
                  تاریخ: {moment().format("HH:mm - YYYY/MM/DD")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>مبلغ:</Typography>
                <Typography>
                  {addBalanceSeparator(amount)}{" "}
                  <Typography
                    sx={{
                      color: primaryColor,
                      display: "inline-block",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    ریالءء
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>واریز به:</Typography>
                <Typography>
                  {bankCards[0]?.bank_response_first_name}{" "}
                  {bankCards[0]?.bank_response_last_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>شماره شبا:</Typography>
                <Typography>{bankCards[0].iban}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography sx={{ mr: "auto" }}>کارمزد:</Typography>
                <Typography>
                  {addBalanceSeparator((amount * user.payout_commission) / 100)}
                </Typography>
                <Typography sx={{ fontSize: 12, color: primaryColor, ml: 0.5 }}>
                  ریالءء
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>شماره پیگیری:</Typography>
                <Typography>#{data.tracking_code}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
  }
};

export default Payout;
