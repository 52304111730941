import axios from "axios";
import { store } from "../../redux/store";
import { logoutSuccessAction } from "../../redux/actions/authActions";
import { api2BaseUrl } from "../../utils/constants";
import { showSnackbar } from "../../components/Snackbar";
// import { hideMessage, showMessage } from "../../components/Message";
// import { Box, Grid, Typography } from "@mui/material";
// import Button from "../../components/Button";
// import { SendSqaureIcon } from "../../assets/icons";
// import { graphiteGrayColor } from "../../assets/colors";
// import { Link } from "react-router-dom";
// import { t } from "i18next";

const axiosMiddleware = axios.create({
  baseURL: `${api2BaseUrl}/api`,
  timeout: 30000,
  // You can set other default config options here
});

// Add a request interceptor
axiosMiddleware.interceptors.request.use(
  (config) => {
    const { auth, settings } = store.getState();
    const accessToken = auth.token;
    // const lang = settings.lang;

    // Set Authorization header if accessToken is available
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    // Set Lang header
    // config.headers["Lang"] = lang;

    // Handle upload progress tracking
    if (config.trackUploadProgress) {
      config.onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (typeof config.trackUploadProgress === "function") {
          config.trackUploadProgress(percentCompleted);
        }
      };
    }

    return config;
  },
  (error) => {
    // Reject promise with the error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosMiddleware.interceptors.response.use(
  (response) => {
    console.warn("axiosMiddleware response", {
      endpoint: response.config.url,
      response,
    });
    // Reset percentCompleted to 0 after request completes
    if (
      response.config.trackUploadProgress &&
      typeof response.config.trackUploadProgress === "function"
    ) {
      response.config.trackUploadProgress(0);
    }
    return response.data;
  },
  async (error) => {
    console.warn("axiosMiddleware error", {
      endpoint: error.config.url,
      error,
    });
    // Reset percentCompleted to 0 after request completes with error
    if (
      error.config.trackUploadProgress &&
      typeof error.config.trackUploadProgress === "function"
    ) {
      error.config.trackUploadProgress(0);
    }
    const originalRequest = error.config;

    // if (!("response" in error)) {
    //   return showMessage({
    //     message: <>{t("technicalDifficultiesDesc")}</>,
    //     buttons: (
    //       <>
    //         <Box sx={{ mr: 1 }}>
    //           <Button
    //             text={t("tryAgain")}
    //             onClick={() => {
    //               axiosMiddleware(originalRequest);
    //               hideMessage();
    //             }}
    //             sx={{ boxShadow: "none", py: 1.207 }}
    //             color="silverCloud"
    //           />
    //         </Box>
    //         <Box>
    //           <Button
    //             text={
    //               <>
    //                 {t("reportTheProblem")}{" "}
    //                 <SendSqaureIcon color={graphiteGrayColor} />
    //               </>
    //             }
    //             onClick={() =>
    //               showMessage({
    //                 message: <>{t("thankYouForBringingThisToOurAttention")}</>,
    //                 buttons: (
    //                   <Box sx={{ width: 207 }}>
    //                     <Button
    //                       component={Link}
    //                       text={
    //                         <>
    //                           {t("home")}{" "}
    //                           <SendSqaureIcon color={graphiteGrayColor} />
    //                         </>
    //                       }
    //                       color="silverCloud"
    //                       to="/my-wallets"
    //                       sx={{ boxShadow: "none" }}
    //                     />
    //                   </Box>
    //                 ),
    //               })
    //             }
    //             sx={{ boxShadow: "none" }}
    //             color="silverCloud"
    //           />
    //         </Box>
    //       </>
    //     ),
    //   });
    // }
    // Handle token expiration and refresh
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const state = store.getState();
      const refreshToken = state.auth.refreshToken;

      if (refreshToken) {
        try {
          const newTokens = await axiosMiddleware({
            method: "post",
            url: "refresh",
            data: { refresh_token: refreshToken },
          });
          // Update tokens in local storage or state
          // authService.setAccessToken(newTokens.accessToken);
          // authService.setRefreshToken(newTokens.refreshToken);

          // Retry the original request with the new token
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${newTokens.accessToken}`;
          return axiosMiddleware(originalRequest);
        } catch (refreshError) {
          // Handle refresh error (e.g., logout user)
          store.dispatch(logoutSuccessAction());
        }
      } else {
        // Handle refresh token absence (e.g., logout user)
        showSnackbar({
          type: "danger",
          message:
            "دسترسی شما منقضی شده است. لطفا دوباره وارد حساب کاربری شوید.",
        });
        store.dispatch(logoutSuccessAction());
      }
    }
    // if (error.response.status === 503) {
    //   return showMessage({
    //     message: error.response.data.error_description.message,
    //     buttons: (
    //       <>
    //         <Box sx={{ mr: 1 }}>
    //           <Button
    //             text={t("retry")}
    //             onClick={() => {
    //               axiosMiddleware(originalRequest);
    //               hideMessage();
    //             }}
    //             sx={{ boxShadow: "none", py: 1.207 }}
    //             color="silverCloud"
    //           />
    //         </Box>
    //         <Box sx={{ mr: 1 }}>
    //           <Button
    //             text={t("goBack")}
    //             color="silverCloud"
    //             onClick={() => window.history.back()}
    //             sx={{ boxShadow: "none", py: 1.207 }}
    //           />
    //         </Box>
    //         <Box sx={{ mr: 1 }}>
    //           <Button
    //             text={
    //               <>
    //                 {t("reportIssue")}{" "}
    //                 <SendSqaureIcon color={graphiteGrayColor} />
    //               </>
    //             }
    //             color="silverCloud"
    //             onClick={() =>
    //               showMessage({
    //                 message: <>{t("thankYouForBringingThisToOurAttention")}</>,
    //                 buttons: (
    //                   <Box sx={{ width: 207 }}>
    //                     <Button
    //                       component={Link}
    //                       text={
    //                         <>
    //                           {t("home")}{" "}
    //                           <SendSqaureIcon color={graphiteGrayColor} />
    //                         </>
    //                       }
    //                       color="silverCloud"
    //                       to="/my-wallets"
    //                       sx={{ boxShadow: "none" }}
    //                     />
    //                   </Box>
    //                 ),
    //               })
    //             }
    //             sx={{ boxShadow: "none" }}
    //           />
    //         </Box>
    //       </>
    //     ),
    //   });
    // }
    return Promise.reject(error.response.data);
  }
);

export default axiosMiddleware;
