import {
  createUIdLinkAPI,
  deleteAccountAPI,
  forgotEmailAPI,
  forgotOtpAPI,
  forgotPasswordAPI,
  getUserProfileAPI,
  logoutAPI,
  registerUserAPI,
  resendRegisterCodeAPI,
  sendMobileCodeAPI,
  signinAPI,
  verifyAPI,
  verifyMobileCodeAPI,
} from "../../services/api/auth";
import {
  CREATE_UID_LINK_FAILURE,
  CREATE_UID_LINK_STARTED,
  CREATE_UID_LINK_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_STARTED,
  DELETE_ACCOUNT_SUCCESS,
  FORGOT_EMAIL_FAILURE,
  FORGOT_EMAIL_STARTED,
  FORGOT_EMAIL_SUCCESS,
  FORGOT_OTP_FAILURE,
  FORGOT_OTP_STARTED,
  FORGOT_OTP_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_STARTED,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_STARTED,
  REGISTER_USER_SUCCESS,
  RESEND_REGISTER_CODE_FAILURE,
  RESEND_REGISTER_CODE_STARTED,
  RESEND_REGISTER_CODE_SUCCESS,
  SEND_MOBILE_CODE_FAILURE,
  SEND_MOBILE_CODE_STARTED,
  SEND_MOBILE_CODE_SUCCESS,
  SIGNIN_FAILURE,
  SIGNIN_STARTED,
  SIGNIN_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_MOBILE_CODE_FAILURE,
  VERIFY_MOBILE_CODE_STARTED,
  VERIFY_MOBILE_CODE_SUCCESS,
  VERIFY_STARTED,
  VERIFY_SUCCESS,
} from "../actionTypes";
import { showFirstSplashRequestAction } from "./configActions";
// --------------------------------------------------------------------------------
export const registerUserRequestAction = ({
  firstname,
  lastname,
  mobile,
  promotionCode,
  setErrors,
  setStep,
  setCounter,
}) => {
  return async (dispatch) => {
    dispatch(registerUserStartedAction());
    try {
      let response = await registerUserAPI({
        firstname,
        lastname,
        mobile,
        promotionCode,
      });
      if (response.status === "success") {
        dispatch(registerUserSuccessAction());
        setStep(1);
        setCounter(120);
      } else {
        dispatch(registerUserFailureAction(response.error_description));
        // setErrors({ mobile: response.data });
      }
    } catch (error) {
      dispatch(registerUserFailureAction(error.data));
      setErrors({
        mobile: error.message.mobile,
        promotionCode: error.message.promotion_code,
      });
    }
  };
};

export const registerUserStartedAction = () => ({
  type: REGISTER_USER_STARTED,
});

export const registerUserSuccessAction = (payload) => ({
  type: REGISTER_USER_SUCCESS,
  payload,
});

export const registerUserFailureAction = (message) => ({
  type: REGISTER_USER_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const signinRequestAction = (
  data,
  { setStep, setCounter, setErrors }
) => {
  return async (dispatch) => {
    dispatch(signinStartedAction());
    try {
      let response = await signinAPI(data);
      if (response.status === "success") {
        dispatch(signinSuccessAction({ user: {}, token: "" }));
        if ("email" in data) setStep("Email");
        else if ("mobile" in data) {
          setStep("Mobile");
          setCounter(120);
        }
      } else {
        dispatch(signinFailureAction(response.error_description));
        setErrors({ username: response.data });
      }
    } catch (error) {
      dispatch(signinFailureAction(error.error_description));
      setErrors({ username: error.data });
    }
  };
};

export const signinStartedAction = () => ({
  type: SIGNIN_STARTED,
});

export const signinSuccessAction = (payload) => ({
  type: SIGNIN_SUCCESS,
  payload,
});

export const signinFailureAction = (message) => ({
  type: SIGNIN_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const verifyRequestAction = (data, { setErrors }) => {
  return async (dispatch) => {
    dispatch(verifyStartedAction());
    try {
      let response = await verifyAPI(data);
      if (response.status === "success") {
        dispatch(showFirstSplashRequestAction(true));
        if (response.data.new_dashboard)
          dispatch(
            verifySuccessAction({
              user: response.data,
              token: response.data.token,
            })
          );
        else {
          window.open(
            `https://dashboard.yekpay.com/fa/auth/login/${response.data.token}`,
            "_self"
          );
        }
      } else {
        dispatch(verifyFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(verifyFailureAction(error.error_description));
      setErrors({ otp: error.message, password: error.message });
    }
  };
};

export const verifyStartedAction = () => ({
  type: VERIFY_STARTED,
});

export const verifySuccessAction = (payload) => ({
  type: VERIFY_SUCCESS,
  payload,
});

export const verifyFailureAction = (message) => ({
  type: VERIFY_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const forgotEmailRequestAction = ({
  email,
  setStep,
  setCounter,
  setErrors,
}) => {
  return async (dispatch) => {
    dispatch(forgotEmailStartedAction());
    try {
      let response = await forgotEmailAPI({ email });
      if (response.status === "success") {
        dispatch(forgotEmailSuccessAction());
        setStep(1);
        setCounter(120);
      } else {
        dispatch(forgotEmailFailureAction(response.data.error_description));
      }
    } catch (error) {
      setErrors({ email: error.message });
      dispatch(forgotEmailFailureAction(error.error_description));
    }
  };
};

export const forgotEmailStartedAction = () => ({
  type: FORGOT_EMAIL_STARTED,
});

export const forgotEmailSuccessAction = (payload) => ({
  type: FORGOT_EMAIL_SUCCESS,
  payload,
});

export const forgotEmailFailureAction = (message) => ({
  type: FORGOT_EMAIL_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const forgotOtpRequestAction = ({ email, otp, setStep, setErrors }) => {
  return async (dispatch) => {
    dispatch(forgotOtpStartedAction());
    try {
      let response = await forgotOtpAPI({ email, otp });
      if (response.status === "success") {
        dispatch(forgotOtpSuccessAction());
        setStep(2);
      } else {
        dispatch(forgotOtpFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(forgotOtpFailureAction(error.error_description));
      setErrors({ otp: error.message });
    }
  };
};

export const forgotOtpStartedAction = () => ({
  type: FORGOT_OTP_STARTED,
});

export const forgotOtpSuccessAction = (payload) => ({
  type: FORGOT_OTP_SUCCESS,
  payload,
});

export const forgotOtpFailureAction = (message) => ({
  type: FORGOT_OTP_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const forgotPasswordRequestAction = ({
  email,
  otp,
  newPassword,
  confirmNewPassword,
  navigate,
}) => {
  return async (dispatch) => {
    dispatch(forgotPasswordStartedAction());
    try {
      let response = await forgotPasswordAPI({
        email,
        otp,
        newPassword,
        confirmNewPassword,
      });
      if (response.status === "success") {
        dispatch(forgotPasswordSuccessAction());
        navigate("/login");
      } else {
        dispatch(forgotPasswordFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(forgotPasswordFailureAction(error.error_description));
    }
  };
};

export const forgotPasswordStartedAction = () => ({
  type: FORGOT_PASSWORD_STARTED,
});

export const forgotPasswordSuccessAction = (payload) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailureAction = (message) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const logoutRequestAction = () => {
  return async (dispatch) => {
    dispatch(logoutStartedAction());
    try {
      let response = await logoutAPI();
      if (response.status === "success") {
        dispatch(showFirstSplashRequestAction(true));
        dispatch(logoutSuccessAction());
      } else {
        dispatch(logoutFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(logoutFailureAction(error.error_description));
    }
  };
};

export const logoutStartedAction = () => ({
  type: LOGOUT_STARTED,
});

export const logoutSuccessAction = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailureAction = (message) => ({
  type: LOGOUT_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createUIdLinkRequestAction = () => {
  return async (dispatch) => {
    dispatch(createUIdLinkStartedAction());
    try {
      let response = await createUIdLinkAPI();
      if (response.status === "success") {
        dispatch(createUIdLinkSuccessAction());
        window.open(response.data, "_self");
      } else {
        dispatch(createUIdLinkFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(createUIdLinkFailureAction(error.error_description));
    }
  };
};

export const createUIdLinkStartedAction = () => ({
  type: CREATE_UID_LINK_STARTED,
});

export const createUIdLinkSuccessAction = () => ({
  type: CREATE_UID_LINK_SUCCESS,
});

export const createUIdLinkFailureAction = (message) => ({
  type: CREATE_UID_LINK_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getUserProfileRequestAction = () => {
  return async (dispatch) => {
    dispatch(getUserProfileStartedAction());
    try {
      let response = await getUserProfileAPI();
      if (response.status === "success") {
        dispatch(getUserProfileSuccessAction({ user: response.data }));
      } else {
        dispatch(getUserProfileFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUserProfileFailureAction(error.error_description));
    }
  };
};

export const getUserProfileStartedAction = () => ({
  type: GET_USER_PROFILE_STARTED,
});

export const getUserProfileSuccessAction = (payload) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload,
});

export const getUserProfileFailureAction = (message) => ({
  type: GET_USER_PROFILE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const resendRegisterCodeRequestAction = ({ mobile, setCounter }) => {
  return async (dispatch) => {
    dispatch(resendRegisterCodeStartedAction());
    try {
      let response = await resendRegisterCodeAPI({ mobile });
      if (response.status === "success") {
        dispatch(resendRegisterCodeSuccessAction());
        setCounter(120);
      } else {
        dispatch(
          resendRegisterCodeFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(resendRegisterCodeFailureAction(error.error_description));
    }
  };
};

export const resendRegisterCodeStartedAction = () => ({
  type: RESEND_REGISTER_CODE_STARTED,
});

export const resendRegisterCodeSuccessAction = (payload) => ({
  type: RESEND_REGISTER_CODE_SUCCESS,
  payload,
});

export const resendRegisterCodeFailureAction = (message) => ({
  type: RESEND_REGISTER_CODE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const sendMobileCodeRequestAction = ({ mobile, onSuccess }) => {
  return async (dispatch) => {
    dispatch(sendMobileCodeStartedAction());
    try {
      let response = await sendMobileCodeAPI({ mobile });
      if (response.status === "success") {
        dispatch(sendMobileCodeSuccessAction());
        onSuccess();
      } else {
        dispatch(sendMobileCodeFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(sendMobileCodeFailureAction(error.error_description));
    }
  };
};

export const sendMobileCodeStartedAction = () => ({
  type: SEND_MOBILE_CODE_STARTED,
});

export const sendMobileCodeSuccessAction = (payload) => ({
  type: SEND_MOBILE_CODE_SUCCESS,
  payload,
});

export const sendMobileCodeFailureAction = (message) => ({
  type: SEND_MOBILE_CODE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const verifyMobileCodeRequestAction = ({ otp, onSuccess }) => {
  return async (dispatch) => {
    dispatch(verifyMobileCodeStartedAction());
    try {
      let response = await verifyMobileCodeAPI({ otp });
      if (response.status === "success") {
        dispatch(verifyMobileCodeSuccessAction());
        onSuccess();
      } else {
        dispatch(
          verifyMobileCodeFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(verifyMobileCodeFailureAction(error.error_description));
    }
  };
};

export const verifyMobileCodeStartedAction = () => ({
  type: VERIFY_MOBILE_CODE_STARTED,
});

export const verifyMobileCodeSuccessAction = () => ({
  type: VERIFY_MOBILE_CODE_SUCCESS,
});

export const verifyMobileCodeFailureAction = (message) => ({
  type: VERIFY_MOBILE_CODE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const deleteAccountRequestAction = () => {
  return async (dispatch) => {
    dispatch(deleteAccountStartedAction());
    try {
      let response = await deleteAccountAPI();
      if (response.status === "success") {
        dispatch(deleteAccountSuccessAction());
        dispatch(logoutRequestAction());
        // setCounter(120);
        // setShowOtpInput(true);
      } else {
        dispatch(deleteAccountFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(deleteAccountFailureAction(error.error_description));
    }
  };
};

export const deleteAccountStartedAction = () => ({
  type: DELETE_ACCOUNT_STARTED,
});

export const deleteAccountSuccessAction = (payload) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload,
});

export const deleteAccountFailureAction = (message) => ({
  type: DELETE_ACCOUNT_FAILURE,
  payload: message,
});
