import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled,
  DialogTitle,
  ButtonBase,
  DialogActions,
  DialogContent,
  Dialog,
  SwipeableDrawer,
} from "@mui/material";
import {
  aliceBlueColor,
  aquaMist,
  arcticMistColor,
  deepBlue,
  forestShadowColor,
  primaryColor,
  silverCloudColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import Button from "../../components/Button";
import {
  ArrowLeftIcon,
  CheckFillIcon,
  CheckIcon,
  ChevronRightIcon,
  CloseIcon,
  EuroIcon,
  RocketIcon,
} from "../../assets/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { upgradePlanRequestAction } from "../../redux/actions/userActions";
import { ChevronDownIcon as ArrowDownIcon } from "../../assets/icons";
import {
  CREATE_TICKET_STARTED,
  UPGRADE_PLAN_STARTED,
} from "../../redux/actionTypes";
import moment from "moment-jalaali";
import { createTicketRequestAction } from "../../redux/actions/ticketActions";
import { appBarHeight, drawerWidth } from "../../utils/constants";

function createData(property, personal, business, specialBusiness) {
  return { property, personal, business, specialBusiness };
}

const PlanFeature = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    auth: { user },
    user: { plansHistory, rType },
    euroBalance,
    ticket,
  } = useSelector((state) => ({
    auth: state.auth,
    user: state.user,
    euroBalance: state.virtualAccounts.virtualAccounts[0]?.balance,
    ticket: state.ticket,
  }));
  const [recieptData, setRecieptData] = useState({});

  const initialState = {
    selectedPlan: {},
    openPersonalPlanConfirm: false,
    openPayConfirm: false,
    duration: 1,
    payStep: 0,
  };
  const [
    {
      selectedPlan,
      openPersonalPlanConfirm,
      openPayConfirm,
      duration,
      payStep,
    },
    setInitState,
  ] = useState(initialState);

  const durationText = duration === 1 ? "ماهانه" : "سالیانه";
  const open = openPayConfirm || openPersonalPlanConfirm;

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const rows = [
    createData(
      "ﺳﻘﻒ ﺑﺮداﺷﺖ روزاﻧﻪ",
      "5,000,000 تومانءء",
      "10,000,000 تومانءء",
      "20,000,000 تومانءء"
    ),
    createData("ﮐﺎرﻣﺰد ﺗﺒﺪﯾﻞ واحد", "0.3%", "0.3%", "0.3%"),
    createData("ﮐﺎرﻣﺰد ﺑﺮداﺷﺖ وﺟﻪ", "8%", "8%", "8%"),
    createData("تعداد پی لینک شخصی فعال", "1", "3", "نامحدود"),
    createData("تعداد پی لینک محصول فعال", "1", "3", "نامحدود"),
    createData(
      "تعداد وب گیت فعال",
      <Box
        sx={{
          backgroundColor: aliceBlueColor,
          width: 20,
          height: 20,
          mx: "auto",
          borderRadius: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CloseIcon width={14} height={14} />
      </Box>,
      1,
      "نامحدود"
    ),
    createData(
      null,
      <Button
        text={user.plan_id === 1 ? "پلن فعال" : "انتخاب پلن"}
        sx={{ borderRadius: 100 }}
        onClick={() =>
          setState({
            selectedPlan: {
              name: "شخصی",
              price: "رایگان!",
              id: 1,
            },
            openPersonalPlanConfirm: true,
          })
        }
        disabled={user.plan_id === 1}
      />,
      <Button
        text={user.plan_id === 2 ? "پلن فعال" : "خرید پلن"}
        sx={{ borderRadius: 100 }}
        onClick={() =>
          setState({
            selectedPlan: {
              name: "کسب و کار",
              price: duration === 1 ? "9.99" : "99.99",
              id: 2,
              payStep: 0,
            },
            openPayConfirm: true,
          })
        }
        disabled={user.plan_id === 2}
      />,
      <Button
        text={user.plan_id === 3 ? "پلن فعال" : "خرید پلن"}
        sx={{ borderRadius: 100 }}
        onClick={() =>
          setState({
            selectedPlan: {
              name: "کسب و کار ویژه",
              price: duration === 1 ? "49.99" : "499.99",
              id: 3,
            },
            openPayConfirm: true,
            payStep: 0,
          })
        }
        disabled={user.plan_id === 3}
      />
    ),
  ];

  const handleChange = (event, newValue) => {
    setState({ duration: newValue, selectedPlan: {} });
  };

  const renderPayConfirm = () => {
    switch (payStep) {
      case 0:
        return (
          <>
            <DialogTitle sx={{ pt: "33px", pb: "28px" }}>
              <Box
                sx={{
                  height: 48,
                  backgroundColor: primaryColor,
                  width: 160,
                  borderTopLeftRadius: 100,
                  borderBottomLeftRadius: 100,
                  position: "absolute",
                  top: 28,
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: 22.58, fontWeight: 700, color: whiteColor }}
                >
                  <Typography
                    sx={{
                      fontSize: 14.11,
                      display: "inline-block",
                      color: whiteColor,
                      fontWeight: 399,
                    }}
                  >
                    {duration === 1 ? "ماهیانه" : "سالیانه"}/
                  </Typography>
                  {selectedPlan.price} €
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: arcticMistColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 100,
                  }}
                >
                  <RocketIcon />
                </Box>
                <Typography sx={{ ml: 1, fontWeight: 600, fontSize: 18 }}>
                  خرید پلن {selectedPlan.name}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  mb: "20px",
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: deepBlue,
                    borderRadius: 100,
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                  }}
                >
                  <EuroIcon
                    width={24}
                    height={24}
                    color={whiteColor}
                    style={{ marginRight: 3, marginTop: 2.5 }}
                  />
                </Box>
                <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
                  موجودی شما
                </Typography>
                <Typography>{euroBalance}</Typography>
                <EuroIcon
                  width={18}
                  height={18}
                  style={{ marginBottom: "4px" }}
                />
              </Box>
              <Typography sx={{ fontWeight: 600, mb: "20px" }}>
                انتخاب روش پرداخت
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: `1px solid ${silverSageColor}`,
                  height: 73,
                  px: "19px",
                  borderRadius: "8px",
                  mb: "12px",
                  cursor: "pointer",
                  transition: "all .5s",
                  textAlign: "left",
                  width: "100%",
                  ":hover": {
                    borderColor: primaryColor,
                  },
                  ":disabled": {
                    opacity: 0.45,
                  },
                }}
                component={ButtonBase}
                onClick={() => setState({ payStep: 1 })}
                disabled={Number(euroBalance) < Number(selectedPlan.price)}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      // color: primaryColor,
                      mb: "12px",
                    }}
                  >
                    پرداخت از موجودی حساب
                  </Typography>
                  {Number(euroBalance) < Number(selectedPlan.price) ? (
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      موجودی کافی نیست!
                    </Typography>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          color: slateGrayColor,
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      >
                        موجودی شما: {euroBalance}
                      </Typography>
                      <EuroIcon width={16} height={16} color={slateGrayColor} />
                    </Box>
                  )}
                </Box>
                <ArrowLeftIcon width={24} height={24} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: `1px solid ${silverSageColor}`,
                  height: 73,
                  px: "19px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all .5s",
                  textAlign: "left",
                  width: "100%",
                  ":hover": {
                    borderColor: primaryColor,
                  },
                }}
                component={ButtonBase}
                onClick={() => {
                  dispatch(
                    createTicketRequestAction({
                      data: {
                        lang: "fa",
                        source: "Website",
                        subject: "خرید اشتراک",
                        text: `<p>با سلام</br>لطفا جهت خرید اشتراک ${
                          selectedPlan.name
                        } ${
                          duration === 1 ? "یک ماهه" : "یک ساله"
                        } شماره حساب و مبلغ را ارسال نمایید.</br>با تشکر</p>`,
                        category_id: 6,
                      },
                      navigate,
                    })
                  );
                }}
                disabled={ticket.rType == CREATE_TICKET_STARTED}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      // color: primaryColor,
                      mb: "12px",
                    }}
                  >
                    پرداخت به حساب بانکی
                  </Typography>
                  <Typography
                    sx={{
                      color: slateGrayColor,
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    شارژ حساب از طریق ثبت تیکت به واحد مالی
                  </Typography>
                </Box>
                <ArrowLeftIcon width={24} height={24} />
              </Box>
            </DialogContent>
          </>
        );
      case 1:
        return (
          <>
            <DialogContent sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  width: 89,
                  height: 89,
                  backgroundColor: arcticMistColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  mx: "auto",
                  mb: "21px",
                }}
              >
                <RocketIcon width={53.4} height={53.4} />
              </Box>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  width: 308,
                  lineHeight: 2,
                  mx: "auto",
                  mb: "21px",
                }}
              >
                آیا میخواهید اشتراک {duration === 1 ? "ماهیانه" : "سالیانه"}{" "}
                {selectedPlan.name} را تهیه کنید؟
              </Typography>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  mb: "13px",
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: deepBlue,
                    borderRadius: 100,
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                  }}
                >
                  <EuroIcon
                    width={24}
                    height={24}
                    color={whiteColor}
                    style={{ marginRight: 3, marginTop: 2.5 }}
                  />
                </Box>
                <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
                  موجودی شما
                </Typography>
                <Typography>{euroBalance}</Typography>
                <EuroIcon width={18} height={18} />
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RocketIcon width={23.4} height={23.4} />
                </Box>
                <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
                  هزینه اشتراک
                </Typography>
                <Typography>{selectedPlan.price}</Typography>
                <EuroIcon width={18} height={18} />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
                px: "24px",
                pb: "38px",
                pt: "17px",
              }}
            >
              <Button
                sx={{ width: 131, borderRadius: 100, color: slateGrayColor }}
                text="مرحله قبل"
                color="silverSage"
                variant="outlined"
                onClick={() => setState({ payStep: 0 })}
              />
              <Button
                sx={{ width: 253, borderRadius: 100 }}
                text="تایید و پرداخت"
                onClick={() =>
                  dispatch(
                    upgradePlanRequestAction({
                      setState,
                      setRecieptData,
                      planId: selectedPlan.id,
                      duration,
                    })
                  )
                }
                loading={rType === UPGRADE_PLAN_STARTED}
              />
            </DialogActions>
          </>
        );
      case 2:
        return (
          <>
            <DialogContent sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  width: 87,
                  height: 87,
                  backgroundColor: aquaMist,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mb: "22px",
                }}
              >
                <CheckIcon width={50} height={87} color={primaryColor} />
              </Box>
              <Typography sx={{ fontWeight: 700, mb: "31px" }}>
                اشتراک شما با موفقیت فعال شد!
              </Typography>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  // border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Typography sx={{ fontSize: 13, fontWeight: 600, mr: "auto" }}>
                  هزینه اشتراک
                </Typography>
                <Typography sx={{ fontSize: 14.54, fontWeight: 700 }}>
                  {recieptData.amount}
                </Typography>
                <EuroIcon width={18} height={18} />
              </Box>
              <Box
                sx={{
                  height: 55,
                  // border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Typography sx={{ fontSize: 13, fontWeight: 600, mr: "auto" }}>
                  تاریخ شروع اشتراک
                </Typography>
                <Typography sx={{ fontSize: 14.54, fontWeight: 700 }}>
                  {moment(recieptData.start_date).format("jYYYY/jMM/jDD")}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  // border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Typography sx={{ fontSize: 13, fontWeight: 600, mr: "auto" }}>
                  تاریخ پایان اشتراک
                </Typography>
                <Typography sx={{ fontSize: 14.54, fontWeight: 700 }}>
                  {moment(recieptData.end_date).format("jYYYY/jMM/jDD")}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions sx={{ px: "27px", pb: "40px" }}>
              <Button
                text="تایید و بستن"
                sx={{ borderRadius: 100 }}
                onClick={() =>
                  setState({
                    openPayConfirm: false,
                    selectedPlan: {},
                    payStep: 0,
                  })
                }
              />
            </DialogActions>
          </>
        );
      default:
        break;
    }
  };

  const renderPersonalPlanConfirm = () => (
    <>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box
          sx={{
            width: 89,
            height: 89,
            backgroundColor: arcticMistColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 100,
            mx: "auto",
            mb: "21px",
          }}
        >
          <RocketIcon width={53.4} height={53.4} />
        </Box>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 600,
            // width: 308,
            lineHeight: 2,
            mx: "auto",
            mb: "21px",
          }}
        >
          آیا میخواهید اشتراک شخصی را انتخاب کنید؟
        </Typography>

        <Box
          sx={{
            backgroundColor: arcticMistColor,
            height: 55,
            border: `1px solid ${silverSageColor}`,
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            px: 1,
            // mb: "20px",
          }}
        >
          <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
            هزینه اشتراک
          </Typography>
          <Typography>{selectedPlan.price}</Typography>
          {/* <EuroIcon width={18} height={18} /> */}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: "24px",
          pb: "38px",
          pt: "17px",
        }}
      >
        <Button
          sx={{ width: 131, borderRadius: 100, color: slateGrayColor }}
          text="بستن"
          color="silverSage"
          variant="outlined"
          onClick={() => setState({ openPersonalPlanConfirm: false })}
        />
        <Button
          sx={{ borderRadius: 100, width: 253 }}
          text="تایید"
          onClick={() =>
            dispatch(
              upgradePlanRequestAction({
                setState,
                setRecieptData,
                planId: selectedPlan.id,
                duration,
              })
            )
          }
          loading={rType === UPGRADE_PLAN_STARTED}
        />
      </DialogActions>
    </>
  );

  const dialogContent = openPayConfirm
    ? renderPayConfirm()
    : renderPersonalPlanConfirm();

  const renderDialog = (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
          width: 452,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        display: { xs: "none", lg: "block" },
      }}
      onClose={() =>
        setState({
          openPayConfirm: false,
          openPersonalPlanConfirm: false,
          payStep: 0,
        })
      }
      scroll="body"
    >
      {dialogContent}
    </Dialog>
  );

  const renderDrawer = (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      // onOpen={() => setOpen(true)}
      disableSwipeToOpen
      onClose={() =>
        setState({
          openPayConfirm: false,
          openPersonalPlanConfirm: false,
          payStep: 0,
        })
      }
      PaperProps={{
        sx: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          top: appBarHeight,
        },
      }}
      sx={{ zIndex: 9999, display: { lg: "none" } }}
      ModalProps={{
        keepMounted: false,
      }}
    >
      {dialogContent}
    </SwipeableDrawer>
  );

  return (
    <Box sx={{ px: { xs: 1, lg: "28px" }, py: { xs: 2, lg: "33px" }, flex: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Link
          component="button"
          underline="none"
          color="black"
          onClick={() => navigate("/account/plan")}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ChevronRightIcon width={20} height={20} />
            <Typography sx={{ fontWeight: 700 }}>
              مشاهده ویژگی پلن ها
            </Typography>
          </Box>
        </Link>
        <StyledTabs
          value={duration}
          onChange={handleChange}
          sx={{ display: { xs: "none", lg: "flex" } }}
        >
          <StyledTab label="ماهیانه" value={1} />
          <StyledTab label="سالیانه" value={12} />
        </StyledTabs>
        <Box sx={{ width: { lg: 160 } }}></Box>
      </Box>
      <Box sx={{ display: { lg: "none" } }}>
        <Accordion
          // expanded={expanded === "panel1"}
          // onChange={handleChange("panel1")}
          sx={{ mb: 2 }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Box>
              <Typography
                sx={{ display: "inline-block", fontWeight: 300, mb: 1 }}
              >
                پلن
              </Typography>{" "}
              <Typography sx={{ display: "inline-block", fontWeight: 600 }}>
                شخصی
              </Typography>
              <Typography sx={{ color: primaryColor, fontWeight: 800 }}>
                رایگان!
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ pr: 1, pl: 1.25 }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                5,000,000 تومانءء سقف برداشت روزانه
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                0.3% کارمزد تبدیل واحد
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                ۸% کارمزد برداشت وجه
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                1 پی لینک شخصی فعال
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
                mb: 2,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                1 پی‌لینک محصول فعال
              </Typography>
            </Box>
            <Button
              text={user.plan_id === 1 ? "پلن فعال" : "انتخاب پلن"}
              sx={{ borderRadius: 100 }}
              onClick={() =>
                setState({
                  selectedPlan: {
                    name: "شخصی",
                    price: "رایگان!",
                    id: 1,
                  },
                  openPersonalPlanConfirm: true,
                })
              }
              disabled={user.plan_id === 1}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          // expanded={expanded === "panel1"}
          // onChange={handleChange("panel1")}
          sx={{ mb: 2 }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Box>
              <Typography
                sx={{ display: "inline-block", fontWeight: 300, mb: 1 }}
              >
                پلن
              </Typography>{" "}
              <Typography sx={{ display: "inline-block", fontWeight: 600 }}>
                ﮐﺴﺐ‌وﮐﺎر
              </Typography>
              <Typography sx={{ fontWeight: 800 }}>
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: 10,
                    fontWeight: 300,
                  }}
                >
                  {durationText} /
                </Typography>{" "}
                {duration === 1 ? "9.99" : "99.99"} €
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ pr: 1, pl: 1.25 }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                10,000,000 تومانءء سقف برداشت روزانه
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                0.3% کارمزد تبدیل واحد
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                8% کارمزد برداشت وجه
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                3 پی لینک شخصی فعال
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                3 پی‌لینک محصول فعال
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
                mb: 2,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                1 وب گیت فعال
              </Typography>
            </Box>
            <Button
              text={user.plan_id === 2 ? "پلن فعال" : "خرید پلن"}
              sx={{ borderRadius: 100 }}
              onClick={() =>
                setState({
                  selectedPlan: {
                    name: "کسب و کار",
                    price: duration === 1 ? "9.99" : "99.99",
                    id: 2,
                    payStep: 0,
                  },
                  openPayConfirm: true,
                })
              }
              disabled={user.plan_id === 2}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
        // expanded={expanded === "panel1"}
        // onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Box>
              <Typography
                sx={{ display: "inline-block", fontWeight: 300, mb: 1 }}
              >
                پلن
              </Typography>{" "}
              <Typography sx={{ display: "inline-block", fontWeight: 600 }}>
                ﮐﺴﺐ‌وﮐﺎر ویژه
              </Typography>
              <Typography sx={{ fontWeight: 800 }}>
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: 10,
                    fontWeight: 300,
                  }}
                >
                  {durationText} /
                </Typography>{" "}
                {duration === 1 ? "49.99" : "499.99"} €
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ pr: 1, pl: 1.25 }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                20,000,000 تومانءء سقف برداشت روزانه
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                0.3% کارمزد تبدیل واحد
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                8% کارمزد برداشت وجه
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                بینهایت پی لینک شخصی فعال
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                بینهایت پی‌لینک محصول فعال
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                py: 1,
                mb: 2,
              }}
            >
              <CheckFillIcon width={20} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: slateGrayColor,
                  ml: 0.5,
                }}
              >
                بینهایت وب گیت فعال
              </Typography>
            </Box>
            <Button
              text={user.plan_id === 3 ? "پلن فعال" : "خرید پلن"}
              sx={{ borderRadius: 100 }}
              onClick={() =>
                setState({
                  selectedPlan: {
                    name: "کسب و کار ویژه",
                    price: duration === 1 ? "49.99" : "499.99",
                    id: 3,
                  },
                  openPayConfirm: true,
                  payStep: 0,
                })
              }
              disabled={user.plan_id === 3}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <TableContainer sx={{ display: { xs: "none", lg: "block" } }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderRight: `1px solid ${silverSageColor}` }}>
                مقایسه پلن ها
                <Typography sx={{ fontSize: 12, fontWeight: 300 }}>
                  بهترین را برای خود انتخاب کنید
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ borderRight: `1px solid ${silverSageColor}` }}
              >
                پلن شخصی
                <Typography sx={{ color: primaryColor, fontWeight: 800 }}>
                  رایگان!
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ borderRight: `1px solid ${silverSageColor}` }}
              >
                ﮐﺴﺐ‌وﮐﺎر
                <Typography sx={{ fontWeight: 800 }}>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: 10,
                      fontWeight: 300,
                    }}
                  >
                    {durationText} /
                  </Typography>{" "}
                  {duration === 1 ? "9.99" : "99.99"} €
                </Typography>
              </TableCell>
              <TableCell align="left">
                ﮐﺴﺐ‌وﮐﺎر ویژه{" "}
                <Typography sx={{ fontWeight: 800 }}>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: 10,
                      fontWeight: 300,
                    }}
                  >
                    {durationText} /
                  </Typography>{" "}
                  {duration === 1 ? "49.99" : "499.99"} €
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": {
                    borderBottom: 0,
                  },
                  "&:nth-of-type(odd)": {
                    backgroundColor:
                      rows.length - 1 === index ? "none" : arcticMistColor,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderRight: `1px solid ${silverSageColor}`,
                    width: 130,
                    py: "22.5px",
                  }}
                >
                  {row.property}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderRight: `1px solid ${silverSageColor}`,
                    width: 20,
                    py: "22.5px",
                  }}
                >
                  {row.personal}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderRight: `1px solid ${silverSageColor}`,
                    width: 20,
                    py: "22.5px",
                  }}
                >
                  {row.business}
                </TableCell>
                <TableCell align="center" sx={{ width: 20, py: "22.5px" }}>
                  {row.specialBusiness}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {renderDialog}
      {renderDrawer}
    </Box>
  );
};

export default PlanFeature;

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    height: 32,
    bottom: 18,
    borderRadius: 100,
    width: 60,
    height: 24,
  },
  "& .MuiTabs-indicatorSpan": {
    width: 60,
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
    backgroundColor: "#F7FAFA",
    borderRadius: 100,
    width: 131,
    height: 34,
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flex: 0.5,
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    // fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    // color: "rgba(255, 255, 255, 0.7)",

    "&.MuiTab-root": {
      minWidth: 60,
      width: 60,
      maxWidth: 60,
      padding: 0,
      fontSize: 12,
      minHeight: 30,
    },
    "&.Mui-selected": {
      color: "#fff",
      zIndex: 1,
    },
  })
);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${silverSageColor}`,
  borderRadius: "15px!important",
  "&::before": {
    display: "none",
  },
  // borderRight: `1px solid ${silverCloudColor}`,
  // borderLeft: `1px solid ${silverCloudColor}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  // "&:first-child": {
  //   borderTop: `1px solid ${silverCloudColor}`,
  // },
  // "&:last-child": {
  //   borderBottom: `1px solid ${silverCloudColor}`,
  // },
  // "&:first-of-type": {
  //   borderTopLeftRadius: 15,
  //   borderTopRightRadius: 15,
  // },
  // "&:last-of-type": {
  //   borderBottomLeftRadius: 15,
  //   borderBottomRightRadius: 15,
  // },
  // "&:nth-child(even)": {
  //   backgroundColor: arcticMistColor,
  // },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <Box
        sx={{
          width: 35,
          height: 35,
          backgroundColor: arcticMistColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 100,
        }}
      >
        <ArrowDownIcon width={17} color={forestShadowColor} />
      </Box>
    }
    {...props}
  />
))(({ theme }) => ({
  minHeight: 81,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1),
  // flexDirection: "row-reverse",
  // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
  //   transform: "rotate(90deg)",
  // },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
