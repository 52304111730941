import {
  CREATE_WEBGATE_FAILURE,
  CREATE_WEBGATE_STARTED,
  CREATE_WEBGATE_SUCCESS,
  GET_PLUGINS_FAILURE,
  GET_PLUGINS_STARTED,
  GET_PLUGINS_SUCCESS,
  GET_WEBGATES_FAILURE,
  GET_WEBGATES_STARTED,
  GET_WEBGATES_SUCCESS,
  SIGNIN_DEFAULT,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: SIGNIN_DEFAULT,
  webgates: [],
  plugins: [],
  error: "",
};
const webgateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_WEBGATE_STARTED:
    case GET_WEBGATES_STARTED:
    case GET_PLUGINS_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_WEBGATES_SUCCESS:
      return {
        ...state,
        webgates: action.payload.webgates,
        rType: GET_WEBGATES_SUCCESS,
        error: "",
      };
    case CREATE_WEBGATE_SUCCESS:
      return {
        ...state,
        rType: CREATE_WEBGATE_SUCCESS,
        error: "",
      };
    case GET_PLUGINS_SUCCESS:
      return {
        ...state,
        rType: GET_PLUGINS_SUCCESS,
        plugins: action.payload.plugins,
        error: "",
      };
    case CREATE_WEBGATE_FAILURE:
    case GET_WEBGATES_FAILURE:
    case GET_PLUGINS_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default webgateReducer;
