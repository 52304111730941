import { showSnackbar } from "../../components/Snackbar";
import {
  changePasswordAPI,
  checkPasswordAPI,
  getAccountEventsAPI,
  getGeneralSettingAPI,
  updateGeneralSettingAPI,
} from "../../services/api/setting";
import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_PASSWORD_FAILURE,
  CHECK_PASSWORD_STARTED,
  CHECK_PASSWORD_SUCCESS,
  GET_ACCOUNT_EVENTS_FAILURE,
  GET_ACCOUNT_EVENTS_STARTED,
  GET_ACCOUNT_EVENTS_SUCCESS,
  GET_GENERAL_SETTING_FAILURE,
  GET_GENERAL_SETTING_STARTED,
  GET_GENERAL_SETTING_SUCCESS,
  UPDATE_GENERAL_SETTING_FAILURE,
  UPDATE_GENERAL_SETTING_STARTED,
  UPDATE_GENERAL_SETTING_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
export const getGeneralSettingRequestAction = ({ setState }) => {
  return async (dispatch) => {
    dispatch(getGeneralSettingStartedAction());
    try {
      let response = await getGeneralSettingAPI();
      if (response.status === "success") {
        dispatch(getGeneralSettingSuccessAction(response.data));
        setState({
          otp: response.data.otp,
          automaticRenewal: response.data.renew,
          emailNotification: response.data.email_notification,
        });
      } else {
        getGeneralSettingFailureAction(response.data.error_description);
      }
    } catch (error) {
      getGeneralSettingFailureAction(error.error_description);
    }
  };
};

export const getGeneralSettingStartedAction = () => ({
  type: GET_GENERAL_SETTING_STARTED,
});

export const getGeneralSettingSuccessAction = (payload) => ({
  type: GET_GENERAL_SETTING_SUCCESS,
  payload,
});

export const getGeneralSettingFailureAction = (message) => ({
  type: GET_GENERAL_SETTING_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const updateGeneralSettingRequestAction = ({
  otp,
  automaticRenewal,
  emailNotification,
}) => {
  return async (dispatch) => {
    dispatch(updateGeneralSettingStartedAction());
    try {
      let response = await updateGeneralSettingAPI({
        otp,
        automaticRenewal,
        emailNotification,
      });
      if (response.status === "success") {
        dispatch(updateGeneralSettingSuccessAction());
      } else {
        updateGeneralSettingFailureAction(response.data.error_description);
      }
    } catch (error) {
      updateGeneralSettingFailureAction(error.error_description);
    }
  };
};

export const updateGeneralSettingStartedAction = () => ({
  type: UPDATE_GENERAL_SETTING_STARTED,
});

export const updateGeneralSettingSuccessAction = () => ({
  type: UPDATE_GENERAL_SETTING_SUCCESS,
});

export const updateGeneralSettingFailureAction = (message) => ({
  type: UPDATE_GENERAL_SETTING_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const checkPasswordRequestAction = ({
  currentPassword,
  setState,
  setErrors,
}) => {
  return async (dispatch) => {
    dispatch(checkPasswordStartedAction());
    try {
      let response = await checkPasswordAPI({ currentPassword });
      if (response.status === "success") {
        dispatch(checkPasswordSuccessAction());
        setState({ passwordIsCheck: true });
      } else {
        dispatch(checkPasswordFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(checkPasswordFailureAction(error.error_description));
      setErrors({ currentPassword: error.message });
    }
  };
};

export const checkPasswordStartedAction = () => ({
  type: CHECK_PASSWORD_STARTED,
});

export const checkPasswordSuccessAction = () => ({
  type: CHECK_PASSWORD_SUCCESS,
});

export const checkPasswordFailureAction = (message) => ({
  type: CHECK_PASSWORD_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const changePasswordRequestAction = ({
  currentPassword,
  newPassword,
  confirmNewPassword,
  setState,
}) => {
  return async (dispatch) => {
    dispatch(changePasswordStartedAction());
    try {
      let response = await changePasswordAPI({
        currentPassword,
        newPassword,
        confirmNewPassword,
      });
      if (response.status === "success") {
        dispatch(changePasswordSuccessAction());
        setState({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
          passwordIsCheck: false,
        });
        showSnackbar({
          type: "success",
          message: "رمز عبور با موفقیت تغییر یافت!",
        });
      } else {
        dispatch(changePasswordFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(changePasswordFailureAction(error.error_description));
    }
  };
};

export const changePasswordStartedAction = () => ({
  type: CHANGE_PASSWORD_STARTED,
});

export const changePasswordSuccessAction = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailureAction = (message) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getAccountEventsRequestAction = ({ limit, offset, setCount }) => {
  return async (dispatch) => {
    dispatch(getAccountEventsStartedAction());
    try {
      let response = await getAccountEventsAPI({ limit, offset });
      if (response.status === "success") {
        dispatch(
          getAccountEventsSuccessAction({ accountEvents: response.data.logs })
        );
        setCount(response.data.count);
      } else {
        dispatch(
          getAccountEventsFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getAccountEventsFailureAction(error.error_description));
    }
  };
};

export const getAccountEventsStartedAction = () => ({
  type: GET_ACCOUNT_EVENTS_STARTED,
});

export const getAccountEventsSuccessAction = (payload) => ({
  type: GET_ACCOUNT_EVENTS_SUCCESS,
  payload,
});

export const getAccountEventsFailureAction = (message) => ({
  type: GET_ACCOUNT_EVENTS_FAILURE,
  payload: message,
});
