import React, { Component } from "react";
import SnackbarManager from "./SnackbarManager";
import MuiSnackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { Box, Typography } from "@mui/material";
import {
  arcticMistColor,
  blushCoralColor,
  deepTealColor,
  fieryRedColor,
  forestTealColor,
  mintBreezeColor,
  silverSageColor,
  whiteColor,
} from "../../assets/colors";
import { CheckIcon, CloseIcon } from "../../assets/icons";
import { iranSansFaNum } from "../../utils/constants";

export default class Snackbar extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      Transition: SlideTransition,
      className: "message-bar",
      style: {},
      message: null,
      // color: accentText,
      // duration: 3000,
    };
    this.showSnackbar = this.showSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.showCalled = true;
    if (!this._id) this._id = srid();
  }

  componentDidMount() {
    SnackbarManager.register(this);
  }
  componentWillUnmount() {
    SnackbarManager.unregister(this);
  }

  showSnackbar(config) {
    this.showCalled = true;
    console.log("setState");
    this.setState({
      className: "message-bar show-message",
      open: true,
      ...config,
    });
  }

  closeSnackbar() {
    this.setState({
      open: false,
      className: "message-bar",
      style: {},
      // message: null,
      // color: accentText,
      // duration: 3000,
    });
  }

  render() {
    let { style, message, type, duration, className } = this.state;

    if (duration && typeof duration == "number" && this.showCalled) {
      setTimeout(() => {
        this.showCalled = false;
        this.closeSnackbar();
      }, duration);
    }

    let background = arcticMistColor;
    let color = null;
    let icon = null;
    let iconColor = null;
    switch (type) {
      case "danger":
        // background = blushCoralColor;
        color = fieryRedColor;
        iconColor = fieryRedColor;
        icon = (
          <Box
            sx={{
              width: 30,
              height: 30,
              background: iconColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              mr: "12px",
              border: `3px solid #FBDBDB`,
            }}
          >
            <CloseIcon color={whiteColor} width={20} height={20} />
          </Box>
        );
        break;
      // case "info":
      //   background = blackColor;
      //   break;
      case "success":
        // background = mintBreezeColor;
        color = deepTealColor;
        iconColor = forestTealColor;
        icon = (
          <Box
            sx={{
              width: 30,
              height: 30,
              background: iconColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              mr: "12px",
              border: `3px solid #B9ECDE`,
            }}
          >
            <CheckIcon color={whiteColor} width={20} height={20} />
          </Box>
        );
        break;
    }

    // return (
    //   <div
    //     style={{ ...styles.container, ...style, backgroundColor }}
    //     className={className}
    //   >
    //     <Text style={[styles.text, { color: color || accentText }]}>
    //       {message}
    //     </Text>
    //   </div>
    // );

    return (
      <MuiSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={this.state.open}
        onClose={this.closeSnackbar}
        TransitionComponent={this.state.Transition}
        key={this.state.Transition.name}
        autoHideDuration={5000}
        // sx={{
        //   "&.MuiSnackbar-root": {
        //     bottom: { xs: 20, lg: 50 },
        //     right: { xs: 20, lg: 50 },
        //   },
        // }}
      >
        <Box
          sx={{
            background,
            // p: 2,
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            border: `1px solid ${silverSageColor}`,
            height: 60,
            width: { xs: "100%", lg: 342 },
            px: "10px",
          }}
        >
          {icon}
          <Typography
            sx={{
              fontSize: 14,
              flex: 1,
              lineHeight: 1.8,
            }}
          >
            {this.state.message}
          </Typography>
        </Box>
      </MuiSnackbar>
    );
  }
}

export function showSnackbar(...args) {
  const ref = SnackbarManager.getDefault();
  if (!!ref) {
    ref.showSnackbar(...args);
  }
}

export function closeSnackbar(...args) {
  const ref = SnackbarManager.getDefault();
  if (!!ref) {
    ref.closeSnackbar();
  }
}

function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    zIndex: 1000,
  },
  text: {
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "right",
    writingDirection: "rtl",
    lineHeight: 25,
    paddingHorizontal: 20,
  },
};
