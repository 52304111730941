import React from "react";
import MuiAutocomplete from "@mui/material/Autocomplete";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  inputColor,
  graphiteGrayColor,
  aliceBlueColor,
  primaryColor,
  silverSageColor,
  errorColor,
} from "../../assets/colors";
import { ChevronLeftIcon } from "../../assets/icons";
import { dmSans, iranSans } from "../../utils/constants";
// import { ArrowDownIcon } from "../../assets/icons";
// --------------------------------------------------------------------------------
const CustomAutocomplete = ({
  name,
  label,
  value,
  options,
  getOptionLabel,
  renderOption,
  onChange,
  helperText,
  error,
  startAdornment,
  disabled,
  loading,
  placeholder,
  popupIcon = (
    <ChevronLeftIcon style={{ transform: "rotate(-90deg)" }} width={20} />
  ),
  hideHelperText,
  ...otherProps
}) => {
  const defaultHelperText = helperText || "\u200C";
  return (
    <MuiAutocomplete
      value={value}
      options={options}
      autoHighlight
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <FormControl variant="standard" fullWidth>
          <InputLabel
            shrink
            sx={{
              fontSize: 14,
              color: graphiteGrayColor,
              "&.Mui-focused": { color: "unset" },
              transform: "none",
              fontWeight: 600,
            }}
          >
            {label}
          </InputLabel>
          <BootstrapInput
            {...params}
            name={name}
            label={label}
            InputProps={{
              // ...params.InputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            error={error}
            InputLabelProps={{ shrink: "true" }}
            ref={params.InputProps.ref}
            startAdornment={
              <InputAdornment>
                {loading ? <CircularProgress size={20} /> : startAdornment}
              </InputAdornment>
            }
            endAdornment={
              otherProps.endAdornment
                ? otherProps.endAdornment
                : params.InputProps.endAdornment
            }
            placeholder={placeholder}
          />
          {!hideHelperText && (
            <FormHelperText error={error}>{defaultHelperText}</FormHelperText>
          )}
        </FormControl>
      )}
      onChange={onChange}
      disableClearable
      disabled={disabled}
      popupIcon={popupIcon}
      sx={{ ".MuiAutocomplete-endAdornment": { mr: 1.5 } }}
      componentsProps={{
        paper: {
          sx: {
            boxShadow: "0px 54px 25.6px -20px #0000001A",
            border: `1px solid ${silverSageColor}`,
          },
        },
      }}
      {...otherProps}
    />
  );
};

export default CustomAutocomplete;

export const BootstrapInput = styled(InputBase)(
  ({ theme, startAdornment, endAdornment, label }) => ({
    "label + &": {
      marginTop: label ? theme.spacing(3.8) : 0,
      transform: "none",
    },
    "&.MuiInputBase-root": {
      // backgroundColor: aliceBlueColor,
      border: `1px solid ${aliceBlueColor}`,
      borderRadius: 8,
      transition: theme.transitions.create(["border-color"]),
      // flexWrap: "wrap",
      "&.Mui-focused": {
        borderColor: primaryColor,
        boxShadow: "0px 0px 0px 2px rgba(65, 173, 206, 0.11)",
      },
      "&.Mui-error": {
        borderColor: errorColor,
        boxShadow: "none",
      },
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      fontSize: 16,
      padding: "15px 12px",
      "&::placeholder": {
        fontSize: 12,
      },
      fontFamily: [dmSans, iranSans].join(","),
      // Use the system font instead of the default Roboto font.
      // fontFamily: [
      //   "-apple-system",
      //   "BlinkMacSystemFont",
      //   '"Segoe UI"',
      //   "Roboto",
      //   '"Helvetica Neue"',
      //   "Arial",
      //   "sans-serif",
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"',
      // ].join(","),
    },
  })
);
