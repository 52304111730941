import { Box, Chip, Typography } from "@mui/material";
import CustomTextInput from "../../../components/CustomTextInput";
import { AccountContext } from "../../../context/AccountContext";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../../../utils/helpers";
import {
  sendEmailTokenRequestAction,
  verifyEmailTokenRequestAction,
} from "../../../redux/actions/userActions";
import { InfoIcon } from "../../../assets/icons";
import { mistyGreenColor } from "../../../assets/colors";
import Button from "../../../components/Button";
import CustomOtpInput from "../../../components/CustomOtpInput";

const StepFirst = () => {
  const dispatch = useDispatch();

  const { userCompany } = useSelector((state) => state.user);
  const { state, setState } = useContext(AccountContext);

  const handleNext = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (state.name === "") errorObj.name = "لطفا نام شرکت را وارد کنید.";
    if (state.registrationNumber === "")
      errorObj.registrationNumber = "لطفا شناسه ملی شرکت را وارد کنید.";
    if (state.email === "") errorObj.email = "لطفا ایمیل شرکت را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;
    else if (userCompany.email === null && !state.showTokenInput)
      handleSendCode();
    else if (userCompany.email !== state.email)
      dispatch(
        verifyEmailTokenRequestAction({
          token: state.token,
          setState,
        })
      );
    else setState({ activeStep: 1 });
  };

  const handleSendCode = () => {
    const errorObj = {};

    if (state.email === "") errorObj.email = "لطفا ایمیل شرکت را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      sendEmailTokenRequestAction({
        email: state.email,
        setState,
      })
    );
  };

  return (
    <>
      <CustomTextInput
        label="نام شرکت"
        placeholder="مثلا: ایران پارس"
        value={state.name}
        onChange={(e) => setState({ name: e.target.value })}
        helperText={state.errors.name}
        error={state.errors.name}
      />
      <CustomTextInput
        label="شناسه ملی شرکت"
        placeholder="مثلا: 123456789"
        value={state.registrationNumber}
        onChange={(e) => setState({ registrationNumber: e.target.value })}
        helperText={state.errors.registrationNumber}
        error={state.errors.registrationNumber}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomTextInput
          label="ایمیل شرکت"
          placeholder="etc@info.ir :مثلا"
          value={state.email}
          onChange={(e) => setState({ email: e.target.value })}
          helperText={state.errors.email}
          error={state.errors.email}
        />
        <Chip
          label={
            state.counter > 0
              ? `ارسال مجدد ${formatTime(state.counter)}`
              : "ارسال کد تایید"
          }
          onClick={handleSendCode}
          sx={{ mt: 1, ml: 1 }}
          color="emeraldOasis"
          disabled={state.counter > 0}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <InfoIcon
          width={20}
          height={20}
          style={{ marginLeft: 4 }}
          color={mistyGreenColor}
        />
        <Typography sx={{ fontSize: 12, color: mistyGreenColor }}>
          ایمیل شرکت نیاز به تایید دارد
        </Typography>
      </Box>
      <Box>
        <CustomOtpInput
          label="ورود کد تایید"
          length={5}
          value={state.token}
          onComplete={(value) => setState({ token: value })}
          containerProps={{
            sx: {
              label: { fontSize: 14 },
              mb: 12,
              visibility: state.showTokenInput ? "visible" : "hidden",
              opacity: state.showTokenInput ? 1 : 0,
              transition: "1s all",
              "& .MuiInputBase-root": { mr: 1 },
            },
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          text="مرحله بعد"
          fullWidth={false}
          sx={{ borderRadius: 100, width: 181 }}
          onClick={handleNext}
          // disabled={
          //   (userCompany.email && userCompany.email !== state.email) ||
          //   !state.showTokenInput
          // }
        />
      </Box>
    </>
  );
};

export default StepFirst;
