import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getGeneralSettingAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "settings/show",
  });
};
export const updateGeneralSettingAPI = ({
  otp,
  automaticRenewal,
  emailNotification,
}) => {
  let postData = {
    otp,
    renew: automaticRenewal,
    email_notification: emailNotification,
  };
  return axiosMiddleware({
    method: "post",
    url: "settings/edit",
    data: postData,
  });
};
export const checkPasswordAPI = ({ currentPassword }) => {
  let postData = {
    password: currentPassword,
  };
  return axiosMiddleware({
    method: "post",
    url: "settings/check-password",
    data: postData,
  });
};
export const changePasswordAPI = ({
  currentPassword,
  newPassword,
  confirmNewPassword,
}) => {
  let postData = {
    current_password: currentPassword,
    new_password: newPassword,
    confirm_new_password: confirmNewPassword,
  };
  return axiosMiddleware({
    method: "post",
    url: "settings/change-password",
    data: postData,
  });
};
export const getAccountEventsAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `settings/logs?limit=${limit}&offset=${offset}`,
  });
};
