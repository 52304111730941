import { applyMiddleware, combineReducers, compose, createStore } from "redux";
// import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import authReducer from "./reducers/authReducer";
// import walletReducer from "./reducers/walletReducer";
// import { CardReducer } from "./reducers/CardReducer";
// import GeneralReducer from "./reducers/GeneralReducer";
// import todoReducers from "./reducers/Reducers";
//import { reducer as reduxFormReducer } from 'redux-form';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import settingReducer from "./reducers/settingReducer";
import userReducer from "./reducers/userReducer";
import ticketReducer from "./reducers/ticketReducer";
import transactionReducer from "./reducers/transactionReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import productReducer from "./reducers/productReducer";
import paylinkReducer from "./reducers/paylinkReducer";
import webgateReducer from "./reducers/webgateReducer";
import virtualAccountsReducer from "./reducers/virtualAccountsReducer";
import configReducer from "./reducers/configReducer";
// --------------------------------------------------------------------------------
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  // posts: PostsReducer,
  auth: authReducer,
  user: userReducer,
  setting: settingReducer,
  ticket: ticketReducer,
  transaction: transactionReducer,
  dashboard: dashboardReducer,
  product: productReducer,
  paylink: paylinkReducer,
  webgate: webgateReducer,
  virtualAccounts: virtualAccountsReducer,
  config: configReducer,
});

const persistConfig = {
  timeout: 0,
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: ["auth"],
  transforms: [createWhitelistFilter("auth", ["token"])],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  composeEnhancers(middleware)
);

export const persistor = persistStore(store);
