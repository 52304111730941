import { useEffect, useState } from "react";
import Item from "./Item";
import { useDispatch, useSelector } from "react-redux";
import { getPersonalPaylinksRequestAction } from "../../../redux/actions/paylinkAction";

const PaylinksList = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);

  const { personalPaylinks } = useSelector((state) => state.paylink);

  useEffect(() => {
    dispatch(getPersonalPaylinksRequestAction());
  }, []);

  return personalPaylinks.map((paylink) => <Item paylink={paylink} />);
};

export default PaylinksList;
