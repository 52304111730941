import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  Button as MuiButton,
  Chip,
  DialogTitle,
} from "@mui/material";
import {
  blackColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import { CloseIcon, EditIcon, MapPinIcon } from "../../assets/icons";
import Button from "../../components/Button";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../../components/CustomTextInput";
import { createElement, useCallback, useEffect, useRef, useState } from "react";
import { appBarHeight, drawerWidth } from "../../utils/constants";
import {
  CREATE_PRODUCT_STARTED,
  EDIT_USER_ADDRESS_STARTED,
} from "../../redux/actionTypes";
import { editUserAddressRequestAction } from "../../redux/actions/userActions";
import { statusObj } from "../Webgate/constants";

const Address = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { rType, user } = useSelector((state) => state.auth);

  const initialState = {
    state: "",
    city: "",
    postalCode: "",
    address: "",
    errors: {},
  };
  const [state, setInitState] = useState(initialState);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const renderEditAddress = () => {
    return (
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderRadius: 5,
            boxShadow: "0px 0px 77px 20px #5F5F5F08;",
            width: 488,
          },
        }}
        BackdropProps={{
          sx: {
            background: "rgba(218, 218, 218, 0.21)",
            backdropFilter: " blur(9px)",
            left: drawerWidth,
            top: appBarHeight,
            borderLeft: "1px solid #E7EFEC",
          },
        }}
        sx={{ left: drawerWidth, top: appBarHeight }}
        onClose={() => setOpen(false)}
        scroll="body"
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon color={whiteColor} width={18} height={18} />
        </IconButton>
        <DialogTitle
          sx={{ backgroundColor: blackColor, fontSize: 16, color: whiteColor }}
        >
          ویرایش اطلاعات کاربری
        </DialogTitle>
        <DialogContent dividers sx={{ borderBottom: "none", pb: 0 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 2 }}>
            <Box sx={{ flex: "48%" }}>
              <CustomTextInput
                label="استان"
                // error={errors.title}
                // helperText={errors.title}
                onChange={(e) =>
                  setState({
                    state: e.target.value,
                    // errors: { ...errors, title: "" },
                  })
                }
                value={state.state}
              />
            </Box>
            <Box sx={{ flex: "48%" }}>
              <CustomTextInput
                label="شهر"
                // error={errors.title}
                // helperText={errors.title}
                onChange={(e) =>
                  setState({
                    city: e.target.value,
                    // errors: { ...errors, title: "" },
                  })
                }
                value={state.city}
              />
            </Box>
            <Box sx={{ flex: "100%" }}>
              <CustomTextInput
                label="کد پستی"
                // error={errors.title}
                // helperText={errors.title}
                onChange={(e) =>
                  setState({
                    postalCode: e.target.value,
                    // errors: { ...errors, postalCode: "" },
                  })
                }
                value={state.postalCode}
                maxLength={10}
              />
            </Box>
            <Box sx={{ flex: "100%" }}>
              <CustomTextInput
                label="آدرس"
                // error={errors.title}
                // helperText={errors.title}
                onChange={(e) =>
                  setState({
                    address: e.target.value,
                    // errors: { ...errors, title: "" },
                  })
                }
                value={state.address}
                row={4}
                multiline
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: "24px", pb: "24px" }}>
          <Button
            text="ذخیره"
            onClick={() =>
              dispatch(
                editUserAddressRequestAction({
                  address: state.address,
                  city: state.city,
                  postalCode: state.postalCode,
                  state: state.state,
                  setOpen,
                })
              )
            }
            loading={rType === EDIT_USER_ADDRESS_STARTED}
            disabled={
              state.address.trim() === "" ||
              state.city.trim() === "" ||
              state.postalCode.trim() === "" ||
              state.state.trim() === ""
            }
          />
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${silverSageColor}`,
          borderRadius: "15px",
          p: 1.5,

          flex: 0.5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <MapPinIcon
            color={primaryColor}
            style={{ marginLeft: 5, marginBottom: 5 }}
          />
          <Typography sx={{ fontWeight: 700 }}>اطلاعات آدرس</Typography>
          {user.user_address_status && (
            <Chip
              label={statusObj[user.user_address_status].label}
              color={statusObj[user.user_address_status].color}
              sx={{ ml: "8px", height: 20, fontSize: 12, fontWeight: 500 }}
              icon={
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette[statusObj[user.user_address_status].color]
                        .contrastText,
                    height: 4,
                    width: 4,
                    borderRadius: 100,
                  }}
                ></Box>
              }
            />
          )}
          <Button
            variant="outlined"
            text="ویرایش"
            fullWidth={false}
            sx={{
              ml: "auto",
              color: slateGrayColor,
              borderRadius: "47px",
            }}
            startIcon={<EditIcon width={18} height={18} />}
            color="silverSage"
            onClick={() => {
              setOpen(true);
              setState({
                state: user.state,
                city: user.city,
                address: user.address,
                postalCode: user.postal_code,
              });
            }}
            disabled={user.user_address_status === "Pending"}
          />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, rowGap: 4 }}>
          <Box sx={{ flex: "1 1 20%" }}>
            <Typography sx={{ fontSize: 12 }}>کشور</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              {user.country}
            </Typography>
          </Box>
          <Box sx={{ flex: "1 1 20%" }}>
            <Typography sx={{ fontSize: 12 }}>استان</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              {user.state}
            </Typography>
          </Box>
          <Box sx={{ flex: "1 1 20%" }}>
            <Typography sx={{ fontSize: 12 }}>شهر</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              {user.city}
            </Typography>
          </Box>
          <Box sx={{ flex: "1 1 20%" }}>
            <Typography sx={{ fontSize: 12 }}>کد پستی</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              {user.postal_code}
            </Typography>
          </Box>
          <Box sx={{ flex: "1 1 100%" }}>
            <Typography sx={{ fontSize: 12 }}>آدرس</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14, lineHeight: 2 }}>
              {user.address}
            </Typography>
          </Box>
        </Box>
      </Box>
      {renderEditAddress()}
    </>
  );
};

export default Address;
