import Dashboard from "./Dashboard";
import TransactionsList from "./TransactionsList";
import Webgate from "./Webgate";
import CreateWebgate from "./Webgate/CreateWebgate";
import TransactionsMap from "./TransactionsMap";
import Tickets from "./Tickets";
import Account from "./Account";
import Paylink from "./Paylink/CreatePaylink";
import PL from "./Paylink";
import Onboarding from "./Onboarding";
import ProductsList from "./Paylink/ProductsList";
import Welcome from "./Onboarding/Welcome";
import Kyc from "./Onboarding/Kyc";
import VirtualAccounts from "./VirtualAccounts";
import Exchange from "./VirtualAccounts/Exchange";
import Payout from "./VirtualAccounts/Payout";
import EmailConfirmation from "./EmailConfirmation";
import { Navigate } from "react-router-dom";
import { PaylinkProvider } from "../context/PyalinkContext";
import { AccountProvider } from "../context/AccountContext";
// --------------------------------------------------------------------------------
export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";
export const ONBOARDING = "/onboarding";
export const VIRTUAL_ACCOUNTS = "/virtual-accounts";
export const PAYOUT = `${VIRTUAL_ACCOUNTS}/payout`;
export const EXCHANGE = `${VIRTUAL_ACCOUNTS}/exchange/:currencyCode`;
export const TRANSACTIONS_LIST = "/transactions-list";
export const TRANSACTIONS_MAP = "/transactions-map";
export const TICKETS = "/tickets";
export const TICKETS_TAB = `${TICKETS}/:tab`;
export const SELECT_TICKET = `${TICKETS_TAB}/:ticketId`;
export const ACCOUNT = "/account";
export const ACCOUNT_TAB = `${ACCOUNT}/:tab`;
export const WEBGATE = "/webgate";
export const WEBGATE_CREATE = `${WEBGATE}/create`;
export const PAYLINK = "/paylink";
export const PAYLINK_CREATE = `${PAYLINK}/create`;
export const PAYLINKS_LIST = `${PAYLINK}/paylinks-list`;
export const PRODUCTS_LIST = `${PAYLINK}/products-list`;
export const EMAIL_CONFIRMATION = "/email-confirmation";
export const WELCOME = "/welcome";

export const routes = [
  {
    url: "*",
    component: <Navigate to={DASHBOARD} />,
    name: null,
  },
  {
    url: ONBOARDING,
    component: <Onboarding />,
    name: null,
  },
  {
    url: DASHBOARD,
    component: <Dashboard />,
    name: "داشبورد",
  },
  {
    url: VIRTUAL_ACCOUNTS,
    component: <VirtualAccounts />,
    name: "حساب های مجازی",
  },
  {
    url: PAYOUT,
    component: <Payout />,
    name: "حساب های مجازی",
  },
  {
    url: EXCHANGE,
    component: <Exchange />,
    name: "حساب های مجازی",
  },
  {
    url: TRANSACTIONS_LIST,
    component: <TransactionsList />,
    name: "لیست تراکنش ها",
  },
  {
    url: TRANSACTIONS_MAP,
    component: <TransactionsMap />,
    name: "نقشه تراکنش ها",
  },
  {
    url: TICKETS,
    component: <Tickets />,
    name: "تیکت ها",
  },
  {
    url: TICKETS_TAB,
    component: <Tickets />,
    name: "تیکت ها",
  },
  {
    url: SELECT_TICKET,
    component: <Tickets />,
    name: "تیکت ها",
  },
  {
    url: ACCOUNT,
    component: (
      <AccountProvider>
        <Account />
      </AccountProvider>
    ),
    name: "تنظیمات حساب کاربری",
  },
  {
    url: ACCOUNT_TAB,
    component: (
      <AccountProvider>
        <Account />
      </AccountProvider>
    ),
    name: "تنظیمات حساب کاربری",
  },
  {
    url: WEBGATE,
    component: <Webgate />,
    name: "وب گیت های من",
  },
  {
    url: WEBGATE_CREATE,
    component: <CreateWebgate />,
    name: "ساخت وب گیت جدید",
  },
  {
    url: PAYLINK_CREATE,
    component: (
      <PaylinkProvider>
        <Paylink />
      </PaylinkProvider>
    ),
    name: "ساخت پی لینک جدید",
  },
  {
    url: PAYLINKS_LIST,
    component: <PL />,
    name: "پی لینک های من",
  },
  {
    url: PRODUCTS_LIST,
    component: <ProductsList />,
    name: "لیست محصولات ",
  },
  {
    url: EMAIL_CONFIRMATION,
    component: <EmailConfirmation />,
    name: "تایید حساب کاربری",
  },
];

export const onboardingRoute = [
  {
    url: "*",
    component: <Navigate to={WELCOME} />,
    name: null,
  },
  {
    url: WELCOME,
    component: <Welcome />,
    name: "داشبورد",
  },
  {
    url: ONBOARDING,
    component: <Onboarding />,
    name: null,
  },
];

export const kycRoute = [
  {
    url: "kyc",
    component: <Kyc />,
    name: null,
  },
  {
    url: "*",
    component: <Navigate to="kyc" />,
    name: null,
  },
];

export const getRouteName = (routes, pathname) => {
  const matchedRoute = routes.find((route) => {
    if (route.url === "*") return false;

    const routePath = route.url.replace(/:\w+/g, "[^/]+");
    return new RegExp(`^${routePath}$`).test(pathname);
  });

  // Return the name of the matched route or null if no match
  return matchedRoute ? matchedRoute.name : null;
};
