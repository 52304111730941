import {
  CREATE_UID_LINK_FAILURE,
  CREATE_UID_LINK_STARTED,
  CREATE_UID_LINK_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_STARTED,
  DELETE_ACCOUNT_SUCCESS,
  FORGOT_EMAIL_FAILURE,
  FORGOT_EMAIL_STARTED,
  FORGOT_EMAIL_SUCCESS,
  FORGOT_OTP_FAILURE,
  FORGOT_OTP_STARTED,
  FORGOT_OTP_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_STARTED,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_STARTED,
  REGISTER_USER_SUCCESS,
  SIGNIN_DEFAULT,
  SIGNIN_FAILURE,
  SIGNIN_STARTED,
  SIGNIN_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_MOBILE_CODE_FAILURE,
  VERIFY_MOBILE_CODE_STARTED,
  VERIFY_MOBILE_CODE_SUCCESS,
  VERIFY_STARTED,
  VERIFY_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: SIGNIN_DEFAULT,
  token: "",
  user: {},
  error: "",
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_STARTED:
    case SIGNIN_STARTED:
    case LOGOUT_STARTED:
    case VERIFY_STARTED:
    case GET_USER_PROFILE_STARTED:
    case CREATE_UID_LINK_STARTED:
    case FORGOT_EMAIL_STARTED:
    case FORGOT_OTP_STARTED:
    case FORGOT_PASSWORD_STARTED:
    case VERIFY_MOBILE_CODE_STARTED:
    case DELETE_ACCOUNT_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        rType: SIGNIN_SUCCESS,
        token: action.payload.token,
        user: action.payload.user,
        error: "",
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        rType: VERIFY_SUCCESS,
        token: action.payload.token,
        user: action.payload.user,
        error: "",
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        rType: GET_USER_PROFILE_SUCCESS,
        user: action.payload.user,
        error: "",
      };
    case REGISTER_USER_SUCCESS:
    case CREATE_UID_LINK_SUCCESS:
    case FORGOT_EMAIL_SUCCESS:
    case FORGOT_OTP_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case VERIFY_MOBILE_CODE_SUCCESS:
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case REGISTER_USER_FAILURE:
    case SIGNIN_FAILURE:
    case LOGOUT_FAILURE:
    case VERIFY_FAILURE:
    case GET_USER_PROFILE_FAILURE:
    case CREATE_UID_LINK_FAILURE:
    case FORGOT_EMAIL_FAILURE:
    case FORGOT_OTP_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case VERIFY_MOBILE_CODE_FAILURE:
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
