import {
  CHECK_EXCHANGE_FAILURE,
  CHECK_EXCHANGE_STARTED,
  CHECK_EXCHANGE_SUCCESS,
  CONFIRM_IRR_REMITTANCE_FAILURE,
  CONFIRM_IRR_REMITTANCE_STARTED,
  CONFIRM_IRR_REMITTANCE_SUCCESS,
  CREATE_EXCHANGE_FAILURE,
  CREATE_EXCHANGE_STARTED,
  CREATE_EXCHANGE_SUCCESS,
  GET_EXCHANGE_RATES_FAILURE,
  GET_EXCHANGE_RATES_STARTED,
  GET_EXCHANGE_RATES_SUCCESS,
  GET_VIRTUAL_ACCOUNTS_FAILURE,
  GET_VIRTUAL_ACCOUNTS_STARTED,
  GET_VIRTUAL_ACCOUNTS_SUCCESS,
  SIGNIN_DEFAULT,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: SIGNIN_DEFAULT,
  virtualAccounts: [],
  exchangeRates: [],
  error: "",
};
const virtualAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIRTUAL_ACCOUNTS_STARTED:
    case GET_EXCHANGE_RATES_STARTED:
    case CONFIRM_IRR_REMITTANCE_STARTED:
    case CHECK_EXCHANGE_STARTED:
    case CREATE_EXCHANGE_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_VIRTUAL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        virtualAccounts: action.payload.virtualAccounts.normal_wallets,
        rType: GET_VIRTUAL_ACCOUNTS_SUCCESS,
        error: "",
      };
    case GET_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        exchangeRates: action.payload.exchangeRates,
        rType: GET_EXCHANGE_RATES_SUCCESS,
        error: "",
      };
    case CONFIRM_IRR_REMITTANCE_SUCCESS:
      return {
        ...state,
        rType: CONFIRM_IRR_REMITTANCE_SUCCESS,
        error: "",
      };
    case CHECK_EXCHANGE_SUCCESS:
      return {
        ...state,
        rType: CHECK_EXCHANGE_SUCCESS,
        error: "",
      };
    case CREATE_EXCHANGE_SUCCESS:
      return {
        ...state,
        rType: CREATE_EXCHANGE_SUCCESS,
        error: "",
      };
    case GET_VIRTUAL_ACCOUNTS_FAILURE:
    case GET_EXCHANGE_RATES_FAILURE:
    case CONFIRM_IRR_REMITTANCE_FAILURE:
    case CHECK_EXCHANGE_FAILURE:
    case CREATE_EXCHANGE_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default virtualAccountsReducer;
