import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_PASSWORD_FAILURE,
  CHECK_PASSWORD_STARTED,
  CHECK_PASSWORD_SUCCESS,
  GET_ACCOUNT_EVENTS_FAILURE,
  GET_ACCOUNT_EVENTS_STARTED,
  GET_ACCOUNT_EVENTS_SUCCESS,
  GET_GENERAL_SETTING_DEFAULT,
  GET_GENERAL_SETTING_FAILURE,
  GET_GENERAL_SETTING_STARTED,
  GET_GENERAL_SETTING_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_GENERAL_SETTING_DEFAULT,
  otp: false,
  automaticRenewal: false,
  emailNotification: false,
  accountEvents: [],
  error: "",
};
const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_SETTING_STARTED:
    case CHECK_PASSWORD_STARTED:
    case CHANGE_PASSWORD_STARTED:
    case GET_ACCOUNT_EVENTS_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_GENERAL_SETTING_SUCCESS:
      return {
        ...state,
        rType: GET_GENERAL_SETTING_SUCCESS,
        otp: action.payload.otp,
        automaticRenewal: action.payload.renew,
        emailNotification: action.payload.email_notification,
        error: "",
      };
    case GET_ACCOUNT_EVENTS_SUCCESS:
      return {
        ...state,
        rType: GET_ACCOUNT_EVENTS_SUCCESS,
        accountEvents: action.payload.accountEvents,
        error: "",
      };
    case CHECK_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        rType: CHANGE_PASSWORD_SUCCESS,
        error: "",
      };
    case GET_GENERAL_SETTING_FAILURE:
    case CHECK_PASSWORD_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case GET_ACCOUNT_EVENTS_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default settingReducer;
