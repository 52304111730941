import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getVirtualAccountsAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "wallet/all2",
  });
};
export const getExchangeRatesAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "rates",
  });
};
export const checkIrrRemittanceAPI = ({ accountId, amount, source }) => {
  let postData = {
    account_id: accountId,
    amount,
    source,
    // description: null,
  };
  return axiosMiddleware({
    method: "post",
    url: "payout/irr-remittance-check",
    data: postData,
  });
};
export const confirmIrrRemittanceAPI = ({ accountId, amount, source }) => {
  let postData = {
    account_id: accountId,
    amount,
    source,
    // description: null,
  };
  return axiosMiddleware({
    method: "post",
    url: "payout/irr-remittance-confirm",
    data: postData,
  });
};
export const checkExchangeAPI = ({ amount, fromCurrencyId, toCurrencyId }) => {
  let postData = {
    amount,
    from_currency_id: fromCurrencyId,
    to_currency_id: toCurrencyId,
  };
  return axiosMiddleware({
    method: "post",
    url: "exchange/check",
    data: postData,
  });
};
export const createExchangeAPI = ({ amount, fromCurrencyId, toCurrencyId }) => {
  let postData = {
    amount,
    from_currency_id: 2,
    to_currency_id: 3,
  };
  return axiosMiddleware({
    method: "post",
    url: "exchange/create",
    data: postData,
  });
};
