import { Avatar, Badge, Box, Divider, Typography } from "@mui/material";
import {
  arcticMistColor,
  brightCrimson,
  deepBlue,
  fireEngineRedColor,
  forestShadowColor,
  lightSilver,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  steelBlueColor,
  whiteColor,
} from "../../assets/colors";
import Button from "../../components/Button";
import { ArrowDownIcon2, EuroIcon, LiraIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getExchangeRatesRequestAction,
  getVirtualAccountsRequestAction,
} from "../../redux/actions/virtualAccountsActions";
import { addBalanceSeparator, numberWithCommas } from "../../utils/helpers";
import moment from "moment-jalaali";
import { getTransactionsListRequestAction } from "../../redux/actions/transactionActions";
// --------------------------------------------------------------------------------
const VirtualAccounts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    virtualAccounts: { virtualAccounts, exchangeRates },
    transaction: { transactionsList },
  } = useSelector((state) => ({
    virtualAccounts: state.virtualAccounts,
    transaction: state.transaction,
  }));
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "month"),
    moment().add(1, "day"),
  ]);
  const irrWallet = virtualAccounts.find((v) => v.currency_id === 3);
  const euroWallet = virtualAccounts.find((v) => v.currency_id === 2);
  const liraWallet = virtualAccounts.find((v) => v.currency_id === 18);

  useEffect(() => {
    dispatch(getVirtualAccountsRequestAction());
    dispatch(getExchangeRatesRequestAction());
    dispatch(
      getTransactionsListRequestAction({
        from: dateRange[0].toISOString(),
        to: dateRange[1].toISOString(),
        offset: 1,
        limit: 10,
        eventId: 0,
      })
    );
  }, []);

  return (
    <Box
      sx={{
        flex: 1,
        display: { lg: "flex" },
        justifyContent: "center",
        flexWrap: "wrap",
        gap: { lg: 2 },
        px: "15px",
        py: "24px",
      }}
    >
      <Box
        sx={{
          width: { lg: 370 },
          backgroundColor: whiteColor,
          pl: { xs: "8px", lg: "18px" },
          pr: "18px",
          py: "19px",
          borderRadius: "20px",
          mb: { xs: "28px", lg: 0 },
        }}
      >
        <Typography sx={{ fontWeight: 500, mb: 1 }}>لیست حساب ها</Typography>
        {/* <Typography sx={{ fontSize: 12, color: slateGrayColor, mb: 2.5 }}>
          توضیح خلاصه در رابطه با این بخش نوشته شود.
        </Typography> */}
        <Box
          sx={{
            backgroundColor: arcticMistColor,
            borderRadius: "12px",
            px: "19px",
            py: "10px",
            mb: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "26px",
            }}
          >
            <Avatar
              src="https://flagcdn.com/ir.svg"
              sx={{ width: 39, height: 39 }}
            />
            <Typography sx={{ fontWeight: 600, fontSize: 14, ml: 1 }}>
              حساب ریالی
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: 12, color: slateGrayColor }}>
              موجودی
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
              <Typography
                sx={{
                  color: primaryColor,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                ریالءء
              </Typography>{" "}
              {irrWallet?.balance}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: 12, color: slateGrayColor }}>
              در انتظار تایید
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
              <Typography
                sx={{
                  color: primaryColor,
                  display: "inline-block",
                  fontSize: 12,
                }}
              >
                ریالءء
              </Typography>{" "}
              {irrWallet?.pending_balance}
            </Typography>
          </Box>
          <Button
            text="برداشت از حساب"
            color="transparentTeal"
            onClick={() => navigate("payout")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: "26px",
          }}
        >
          <Box
            sx={{
              width: 40,
              height: 40,
              backgroundColor: deepBlue,
              borderRadius: 100,
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
            }}
          >
            <EuroIcon
              width={33}
              height={33}
              color={whiteColor}
              style={{ marginRight: 3, marginTop: 2.5 }}
            />
          </Box>
          <Typography sx={{ fontWeight: 600, fontSize: 14, ml: 1 }}>
            حساب یورو اروپا
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box>
            <Typography sx={{ fontSize: 12, color: slateGrayColor, mb: 1 }}>
              موجودی
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {euroWallet?.balance}
              </Typography>
              <EuroIcon width={18} height={18} color={forestShadowColor} />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 12, color: slateGrayColor, mb: 1 }}>
              در انتظار تایید
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {euroWallet?.pending_balance}
              </Typography>
              <EuroIcon width={18} height={18} color={forestShadowColor} />
            </Box>
          </Box>
          <Button
            text="تبدیل به ریال"
            color="transparentTeal"
            fullWidth={false}
            sx={{ p: 1.5, fontSize: 12 }}
            onClick={() => navigate("exchange/EUR")}
          />
        </Box>
        <Divider sx={{ borderColor: silverSageColor, mb: "20px" }} />
        {liraWallet ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: "26px",
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: brightCrimson,
                  borderRadius: 100,
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                }}
              >
                <LiraIcon
                  width={33}
                  height={33}
                  color={whiteColor}
                  style={{ marginRight: 2, marginTop: 4 }}
                />
              </Box>
              <Typography sx={{ fontWeight: 600, fontSize: 14, ml: 1 }}>
                حساب لیر ترکیه
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 12, color: slateGrayColor, mb: 1 }}>
                  موجودی
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    {liraWallet.pending_balance}
                  </Typography>
                  <LiraIcon width={18} height={18} color={forestShadowColor} />
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 12, color: slateGrayColor, mb: 1 }}>
                  در انتظار تایید
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    {liraWallet.pending_balance}
                  </Typography>
                  <LiraIcon width={18} height={18} color={forestShadowColor} />
                </Box>
              </Box>
              <Button
                text="تبدیل به ریال"
                color="transparentTeal"
                fullWidth={false}
                sx={{ p: 1.5, fontSize: 12 }}
                onClick={() => navigate("exchange/TRY")}
              />
            </Box>
          </>
        ) : null}
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            height: 57,
            display: { xs: "none", lg: "flex" },
            alignItems: "center",
            px: "27px",
            borderRadius: "15px",
            mb: "13px",
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>نرخ تبدیل ارز ها</Typography>
          <Box sx={{ display: "flex", alignItems: "center", mx: 3 }}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Avatar
                  src="https://flagcdn.com/ir.svg"
                  sx={{ width: 20, height: 20 }}
                />
              }
              sx={{ "& .MuiBadge-badge": { bottom: 0 } }}
            >
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: deepBlue,
                  borderRadius: 100,
                }}
              >
                <EuroIcon
                  width={20}
                  height={20}
                  color={whiteColor}
                  style={{ marginRight: 5, marginTop: 4 }}
                />
              </Box>
            </Badge>
            <Typography sx={{ ml: 2, fontWeight: 500, fontSize: 14 }}>
              تبدیل به ریال
            </Typography>
            <Typography
              sx={{
                ml: 2,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {exchangeRates[0] && addBalanceSeparator(exchangeRates[0]?.buy)}{" "}
              <Typography
                sx={{
                  display: "inline-block",
                  color: primaryColor,
                  fontSize: 12,
                }}
              >
                ریالءء
              </Typography>
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ borderColor: lightSilver }}
          />
          <Box sx={{ display: "flex", alignItems: "center", ml: 3 }}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Avatar
                  src="https://flagcdn.com/ir.svg"
                  sx={{ width: 20, height: 20 }}
                />
              }
              sx={{ "& .MuiBadge-badge": { bottom: 0 } }}
            >
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: brightCrimson,
                  borderRadius: 100,
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                }}
              >
                <LiraIcon
                  width={20}
                  height={20}
                  color={whiteColor}
                  style={{ marginRight: 5, marginTop: 4 }}
                />
              </Box>
            </Badge>
            <Typography sx={{ ml: 2, fontWeight: 500, fontSize: 14 }}>
              تبدیل به ریال
            </Typography>
            <Typography
              sx={{
                ml: 2,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {exchangeRates[8] && exchangeRates[8]?.buy}{" "}
              <Typography
                sx={{
                  display: "inline-block",
                  color: primaryColor,
                  fontSize: 12,
                }}
              >
                ریالءء
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            pl: { xs: "8px", lg: "28px" },
            pr: { xs: "8px", lg: "16px" },
            py: "19px",
            borderRadius: "20px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontWeight: 500, mb: "20px" }}>
            تراکنش های اخیر
          </Typography>
          {transactionsList.map((tr, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                height: { xs: 93, lg: 64 },
                px: "11px",
                justifyContent: "space-between",
                ":nth-child(even)": {
                  backgroundColor: arcticMistColor,
                  borderRadius: "12px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: { xs: "100%", lg: "none" },
                }}
              >
                {/* <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Avatar
                      src="https://flagcdn.com/ir.svg"
                      sx={{ width: 20, height: 20 }}
                    />
                  }
                  sx={{ "& .MuiBadge-badge": { bottom: 0 } }}
                >
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: brightCrimson,
                      borderRadius: 100,
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                    }}
                  >
                    <LiraIcon
                      width={20}
                      height={20}
                      color={whiteColor}
                      style={{ marginRight: 5, marginTop: 4 }}
                    />
                  </Box>
                </Badge> */}
                {tr.currency_id === 3 ? (
                  <Avatar
                    src="https://flagcdn.com/ir.svg"
                    sx={{ width: 30, height: 30 }}
                  />
                ) : tr.currency_id === 2 ? (
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: deepBlue,
                      borderRadius: 100,
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                    }}
                  >
                    <EuroIcon
                      width={20}
                      height={20}
                      color={whiteColor}
                      style={{ marginRight: 5, marginTop: 4 }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: brightCrimson,
                      borderRadius: 100,
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                    }}
                  >
                    <LiraIcon
                      width={20}
                      height={20}
                      color={whiteColor}
                      style={{ marginRight: 5, marginTop: 4 }}
                    />
                  </Box>
                )}
                <Typography sx={{ fontWeight: 500, fontSize: 14, ml: "13px" }}>
                  {tr.event}
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: 12, color: steelBlueColor, fontWeight: 300 }}
              >
                {moment(tr.date).format("HH:mm - jYYYY/jMM/jDD")}
              </Typography>
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    ml: 1,
                    fontWeight: 500,
                    fontSize: 14,
                    mr: 1,
                  }}
                >
                  239.00{" "}
                  <Typography
                    sx={{
                      color: primaryColor,
                      display: "inline-block",
                      fontWeight: 400,
                    }}
                  >
                    ریالءء
                  </Typography>
                </Typography>
                <ArrowRightLineIcon />
                <Typography
                  sx={{
                    ml: 1,
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  239.00
                </Typography>
                <EuroIcon width={18} heght={18} color={forestShadowColor} />
              </Box> */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {tr.currency_id === 2 ? (
                  <EuroIcon
                    width={18}
                    color={
                      tr.type === "Decrease" ? fireEngineRedColor : primaryColor
                    }
                  />
                ) : tr.currency_id === 18 ? (
                  <LiraIcon />
                ) : null}
                <Typography
                  sx={{
                    color:
                      tr.type === "Decrease"
                        ? fireEngineRedColor
                        : forestShadowColor,
                  }}
                >
                  {tr.currency_id === 3 ? (
                    <Typography
                      sx={{
                        color: primaryColor,
                        display: "inline-block",
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      ریالءء
                    </Typography>
                  ) : null}{" "}
                  {tr.currency_id !== 3
                    ? numberWithCommas(tr.amount)
                    : numberWithCommas(Math.trunc(tr.amount))}
                </Typography>
                {tr.type === "Decrease" ? (
                  <ArrowDownIcon2
                    style={{ marginRight: 4 }}
                    color={fireEngineRedColor}
                  />
                ) : (
                  <ArrowDownIcon2
                    style={{ marginRight: 4, transform: "rotate(180deg)" }}
                    color={primaryColor}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default VirtualAccounts;
