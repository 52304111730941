import { Box, IconButton, Typography } from "@mui/material";
import { arcticMistColor, blackColor, whiteColor } from "../../assets/colors";
import AccountsMenu from "./AccountsMenu";
import { useNavigate, useParams } from "react-router-dom";
import Setting from "./Setting";
import Plan from "./Plan";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import AccountEvents from "./AccountEvents";
import { appBarHeight, fullHeight } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import Profile from "./Profile";
import { useContext, useState } from "react";
import {
  AccountSettingIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
} from "../../assets/icons";
import Button from "../../components/Button";
import Pricing from "./Pricing";
import { AccountContext } from "../../context/AccountContext";
import { editUserAddressRequestAction } from "../../redux/actions/userActions";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state, setState } = useContext(AccountContext);

  const { tab } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [page, setPage] = useState(null);

  const renderTab = () => {
    switch (tab) {
      case "setting":
        return <Setting />;
      case "plan":
        return <Plan />;
      case "pricing":
        return <Pricing />;
      case "change-password":
        return <ChangePassword />;
      case "delete-account":
        return <DeleteAccount />;
      case "account-events":
        return <AccountEvents />;
      default:
        return <Profile page={page?.value} setPage={setPage} />;
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      {user.email ? null : (
        <Box
          sx={{
            backgroundColor: blackColor,
            display: { lg: "none" },
            pl: "16px",
            pr: "15px",
            py: "17px",
          }}
        >
          <Button
            onClick={() => navigate("/email-confirmation")}
            startIcon={<AccountSettingIcon width={32} height={32} />}
            text={
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: whiteColor,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  حساب کاربری خود را تایید کنید
                </Typography>
              </Box>
            }
            color="charcoalGray"
            sx={{
              height: 54,
            }}
            endIcon={<ArrowLeftIcon width={24} color={whiteColor} />}
          />
        </Box>
      )}
      <Box
        sx={{
          py: 2,
          backgroundColor: arcticMistColor,
          width: { xs: "100vw", lg: 252 },
          height: { lg: `calc(${fullHeight} - ${appBarHeight + 2}px)` },
          display: { xs: page ? "none" : "block", lg: "flex" },
          flexDirection: { xs: "row", lg: "column" },
        }}
      >
        <AccountsMenu />
      </Box>
      <Box
        sx={{
          display: { xs: page ? "flex" : "none", lg: "none" },
          alignItems: "center",
          backgroundColor: arcticMistColor,
          height: 64,
          fontWeight: 600,
        }}
      >
        <IconButton onClick={() => setPage(null)} sx={{ ml: 1 }}>
          <ChevronRightIcon width={30} height={30} />
        </IconButton>
        <Typography sx={{ fontWeight: 600, flex: 1 }}>{page?.name}</Typography>
        {user.user_address_status !== "Pending" &&
          page?.value === "AddressInformation" && (
            <Button
              text="ذخیره"
              fullWidth={false}
              variant="text"
              sx={{ fontWeight: 600 }}
              // disabled
              onClick={() =>
                dispatch(
                  editUserAddressRequestAction({
                    country: state.country,
                    state: state.state,
                    city: state.city,
                    postalCode: state.postalCode,
                    address: state.address,
                    setPage,
                  })
                )
              }
            />
          )}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          position: "relative",
          height: { lg: `calc(${fullHeight} - ${appBarHeight + 2}px)` },
          overflow: { lg: "auto" },
        }}
      >
        {renderTab()}
      </Box>
    </Box>
  );
};

export default Account;
