import { Box, Divider, Typography } from "@mui/material";
import Button from "../../../components/Button";
import {
  aquaMist,
  arcticMistColor,
  primaryColor,
} from "../../../assets/colors";
import { CheckIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

const Step4 = ({ activeStep, setActiveStep, selectedPlugin }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: { lg: 455 },
          pr: { lg: 3 },
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                width: 109,
                height: 109,
                backgroundColor: aquaMist,
                borderRadius: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mx: "auto",
                mb: "21px",
              }}
            >
              <CheckIcon width={65.4} height={65.4} color={primaryColor} />
            </Box>
            <Typography sx={{ fontWeight: 700, mb: 1 }}>
              درخواست وب گیت شما با موفقیت ثبت شد.
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: 1.8 }}>
              درخواست شما توسط واحد درگاه‌ها در حال بررسی است و نتیجه طی ۲۴ ساعت
              آینده از طریق تیکت به اطلاع شما خواهد رسید.{" "}
              {selectedPlugin
                ? "مستندات اتصال به درگاه بین‌المللی نیز در تیکت جدید برای شما ارسال شده است."
                : ""}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            text="مدیریت وب گیت ها"
            onClick={() => navigate("/webgate")}
          />
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ mb: 10, display: { xs: "none", lg: "block" } }}
      />
    </>
  );
};

export default Step4;
