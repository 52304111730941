import {
  arcticMistColor,
  paleGrayColor,
  primaryColor,
  silverSageColor,
  whiteColor,
} from "../../assets/colors";
import Title from "../../components/Title";
import { Box, Typography } from "@mui/material";
import { SmartphoneIcon } from "../../assets/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountEventsRequestAction } from "../../redux/actions/settingActions";
import Table2 from "../../components/Table2";
import moment from "moment-jalaali";
import { iranSans } from "../../utils/constants";

const AccountEvents = () => {
  const dispatch = useDispatch();

  const { accountEvents } = useSelector((state) => state.setting);
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [count, setCount] = useState(1);

  const columns = [
    {
      field: "type", //access nested data with dot notation
      label: "عملیات",
    },
    {
      field: "device",
      label: "دستگاه",
    },
    {
      field: "ip_address",
      label: "آیپی دستگاه",
    },
    {
      field: "created_at",
      label: "تاریخ و ساعت",
      renderCell: (row) => moment(row.created_at).format("HH:mm - jYYYY/jM/jD"),
    },
  ];

  useEffect(() => {
    dispatch(
      getAccountEventsRequestAction({
        limit: rowPerPage,
        offset: page,
        setCount,
      })
    );
  }, [page, rowPerPage]);

  return (
    <Box sx={{ flex: 1, p: 2.25 }}>
      <Title
        text="رویداد های حساب کاربری"
        size={4}
        containerProps={{ sx: { display: { xs: "none", lg: "flex" }, mb: 3 } }}
      />
      <Table2
        data={accountEvents}
        columns={columns}
        count={count}
        onChangePage={(page) => setPage(page)}
      />
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        {accountEvents.map((event) => (
          <Box
            sx={{
              backgroundColor: arcticMistColor,
              p: 1.25,
              borderRadius: 1.5,
              mb: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 35,
                  height: 35,
                  backgroundColor: primaryColor,
                  borderRadius: 1.25,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SmartphoneIcon color={whiteColor} width="20" height="20" />
              </Box>
              <Box sx={{ ml: 1, flex: 1 }}>
                <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                  {event.type}
                </Typography>
                <Typography sx={{ fontSize: 10, fontWeight: 300 }}>
                  {event.device}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                mt: 2,
              }}
            >
              <Typography sx={{ fontSize: 12, ml: 0.5 }}>:ip</Typography>
              <Typography sx={{ fontSize: 10 }}>{event.ip_address}</Typography>
              <Box
                sx={{
                  width: 5,
                  height: 5,
                  backgroundColor: paleGrayColor,
                  borderRadius: 100,
                  mx: 0.5,
                }}
              ></Box>
              <Typography sx={{ fontSize: 10 }}>
                {moment(event.created_at).format("HH:mm - jYYYY/jM/jD")}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AccountEvents;
