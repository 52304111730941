import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  alpha,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  primaryColor,
  whiteColor,
  arcticMistColor,
  silverSageColor,
  slateGrayColor,
  forestShadowColor,
} from "../../assets/colors";
import {
  LogoFull,
  KycIcon,
  Simplification,
  UserIcon,
  CheckIcon,
  FormkitSadIcon,
  Logo,
  PowerIcon,
} from "../../assets/icons";
import YekcardBg from "./yekcard-bg.png";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import MuiStepper from "../../components/Stepper";
import {
  createUIdLinkRequestAction,
  getUserProfileRequestAction,
  logoutRequestAction,
} from "../../redux/actions/authActions";
import {
  CREATE_UID_LINK_STARTED,
  CREATE_UID_LINK_SUCCESS,
  GET_USER_PROFILE_STARTED,
} from "../../redux/actionTypes";

const Kyc = () => {
  const dispatch = useDispatch();
  const { user, rType } = useSelector((state) => state.auth);

  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      label: "وارد سایت یو آیدی میشود",
      value: 0,
      icon: Simplification,
    },
    {
      label: "اطلاعات هویتی خود را وارد و تکمیل کنید",
      value: 1,
      icon: UserIcon,
    },
    {
      label: "به سایت برمیگردید و احراز هویت تکمیل شده",
      value: 2,
      icon: CheckIcon,
    },
  ];

  const onSubmit = () => {
    dispatch(createUIdLinkRequestAction());
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveStep((prevState) => (prevState === 2 ? 0 : prevState + 1));
    }, 2000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  const renderPendingKyc = () => {
    return (
      <Dialog
        open={user.identity_status === "Pending"}
        PaperProps={{
          sx: {
            borderRadius: 5,
            boxShadow: "0px 0px 77px 20px #5F5F5F08",
            width: 488,
          },
        }}
        BackdropProps={{
          sx: {
            background: "rgba(218, 218, 218, 0.21)",
            backdropFilter: " blur(9px)",
            right: { lg: 455 },
            borderLeft: "1px solid #E7EFEC",
          },
        }}
        sx={{ right: { lg: 455 } }}
        // onClose={() => setOpen(false)}
        scroll="body"
      >
        <DialogContent sx={{ height: 147 }}>
          <Typography sx={{ fontWeight: 700, mb: "11px" }}>
            اطلاعات شما در حال پردازش است!
          </Typography>
          <Typography sx={{ fontSize: 14, lineHeight: 1.8 }}>
            احراز هویت شما در حال بررسی می باشد. لطفا پس از دریافت پیامک اتمام
            بررسی، بر روی دکمه زیر کلیک نمایید.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            text="بررسی وضعیت احراز هویت"
            onClick={() => dispatch(getUserProfileRequestAction())}
            loading={rType === GET_USER_PROFILE_STARTED}
          />
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          order: { xs: 1, lg: 0 },
          backgroundColor: whiteColor,
          position: "relative",
        }}
      >
        <Button
          text="خروج از حساب"
          fullWidth={false}
          sx={{
            position: "absolute",
            top: 70,
            right: 48,
            display: { xs: "none", lg: "flex" },
          }}
          startIcon={<PowerIcon />}
          color="coralRed"
          onClick={() => dispatch(logoutRequestAction())}
        />
        {user.identity_status === "Declined" ? (
          <Box
            sx={{
              height: { lg: 195 },
              width: { lg: 496 },
              backgroundColor: arcticMistColor,
              p: { xs: 1, lg: 2 },
              borderRadius: "15px",
              py: 3,
              mx: 1,
            }}
          >
            <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
              <Box
                sx={{
                  backgroundColor: silverSageColor,
                  width: 85,
                  height: 85,
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "15px",
                }}
              >
                <FormkitSadIcon
                  width={50}
                  height={50}
                  color={forestShadowColor}
                />
              </Box>
              <Box sx={{ flex: 1, ml: 2 }}>
                <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
                  متاسفیم ، احراز هویت شما تایید نشد!
                </Typography>
                <Typography
                  sx={{ lineHeight: 1.8, fontSize: 14, color: slateGrayColor }}
                >
                  متاسفانه احراز هویت شما با خطا مواجه شده و تایید نشده است لطفا
                  مجددا تلاش کنید.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "9px" }}>
              <Button
                text="تلاش دوباره"
                onClick={onSubmit}
                loading={
                  rType === CREATE_UID_LINK_STARTED ||
                  rType === CREATE_UID_LINK_SUCCESS
                }
              />
              {/* <Button
                text="احراز هویت آفلاین"
                variant="outlined"
                color="slateGray"
                sx={{ border: "1px solid #E7EFEC" }}
              /> */}
            </Box>
          </Box>
        ) : (
          <Box sx={{ mx: { xs: "15px", lg: 3 }, mb: { xs: 3, lg: 5 } }}>
            <Typography
              sx={{
                fontSize: { xs: 20, lg: 24 },
                fontWeight: { xs: 700, lg: 600 },
                mb: 2.5,
                mt: { xs: "32px", lg: 0 },
              }}
            >
              برای شروع فرآیند ابتدا احراز هویت کنید
            </Typography>
            {/* <Typography
              sx={{
                mb: 4,
                fontSize: { xs: 14, lg: 16 },
                fontWeight: { xs: 300, lg: 400 },
                lineHeight: 2,
              }}
            >
              توضیحات را بخوانید و بر اساس نیاز کسب و کارتان ، محصول مورد نظر
              خود را انتخاب کنید.
            </Typography> */}
            <Box
              sx={{
                width: { lg: 732 },
                height: { lg: 431 },
                backgroundColor: arcticMistColor,
                p: { xs: 2, lg: 3 },
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: { xs: "column", lg: "row" },
                  mb: { xs: 3, lg: 0 },
                }}
              >
                <Box
                  sx={{
                    flex: 0.5,
                    pb: { lg: 2 },
                    pr: { lg: 3 },
                    mb: { xs: 3, lg: 0 },
                  }}
                >
                  <Box
                    sx={{
                      width: 85,
                      height: 85,
                      backgroundColor: silverSageColor,
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 2,
                      mx: { xs: "auto", lg: 0 },
                    }}
                  >
                    <KycIcon />
                  </Box>
                  <Typography sx={{ fontWeight: 600, mb: 1 }}>
                    احراز هویت آنلاین
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, color: slateGrayColor, lineHeight: 2 }}
                  >
                    کاربر عزیز ، جهت ادامه استفاده از خدمات و انجام تراکنش های
                    مالی ، در ابتدا باید احراز هویت حساب خود را انجام دهید.
                  </Typography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  variant="middle"
                  sx={{ display: { xs: "none", lg: "block" } }}
                />
                <Divider flexItem sx={{ display: { lg: "none" }, mb: 3 }} />
                <Box sx={{ flex: 0.5, pr: { lg: 2 }, pl: { lg: 4 } }}>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: 800, mb: { xs: 2, lg: 3 } }}
                  >
                    مراحل انجام احراز هویت
                  </Typography>
                  <MuiStepper
                    activeStep={activeStep}
                    steps={steps}
                    //   orientation="horizontal"
                    size="sm"
                  />
                </Box>
              </Box>
              <Button
                text="انجام احراز هویت آنلاین"
                onClick={onSubmit}
                loading={
                  rType === CREATE_UID_LINK_STARTED ||
                  rType === CREATE_UID_LINK_SUCCESS
                }
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: { lg: 455 },
          height: { xs: 256, lg: "auto" },
          backgroundImage: `url(${YekcardBg})`,
          backgroundSize: "cover",
          // mixBlendMode: "hard-light",
          backgroundColor: alpha(primaryColor, 0.35),
          backgroundBlendMode: "lighten",
          pl: { xs: "27px", lg: 0 },
          pr: { xs: "21px", lg: 0 },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          component={LogoFull}
          sx={{
            marginLeft: "30px",
            marginTop: "56px",
            display: { xs: "none", lg: "block" },
          }}
        />
        <Box
          sx={{
            display: { xs: "flex", lg: "none" },
            alignItems: "center",
            justifyContent: "space-between",
            // pr: "21px",
            // pl: "27px",
            mb: "18px",
            mt: "27px",
          }}
        >
          <Box component={LogoFull} />
          <Button
            text="خروج از حساب"
            fullWidth={false}
            sx={{
              display: { lg: "none" },
              borderRadius: 100,
              zIndex: 1,
            }}
            startIcon={<PowerIcon />}
            color="arcticMistWhite"
            onClick={() => dispatch(logoutRequestAction())}
          />
        </Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: { xs: 700, lg: 800 },
            color: whiteColor,
            mt: { lg: "108px" },
            ml: { lg: "30px" },
            mb: { xs: 2, lg: 1.75 },
            lineHeight: 1.8,
          }}
        >
          با چند کلیک ساده ، داشبورد
          <br /> اختصاصی خود را در یک پی بسازید!
        </Typography>
        <Typography
          sx={{
            color: alpha(whiteColor, 0.8),
            ml: { lg: "30px" },
            fontWeight: 300,
            fontSize: { xs: 14, lg: 16 },
          }}
        >
          به سوالات پاسخ دهید و مراحل را طی کنید.
        </Typography>
        <Box
          sx={{
            width: 747,
            height: 747,
            backgroundColor: "#FFFFFF1A",
            position: "absolute",
            borderRadius: 100,
            bottom: -500,
            left: -100,
          }}
        ></Box>
        <Box
          sx={{
            width: 593,
            height: 593,
            backgroundColor: "#FFFFFF1A",
            position: "absolute",
            borderRadius: 100,
            bottom: -480,
            left: 80,
          }}
        ></Box>
      </Box>
      {renderPendingKyc()}
    </>
  );
};

export default Kyc;
