import styled from "@emotion/styled";
import Switch from "@mui/material/Switch";
import {
  lightGrayColor,
  mediumGrayColor,
  primaryColor,
  whiteColor,
} from "../../assets/colors";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 34,
  padding: "7.5px 10px",
  "& .MuiSwitch-switchBase": {
    margin: "10px 6px",
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        // backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: whiteColor,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 14,
    height: 14,
    color: mediumGrayColor,
  },
  "& .MuiSwitch-track": {
    backgroundColor: lightGrayColor,
    borderRadius: 20 / 2,
  },
}));

export default MaterialUISwitch;
