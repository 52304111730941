import { Box } from "@mui/material";
import { Avatar, Grid } from "@mui/material";
import {
  arcticMistColor,
  coralRedColor,
  emeraldGreenColor,
  goldenrodColor,
  mintCreamColor,
  primaryColor,
  silverSageColor,
  silverSandColor,
  whiteColor,
} from "../../../assets/colors";
import { useContext } from "react";
import { EuroIcon2, ImageIcon } from "../../../assets/icons";
import { useSelector } from "react-redux";
import { PaylinkContext } from "../../../context/PyalinkContext";
import { apiBaseUrl } from "../../../utils/constants";
import Typography from "../../../components/Typography";

const PaymentPagePreview = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    state: {
      showContactInformation,
      linkImage,
      linkTitle,
      linkDescription,
      defaultAmount,
      focusAmount,
      desiredAmount,
    },
    setState,
  } = useContext(PaylinkContext);

  return (
    <Box
      sx={{
        width: 718,
        backgroundColor: whiteColor,
        borderRadius: 2.5,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          p: 2,
          boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            background: coralRedColor,
            borderRadius: 100,
            ml: 0.5,
          }}
        ></Box>
        <Box
          sx={{
            width: 10,
            height: 10,
            background: goldenrodColor,
            borderRadius: 100,
            ml: 0.5,
          }}
        ></Box>
        <Box
          sx={{
            width: 10,
            height: 10,
            background: emeraldGreenColor,
            borderRadius: 100,
          }}
        ></Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 278,
            backgroundColor: arcticMistColor,
            p: 2,
            textAlign: "right",
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
            Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              borderBottom: `1px solid ${silverSageColor}`,
              pt: 3,
              pb: 1,
            }}
          >
            <Avatar
              sx={{ width: 51, height: 51, ml: 1 }}
              src={`${apiBaseUrl}/${user.profile_picture}`}
            />
            <Box>
              <Typography sx={{ fontSize: 12, fontWeight: 700, mb: 0.5 }}>
                {user.first_name} {user.last_name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 10,
                  mb: 0.25,
                  display: showContactInformation ? "block" : "none",
                }}
                variant={2}
              >
                {user.email}
              </Typography>
              <Typography
                sx={{
                  fontSize: 8,
                  display: showContactInformation ? "block" : "none",
                  direction: "rtl",
                }}
                variant={2}
              >
                {user.mobile}
              </Typography>
            </Box>
          </Box>
          {desiredAmount && (
            <Box sx={{ display: "flex", py: 2, alignItems: "center" }}>
              <Typography sx={{ fontSize: 11, fontWeight: 500 }} variant={2}>
                {defaultAmount}
              </Typography>
              <EuroIcon2 width={11} height={11} />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 11,
                  ml: "auto",
                }}
              >
                :Payment amount
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ flex: 1, p: 2, textAlign: "right" }}>
          <Box
            sx={{
              width: 294,
              border: `1px solid ${mintCreamColor}`,
              p: 1,
              borderRadius: 2.5,
              mx: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                borderRadius: 1,
                p: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                mb: 2,
              }}
            >
              {linkImage ? (
                <Avatar
                  sx={{ width: 40, height: 40, ml: 1 }}
                  src={URL.createObjectURL(linkImage)}
                />
              ) : (
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: silverSageColor,
                    borderRadius: 100,
                    ml: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ImageIcon />
                </Box>
              )}
              <Box>
                <Typography
                  sx={{
                    fontSize: 10,
                    fontWeight: 700,
                    lineHeight: 2,
                  }}
                  variant={2}
                >
                  {linkTitle ? linkTitle : " Your custom name"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 8,
                  }}
                  variant={2}
                >
                  {linkDescription
                    ? linkDescription
                    : "!Your description will be shown here"}
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ fontSize: 10, fontWeight: 500, mb: 1 }}>
              Amount
            </Typography>
            <Box
              sx={{
                backgroundColor: desiredAmount ? arcticMistColor : "none",
                p: 1,
                borderRadius: 1,
                mb: 1,
                border: focusAmount
                  ? `1px solid ${primaryColor}`
                  : `1px solid ${silverSageColor}`,
                height: 42,
              }}
            >
              {desiredAmount ? (
                <Typography
                  sx={{
                    fontWeight: 500,
                  }}
                  variant={2}
                >
                  {defaultAmount}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: 6 }}>Enter amount</Typography>
              )}
            </Box>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                {/* <Typography sx={{ fontSize: 8 }}>
                  This is a place for showing a hint here!
                </Typography> */}
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Last Name
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your last name
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  First Name
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your first name
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Mobile
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your mobile
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Email
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>Enter your email</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Country
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your country
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                  Address
                </Typography>
                <Box
                  sx={{
                    border: `1px solid ${silverSageColor}`,
                    p: 0.8,
                    borderRadius: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 6 }}>
                    Enter your address
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                backgroundColor: primaryColor,
                borderRadius: 1,
                p: 0.8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Typography sx={{ color: whiteColor, fontSize: 10 }}>
                Pay
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentPagePreview;
