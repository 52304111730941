import { Box, IconButton, Link, SwipeableDrawer, alpha } from "@mui/material";
import {
  arcticMistColor,
  blackColor,
  darkForestGreenColor,
  forestShadowColor,
  midnightBlueColor,
  primaryColor,
  sageGreenColor,
  silverSageColor,
  whiteColor,
} from "../../../assets/colors";
import Wave from "../wave.svg";
import Background from "../background.png";
import {
  ArrowRightIcon,
  EditIcon,
  EmailIcon,
  GoogleIcon,
  LogoText,
  PhoneHangupIcon,
  UnlockIcon,
} from "../../../assets/icons";
import Button from "../../../components/Button";
import CustomTextInput from "../../../components/CustomTextInput";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signinRequestAction,
  verifyRequestAction,
} from "../../../redux/actions/authActions";
import { SIGNIN_STARTED, VERIFY_STARTED } from "../../../redux/actionTypes";
import CustomOtpInput from "../../../components/CustomOtpInput/index";
import { formatTime, persianToEnglishNumber } from "../../../utils/helpers";
import { EMAIL_REGEX, MOBILE_NUMBER_REGEX } from "../../../utils/regex";
import platform from "platform";
import { fullHeight } from "../../../utils/constants";
import { showSecondSplashRequestAction } from "../../../redux/actions/configActions";
import Typography from "../../../components/Typography";
// --------------------------------------------------------------------------------
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordInputRef = useRef();

  const { rType, showFirstSplash, showSecondSplash } = useSelector((state) => ({
    rType: state.auth.rType,
    showFirstSplash: state.config.showFirstSplash,
    showSecondSplash: state.config.showSecondSplash,
  }));

  const [step, setStep] = useState("Username");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", primaryColor);
  }, []);

  useEffect(() => {
    if (otp.length === 5) {
      onVerify();
    }
  }, [otp]);

  useEffect(() => {
    step === "Email" && passwordInputRef.current.focus();
  }, [step]);

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {};
    const errorObj = {};

    if (!username.trim()) {
      errorObj.username = "لطفا شماره موبایل یا ایمیل خود را وارد کنید.";
    } else if (EMAIL_REGEX.test(username)) {
      data.email = username;
    } else if (MOBILE_NUMBER_REGEX.test(username)) {
      data.mobile = username;
    } else {
      errorObj.username = "شماره موبایل یا ایمیل معتبر نیست.";
    }

    if (Object.keys(errorObj).length === 0) {
      dispatch(signinRequestAction(data, { setStep, setCounter, setErrors }));
    }

    setErrors(errorObj);
  };

  const onVerify = (e) => {
    e?.preventDefault();
    const errorObj = {};

    let data = {};
    if (EMAIL_REGEX.test(username)) {
      data.email = username;
      data.password = password;
    } else if (MOBILE_NUMBER_REGEX.test(username)) {
      data.mobile = username;
      data.verify_token = otp;
    }
    // if (otp === "") errorObj.otp = "لطفا کد ارسال شده را وارد کنید.";

    // setErrors(errorObj);

    // if (Object.keys(errorObj).length > 0) return;

    dispatch(verifyRequestAction(data, { setErrors }));
  };

  useEffect(() => {
    let timer = null;
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter, step]);

  const renderStep = () => {
    switch (step) {
      case "Username":
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              ورود به حساب کاربری
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 3 }}
            >
              لطفا برای ورود اطلاعات زیر را کامل کنید
            </Typography>
            <CustomTextInput
              label="شماره موبایل یا ایمیل"
              value={username}
              placeholder="... شماره موبایل یا ایمیل خود را وارد کنید"
              sx={{ height: 56 }}
              onChange={(e) =>
                setUsername(persianToEnglishNumber(e.target.value))
              }
              // startAdornment={<EmailIcon color={sageGreenColor} />}
              helperText={errors.username}
              error={errors.username}
              typographyVariant={2}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mb: 8,
                // mt: 2,
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  borderBottom: `1px solid ${silverSageColor}`,
                  pb: 1,
                }}
              >
                فراموشی{" "}
                <Link
                  component="button"
                  href="#"
                  underline="none"
                  sx={{ fontSize: 14 }}
                  onClick={() => navigate("/forgot-password")}
                  type="button"
                >
                  رمز عبور
                </Link>
              </Typography>
            </Box>
            <Button
              text="ورود"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                mt: "auto",
              }}
              onClick={onSubmit}
              loading={rType === SIGNIN_STARTED}
              type="submit"
            />
            {/* <Button
              text="ثبت نام با حساب گوگل"
              sx={{
                borderRadius: "58px",
                width: 361,
                py: 1.6,
                mb: 2,
              }}
              variant="outlined"
              // color="secondary"
              startIcon={<GoogleIcon />}
            /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Typography sx={{ color: blackColor }}>
                حساب کاربری ندارید؟
              </Typography>
              <Button
                variant="text"
                text="ثبت نام کنید"
                fullWidth={false}
                onClick={() => navigate("/register")}
              />
            </Box>
          </>
        );
      case "Email":
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              ورود رمز عبور
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 2.75 }}
            >
              لطفا رمز ورود خود را وارد نمایید.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "center",
                mb: 2.75,
              }}
            >
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: "5px",
                  p: 0.5,
                  px: 1,
                }}
              >
                <Typography sx={{ color: darkForestGreenColor }} variant={2}>
                  {username}
                </Typography>
              </Box>
              <IconButton onClick={() => setStep("Username")}>
                <EditIcon color={primaryColor} width={17} height={17} />
              </IconButton>
            </Box>
            <CustomTextInput
              inputRef={passwordInputRef}
              type="password"
              label="کلمه عبور"
              value={password}
              placeholder="... کلمه عبور خود را وارد کنید"
              sx={{ height: 56 }}
              onChange={(e) => setPassword(e.target.value)}
              startAdornment={<UnlockIcon color={sageGreenColor} />}
              helperText={errors.password}
              error={errors.password}
            />
            <Button
              text="ورود"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                my: 2,
                mt: "auto",
              }}
              onClick={onVerify}
              loading={rType === VERIFY_STARTED}
              type="submit"
            />
            <Button
              variant="text"
              text="بازگشت به مرحله قبل"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                fontSize: 16,
              }}
              onClick={() => setStep("Username")}
              startIcon={<ArrowRightIcon />}
              color="black"
            />
          </>
        );
      case "Mobile":
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              ورود کد تایید
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 2.75 }}
            >
              کد تایید ارسال شده به شماره موبایل خود را وارد کنید
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "center",
                mb: 2.75,
              }}
            >
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: "5px",
                  p: 0.5,
                  px: 1,
                }}
              >
                <Typography sx={{ color: darkForestGreenColor }}>
                  {username}
                </Typography>
              </Box>
              <IconButton onClick={() => setStep("Username")}>
                <EditIcon color={primaryColor} width={17} height={17} />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1.5 }}>
              <CustomOtpInput
                length={5}
                value={otp}
                onComplete={(value) => setOtp(value)}
                error={errors.otp}
                helperText={errors.otp}
              />
            </Box>
            <Typography
              sx={{
                fontSize: 14,
                color: sageGreenColor,
                textAlign: "center",
                mb: 20,
              }}
            >
              <Link
                component="button"
                underline="none"
                disabled={counter > 0}
                onClick={onSubmit}
                sx={{
                  color: forestShadowColor,
                  ":disabled": {
                    color: "inherit",
                    cursor: "default",
                  },
                }}
              >
                ارسال مجدد کد
              </Link>
              {counter > 0 ? `: ${formatTime(counter)}` : null}
            </Typography>
            <Button
              text="ورود"
              sx={{
                borderRadius: 2.5,
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                mt: "auto",
              }}
              onClick={onVerify}
              loading={rType === VERIFY_STARTED}
              type="submit"
            />
            <Button
              variant="text"
              text="بازگشت به مرحله قبل"
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                fontSize: 16,
              }}
              onClick={() => setStep("Username")}
              startIcon={<ArrowRightIcon />}
              color="black"
            />
          </>
        );
    }
  };

  const renderDrawer = (
    <SwipeableDrawer
      variant="persistent"
      anchor="bottom"
      open={!showSecondSplash}
      onOpen={() => dispatch(showSecondSplashRequestAction(false))}
      onClose={() => dispatch(showSecondSplashRequestAction(true))}
      PaperProps={{
        sx: {
          borderTop: 0,
          backgroundColor: primaryColor,
        },
      }}
      sx={{ display: { lg: "none" } }}
    >
      <Box
        sx={{
          minHeight: 200,
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Background})`,
            backgroundPositionY: -15,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            transform: "scale(1.6)",
            zIndex: -1,
          },
        }}
      >
        <LogoText color={whiteColor} height={51} />
      </Box>
      <Box
        sx={{
          px: "25px",
          pt: "29px",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          backgroundColor: whiteColor,
          height: fullHeight,
        }}
        role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
      >
        {renderStep()}
      </Box>
    </SwipeableDrawer>
  );

  return (
    <Box
      sx={{
        backgroundColor: primaryColor,
        minHeight: fullHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        // overflow: "hidden",
        backgroundImage: `url(${Background})`,
        backgroundPositionY: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          width: 495,
          // height: 730,
          backgroundColor: whiteColor,
          borderRadius: 5,
          boxShadow: "0px 0px 40px 0px #0000000F",
          zIndex: 1,
          //   p: 2,
          mt: 1,
          mb: 10,
          display: { xs: "none", lg: "block" },
        }}
      >
        <Box sx={{ mx: "auto", width: 361 }} component="form">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LogoText
              color={primaryColor}
              width="73.79"
              style={{
                marginTop: "40px",
                marginBottom: "35px",
              }}
            />
          </Box>
          {renderStep()}
        </Box>
      </Box>
      <Wave style={{ position: "absolute", right: 0, left: 0, bottom: 0 }} />
      <Box
        sx={{
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row-reverse",
            alignItems: "center",
            pb: 1,
            color: alpha(midnightBlueColor, 0.7),
            // opacity: 0.7,
          }}
        >
          <Typography sx={{ ml: 1, color: "inherit" }}>021-91001397</Typography>
          <PhoneHangupIcon color="inherit" />
          <Typography sx={{ mx: 1, color: "inherit" }}>
            support@yekpay.com
          </Typography>
          <EmailIcon color="inherit" />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
          <Typography
            sx={{ fontSize: 14, color: sageGreenColor, fontWeight: 300 }}
          >
            کلیه حقوق استفاده از این وب سایت متعلق به یک پی میباشد.
          </Typography>
        </Box>
      </Box>
      {renderDrawer}
    </Box>
  );
};

export default Login;
