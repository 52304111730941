import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  arcticMistColor,
  forestShadowColor,
  lightSilver,
  orangeSunset,
  peachCream,
  primaryColor,
  whisperingSnowColor,
  whiteColor,
} from "../../assets/colors";
import {
  CloseIcon,
  CopyIcon,
  CopyIcon2,
  GitBranchIcon,
  GlobeIcon,
  InfoIcon,
  KeyIcon,
  ListIcon,
  LoaderIcon,
} from "../../assets/icons";
import { useEffect, useRef, useState } from "react";
import { appBarHeight, drawerWidth } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import Table2 from "../../components/Table2";
import {
  changeStatusWebgateRequestAction,
  getWebgatesRequestAction,
} from "../../redux/actions/webgateActions";
import { useNavigate } from "react-router-dom";
import moment from "moment-jalaali";
import { statusObj } from "./constants";
import CustomAccordion from "../../components/Accordion";
import { numberWithCommas } from "../../utils/helpers";
// --------------------------------------------------------------------------------
const Webgate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { webgates } = useSelector((state) => state.webgate);
  const [merchantCode, setMerchantCode] = useState(null);

  useEffect(() => {
    dispatch(getWebgatesRequestAction());
  }, []);

  const renderMerchantCode = () => {
    return (
      <Dialog
        open={merchantCode}
        PaperProps={{
          sx: {
            borderRadius: 5,
            boxShadow: "0px 0px 77px 20px #5F5F5F08;",
            width: { lg: 488 },
            m: 1,
          },
        }}
        BackdropProps={{
          sx: {
            background: "rgba(218, 218, 218, 0.21)",
            backdropFilter: " blur(9px)",
            left: { lg: drawerWidth },
            top: appBarHeight,
            borderLeft: "1px solid #E7EFEC",
          },
        }}
        sx={{ left: { lg: drawerWidth }, top: appBarHeight }}
        onClose={() => setMerchantCode(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setMerchantCode(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon width={18} />
        </IconButton>
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                height: 40,
                width: 40,
                backgroundColor: arcticMistColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
              }}
            >
              <KeyIcon width={25} height={25} color={primaryColor} />
            </Box>
            <Typography sx={{ fontWeight: 700, ml: 1 }}>
              مرچنت کد وب سایت {merchantCode?.website_name}:
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              backgroundColor: lightSilver,
              display: "flex",
              height: 57,
              alignItems: "center",
              borderRadius: "9px",
              px: 1,
            }}
          >
            <IconButton
              sx={{ mr: 1 }}
              onClick={() =>
                navigator.clipboard.writeText(merchantCode.merchant_code)
              }
            >
              <CopyIcon />
            </IconButton>
            <Box
              sx={{
                backgroundColor: whiteColor,
                height: 44,
                borderRadius: "9px",
                flex: 1,
                display: "flex",
                alignItems: "center",
                px: 1,
                py:1,
                overflowX: "auto",
                direction: "rtl",
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                {merchantCode?.merchant_code}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        {/* <DialogActions>
        <Button
          text="ایجاد محصول"
          // onClick={handleAddProduct}
          loading={rType === CREATE_PRODUCT_STARTED}
        />
      </DialogActions> */}
      </Dialog>
    );
  };

  const columns = [
    {
      field: "website_name",
      label: "نام وب سایت",
      sx: {
        fontWeight: 600,
      },
    },
    {
      field: "website_domain",
      label: "آدرس وب سایت",
      sx: {
        fontWeight: 500,
      },
      renderCell: ({ website_domain }) => (
        <>
          {website_domain}
          <IconButton
            onClick={() => navigator.clipboard.writeText(website_domain)}
          >
            <CopyIcon2 color={primaryColor} />
          </IconButton>
        </>
      ),
    },
    {
      field: "status",
      label: "وضعیت درگاه",
      renderCell: ({ last_status: { status } }) => (
        <Chip
          sx={{
            borderRadius: "7px",
            height: "27px",
            fontWeight: 500,
            minWidth: 96,
          }}
          label={statusObj[status]?.label}
          color={statusObj[status]?.color}
        />
      ),
    },
    {
      field: "created_at",
      label: "تاریخ ثبت",
      renderCell: (row) =>
        moment(row.created_at).format("HH:mm - jYYYY/jMM/jDD"),
      sx: {
        fontWeight: 500,
      },
    },
    {
      label: "عملیات ها",
      renderCell: ({
        last_status: { status },
        merchant_code,
        id,
        website_name,
      }) => (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Chip
            sx={{
              fontSize: 12,
              borderRadius: "7px",
              color: forestShadowColor,
              "& .MuiChip-label": {
                pr: 0.5,
              },
            }}
            label="مرچنت کد"
            icon={<KeyIcon color={primaryColor} width={18} height={18} />}
            onClick={() => setMerchantCode({ merchant_code, website_name })}
            variant="outlined"
            color="silverSage"
            disabled={status !== "Trial" && status !== "Approved"}
          />
          <Chip
            sx={{
              fontSize: 12,
              borderRadius: "7px",
              color: forestShadowColor,
              "& .MuiChip-label": {
                pr: 0.5,
              },
            }}
            label="راهنمای اتصال"
            icon={<GitBranchIcon />}
            variant="outlined"
            color="silverSage"
            onClick={() => window.open("https://docs.yekpay.com/fa/", "_blank")}
            disabled={status !== "Trial" && status !== "Approved"}
          />
          <Chip
            sx={{
              fontSize: 12,
              borderRadius: "7px",
              color: forestShadowColor,
              "& .MuiChip-label": {
                pr: 0.5,
              },
            }}
            label="غیر فعال کردن"
            icon={<LoaderIcon />}
            variant="outlined"
            color="silverSage"
            onClick={() => dispatch(changeStatusWebgateRequestAction({ id }))}
            disabled={status === "Canceled"}
          />
        </Box>
      ),
      columnSx: { textAlign: "center" },
      hideLabel: true,
    },
  ];

  return (
    <Box sx={{ p: { xs: "14px", lg: 3 }, overflowX: "auto", flex: 1 }}>
      <Box
        sx={{
          backgroundColor: peachCream,
          p: 1,
          borderRadius: "9px",
          mb: 1,
          height: 57,
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon
          style={{ marginLeft: 5 }}
          color={orangeSunset}
          width={24}
          height={24}
        />
        <Typography
          sx={{
            fontSize: { xs: 10, lg: 16 },
            fontWeight: 500,
            lineHeight: 2.5,
          }}
        >
          در اشتراک فعلی شما (شخصی) شما مجاز به ساخت ۱ وب گیت میباشید.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: whiteColor,
          p: 3,
          border: `1px solid ${whisperingSnowColor}`,
          borderRadius: 2.5,
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          mb: 2,
          py: 4,
        }}
      >
        <ListIcon color={primaryColor} />
        <Typography sx={{ ml: 1, fontWeight: 600 }}>لیست وب گیت ها</Typography>
        <Chip
          label="+ افزودن وب گیت جدید"
          color="brightTurquoise"
          sx={{ ml: "auto" }}
          onClick={() => navigate("/webgate/create")}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: whiteColor,
          py: { xs: 2, lg: 3 },
          px: { xs: "12px", lg: 3 },
          border: `1px solid ${whisperingSnowColor}`,
          borderRadius: 2.5,
        }}
      >
        <Box sx={{ display: { lg: "none" } }}>
          <CustomAccordion
            data={webgates}
            columns={columns}
            // count={count}
            // page={page}
            // onChangePage={(page) => setPage(page)}
            renderSummary={(item) => (
              <>
                {/* {createElement(renderCurrencyCircleIcon(item.currency), {
                  width: 35,
                  style: { marginRight: 8 },
                })} */}
                <Box
                  sx={{
                    backgroundColor: arcticMistColor,
                    width: 36,
                    height: 36,
                    borderRadius: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mr: 1,
                  }}
                >
                  <GlobeIcon height={20} width={20} />
                </Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    flex: 1,
                  }}
                >
                  {item.website_name}
                </Typography>
                <Chip
                  sx={{
                    // borderRadius: "7px",
                    // height: "27px",
                    fontWeight: 500,
                    // minWidth: 96,
                    mr: 1,
                  }}
                  label={statusObj[item.last_status.status]?.label}
                  color={statusObj[item.last_status.status]?.color}
                />
              </>
            )}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignItems: "center",
            mb: 3,
          }}
        >
          <ListIcon color={primaryColor} />
          <Typography sx={{ ml: 1, fontWeight: 600, fontSize: 18 }}>
            لیست وب گیت ها
          </Typography>{" "}
          <Chip
            label="افزودن وب گیت جدید +"
            color="brightTurquoise"
            sx={{ ml: "auto" }}
            onClick={() => navigate("create")}
          />
        </Box>
        <Table2
          data={webgates}
          columns={columns}
          // count={count}
          // onChangePage={(page) => setPage(page)}
        />
      </Box>
      {renderMerchantCode()}
    </Box>
  );
};

export default Webgate;

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  //   "& .MuiTabs-indicator": {
  //     backgroundColor: "#1890ff",
  //   },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "10px 8px",
    fontSize: 12,
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    // "&:hover": {
    //   color: "#40a9ff",
    //   opacity: 1,
    // },
    "&.Mui-selected": {
      //   color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
