import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getTicketsListAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "ticket/all",
  });
};
export const getTicketCategoriesAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "get-tickets-categories",
  });
};
export const createTicketAPI = ({ data }) => {
  let postData = data;
  return axiosMiddleware({
    method: "post",
    url: "ticket/create",
    data: postData,
  });
};
export const getTicketConversationAPI = ({ ticketId }) => {
  let postData = {
    ticket_id: ticketId,
  };
  return axiosMiddleware({
    method: "post",
    url: "ticket/show",
    data: postData,
  });
};
export const createReplyTicketAPI = ({ data }) => {
  let postData = data;
  return axiosMiddleware({
    method: "post",
    url: "ticket/reply",
    data: postData,
  });
};
export const createFeedbackTicketAPI = ({ ticketId, rate, feedback }) => {
  let postData = {
    ticket_id: ticketId,
    score: rate,
    feedback,
  };
  return axiosMiddleware({
    method: "post",
    url: "ticket/feedback",
    data: postData,
  });
};
