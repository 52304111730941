import MuiTypography from "@mui/material/Typography";
import {
  dmSans,
  iranSans,
  iranSansFaNum,
  notoSans,
} from "../../utils/constants";

const Typography = ({ children, variant = 1, ...props }) => {
  return (
    <MuiTypography
      {...props}
      sx={{
        ...props.sx,
        fontFamily:
          variant === 2
            ? [dmSans, iranSans].join(",")
            : variant === 3
            ? [notoSans, iranSans].join(",")
            : [iranSansFaNum, dmSans].join(","),
      }}
    >
      {children}
    </MuiTypography>
  );
};

export default Typography;
